import { createContext, useReducer } from 'react'

export const DimensionContext = createContext() // make a new context to provide components in the application

// state - previous state before making changes
// action - have object of type property and payload
export const dimensionReducer = (state, action) => {
	switch (action.type) {
		case 'SET_DIMENSION':
			return {
				dimensions: action.payload
			}
		default:
			return state
	}
}

// provide context to the whole application component tree, so our components can access it
export const DimensionContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(
		dimensionReducer,    // reducer function name
		{ dimensions: {
			width: window.innerWidth,
			height: window.innerHeight
		} }  // initial value for states
	)

	// template
	return (
		<DimensionContext.Provider value={{...state, dispatch}}>
			{children}
		</DimensionContext.Provider>
	)
}