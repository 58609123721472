import React, { useState, useEffect } from 'react'

import { Card, Button,Row, Col  } from 'reactstrap'
import axios from 'axios'
import { BsDot } from "react-icons/bs";

import PageHeader from '../components/PageHeader'

import { useUserContext } from '../hooks/useUserContext'
import { useDimensionContext } from '../hooks/useDimensionContext'
import { useAuthContext } from "../hooks/useAuthContext"

import { util } from '../utils/util'

export const Subscriptions = () => {
	const { user } = useUserContext()
  const { dimensions } = useDimensionContext()
  const { auth } = useAuthContext()

	const [ name, setName ] = useState('')
	const [ userID, setUserID ] = useState('')
  const [ history, setHistory ] = useState([]);
  const [ workspace, setWorkspace ] = useState('');
  const [ prodCount, setProdCount ] = useState(0);
  const [ servCount, setServCount ] = useState(0);
  const [ plan, setPlan ] = useState('');
  const [ subDetails, setSubDetails ] = useState({});
  const [ price, setPrice ] = useState(0);
  const [ subId, setSubId ] = useState('');
  const [ loading, setLoading ] = useState(false);

  const [ countryCode, setCountryCode ] = useState('')

  const TEAMS_PRICE_OTHERS_ID = util.mode === 'localhost' || util.mode === 'staging' ? 'price_1M7ZAcKNHkXXY6mGvvCgybpH' : 'price_1Mb4KmKNHkXXY6mG9GlUoZoX'
  const TEAMS_PRICE_OTHERS = 'USD 12.00/month'

  const TEAMS_PRICE_MY_ID = util.mode === 'localhost' || util.mode === 'staging' ? 'price_1M7ZAcKNHkXXY6mG7LdrbRO9' : 'price_1Mb4KmKNHkXXY6mGHhfk4b50'
  const TEAMS_PRICE_MY = 'RM 54.33/month'

  useEffect(() => {
    const getGeoInfo = () => {
      axios.get('https://ipapi.co/json/').then((response) => {
        let data = response.data

        setCountryCode(data.country_code)
      }).catch((error) => {
        // console.log(error);
      })
    }

    if (countryCode === '') {
      getGeoInfo()
    }
  })

  useEffect(() => {
    if (user) {
      setName(user.name)
      setUserID(user._id)
    }
	},[user])

  useEffect(() => {
		const fetchWorkspaceList = async() => {
			const response = await fetch(`${util.expURL}/api/workspaces/`, {
				headers: {'Authorization': `Bearer ${auth.token}`}
			})
	
			const json = await response.json()
			if (response.ok && user) {
				json.map(data => {
					if (data.workspace_id === user.current_workspace) {
            setLoading(false)
						setWorkspace(data.name)
            setServCount(data.service_count !== undefined ? data.service_count : 0)
            setProdCount(data.product_count !== undefined ? data.product_count : 0)
					}
				})
			}
		}
		if (auth && user){
			fetchWorkspaceList()
		}
	},[user, auth, workspace])

  // console.log(plan)

  useEffect(() => {
    const fetchSubscription = async() => {
			const response = await fetch(`${util.expURL}/api/subscription/${user._id}`, {
				headers: {'Authorization': `Bearer ${auth.token}`}
			})

			const json = await response.json()
      if(json.message === 'teams'){
        setPlan(json.result.plan)
        setSubDetails(json.result)
        setSubId(json.result.sub_id)
        displayPrice(json.result.price, json.result.currency) 
        // setHistory(json.payment_history)
      } else {
        setPlan('Basic')
      }

		}
		if (auth && user){
			fetchSubscription()
		}
  
  }, [user, auth, workspace])
  

  const displayPrice = (amount, currency) => {
    let AMOUNT , final_amount
    if(currency === 'usd'){
      AMOUNT = amount.slice(0,2)
    } else {
      AMOUNT = amount.slice(0,2) + '.' + amount.slice(-2)
    }

    final_amount = (currency).toUpperCase() + ' ' + AMOUNT

    setPrice(final_amount)        

  }
  
  const archiveSubscription = async() => {
    const passStuff = {
			user_id: userID,
		}

    const response = await fetch(`${util.expURL}/api/subscription-archive/${user._id}`, {
			method: 'POST',
			mode: 'cors',
      body: JSON.stringify(passStuff),
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${auth.token}`
			}
		})

		const json = await response.json()
		
		if (json.message === 'success') {
			alert('Subscription has been successfully cancelled')
      setLoading(false)
      setPlan('Basic')
      setSubDetails({})
      setSubId('')
      setPrice(' - ')
		}



  }

  const cancelSubscription = () => {
    let r = window.confirm('Are you sure you want to cancel your subscription?\nClick on "OK" to confirm.')
		if (!r){
      setLoading(false)
      return
    } else {
      setLoading(true)
      const currentURL = window.location.href

      const  post_data = {
        sub_id : subId,
        metadata : {
          myPlotsUserID: userID,
          plan : 'Basic',
          user : name,
          success_url : currentURL,
          cancel_url : currentURL,
          region : countryCode,
        } 
      }
  
      // console.log('post_data here', post_data)
        
      axios.post(`${util.stripeURL}/api/SERV/payment/cancel_subscription`, post_data)
      .then(response => {
          // console.log('responseee cancel sub', response.data);
  
          if(response.data.message === 'success'){
            archiveSubscription()
            
          }
          // window.location.href = response.data.url
        }
      )
  
    }

    
  }
  
  const handleUpgradePlan = () => {
    let r = window.confirm('Are you sure you want to upgrade your subscription?\nClick on "OK" to confirm.')
		if (!r){
      setLoading(false)
      return
    } else {
      setLoading(true)
      let apiId = countryCode === 'MY' ? TEAMS_PRICE_MY_ID : TEAMS_PRICE_OTHERS_ID
    
      const currentURL = window.location.href
  
      const  post_data = {
        lookup_key : apiId,
        metadata : {
          myPlotsUserID: userID,
          plan : 'Teams',
          user : name,
          success_url : currentURL,
          cancel_url : currentURL,
          region : countryCode,
          value : apiId
        } 
      }
  
      // console.log('post_data here', post_data)
        
      axios.post(`${util.stripeURL}/api/SERV/payment/create_checkout_session`, post_data)
      .then(response => {
          // console.log('responds after success payment', response.data);
          window.location.href = response.data.url
          setLoading(false)
          // console.log("plan has changed", plan, prevPlan );

  
          // if(response.data.message === 'success'){
          //   alert('Your subscription has been successfully upgraded. Thank you!')
          // }
        }
      )
    }

    
  }

  const formatDate = (date) => {
    if(date !== 0){
      
      const dateObj = new Date(date * 1000)
      var month = dateObj.getUTCMonth() + 1
      var day = dateObj.getDate()
      var year = dateObj.getUTCFullYear()
  
      const finalDate =  `${day}/${month}/${year}`
  
      return finalDate
    }
		
	}

  const basicButton = () => {
    if(plan === 'Teams' && loading === true  ){
      return(
        <Button size='lg'  className='continue-btn'  style={{position:'absolute', bottom: 15, width: '50%', alignSelf:'center'}}  color='darkGreen' >
          <div className="d-flex align-items-center justify-content-center">
              <div className="mr-2">Processing... &nbsp; </div>
              <div className="buttonloader"></div>
          </div>
        </Button>
      )
    } else if( plan === 'Teams' && loading === false){
      return(
      <Button size='lg'  className='continue-btn'  style={{position:'absolute', bottom: 15, width: '50%', alignSelf:'center'}}  color='darkGreen' 
        onClick={() => cancelSubscription() }>
          Downgrade Plan
      </Button>
      )
    } else {
      return (<Button size='lg' disabled={true} style={{position:'absolute', bottom: 15, width: '50%', alignSelf:'center'}} >Current Plan</Button>)
    }
  }

  const teamButton = () => {
    if(plan === 'Basic' && loading === true){
      return(
        <Button size='lg'  className='continue-btn'  style={{position:'absolute', bottom: 15, width: '50%', alignSelf:'center'}}  color='darkGreen' >
          <div className="d-flex align-items-center justify-content-center">
              <div className="mr-2">Upgrading... &nbsp; </div>
              <div className="buttonloader"></div>
          </div>
        </Button>
      )
    } else if(plan === 'Basic' && loading === false){
      return(
        <Button size='lg'  className='continue-btn'  style={{position:'absolute', bottom: 15, width: '50%', alignSelf:'center'}}  color='darkGreen' 
          onClick={() => handleUpgradePlan()}>
            Upgrade Plan
        </Button>
      )
    } else {
      return( <Button size='lg' disabled={true} style={{position:'absolute', bottom: 15, width: '50%', alignSelf:'center'}} >Current Plan</Button>)
    }            
  }

  const planSection = () => {
    return(
      <div className='mb-3'  >
      <legend className='mb-4' style={{ fontSize:dimensions.width < 400 ? '16px':'18px', fontWeight:'700' }}>Choose the right plan for you...</legend>
        <div className={dimensions.width > 800 ? 'd-flex mb-3' : ''} >
          
          <Card className={dimensions.width > 800 ? 'me-4 p-3  pb-4 subscription-details' : 'mb-2 p-3 subscription-details' } style={{ width:dimensions.width > 800 ? '50%' : '100%', border: plan === 'Basic' ? '2px solid #63dca0'  : ''}}>
            <h4 >BASIC</h4>
            <h5 className='mb-3'><strong>Always Free</strong></h5>
            <div className='plan-details' style={{height:'10%',}}>
            When you really just want the basics
            </div>

            <div className='mt-4 mb-5 plan-details' >
              <BsDot fontSize='1.5em' /> 5 services <br/>
              <BsDot fontSize='1.5em' /> 5 products <br/>
              <BsDot fontSize='1.5em' />1 workspace <br/>
              <BsDot fontSize='1.5em' />Unlimited Collaborators <br/>
              <BsDot fontSize='1.5em' />Generate shareable links <br/>
            </div>
            <div className='m-2' />
            {basicButton()}

          </Card>

          <Card className={dimensions.width > 800 ? 'p-3 pb-4 subscription-details' : 'mb-2 p-3 subscription-details' } style={{ width:dimensions.width > 800 ? '50%' : '100%',  border: plan === 'Teams' ? '2px solid #63dca0'  : '' }}>
            <h4>TEAMS</h4>
            <h5 className='mb-3'><strong>{countryCode === 'MY' ? TEAMS_PRICE_MY : TEAMS_PRICE_OTHERS}<span fontSize='1em' ></span></strong></h5>
            <div  className='plan-details' style={{height:'10%',}}>
            When your team needs to align on a scheduling process and collaborate efficiently
            </div>
          
            <div className='mt-4 mb-5 plan-details'>
              <BsDot fontSize='1.5em' />Unlimited services and products <br/>
              <BsDot fontSize='1.5em' />Up to 5 Workspaces  <br/>
              <BsDot fontSize='1.5em' />Sharing permissions <br/>
              <BsDot fontSize='1.5em' />Team libraries <br/>
            </div>
            <div className='m-2' />    
            {teamButton()}        
          </Card>

          {/* <Card className={dimensions.width > 800 ?  'p-3 subscription-details' : 'p-3 subscription-details' } style={{ width:dimensions.width > 800 ? '47%' : '100%' }}>
            <h4>ENTERPRISE</h4>
            <h5 className='mb-3 '><strong>Contact Us</strong></h5>
            <div className='plan-details' style={{height:'10%'}}>
            When your 30+ member team needs advanced security, control, and support
            </div>
            

            <div className='mt-4 mb-5 pb-5 plan-details'>
              Everything in <strong>TEAMS</strong>, plus... <br/>
              <BsDot fontSize='1.5em' />Org-wide libraries <br/>
              <BsDot fontSize='1.5em' />Design system analytics <br/>
              <BsDot fontSize='1.5em' />Branching and merging <br/>
              <BsDot fontSize='1.5em' />Centralized file management <br/>
              <BsDot fontSize='1.5em' />Unified admin and billing <br/>
              <BsDot fontSize='1.5em' />Private plugins and widgets <br/>
              <BsDot fontSize='1.5em' />Single sign-on <br/>
            </div>
            <Button size='lg' className='continue-btn'  style={dimensions.width > 800 ? {position:'absolute', bottom: '1em', width:'90%',} : {marginTop: 20}} color='darkGreen' onClick={() => handleUpgradePlan('Teams', 2)}>Upgrade Plan</Button>
          </Card> */}

        </div>

      </div>
    )
  }

  const subscriptionDetails = () => {
    return(
      <Card className={dimensions.width > 800 ? 'me-4 p-3 subscription-details' : 'mb-2 p-3 subscription-details' } style={{ width:dimensions.width > 800 ? '50%' : '100%',  }}>
        <h5 className='mb-3'>Subscription Details</h5>
        <p>Subscribed Plan : <strong>{plan}</strong></p>
        <p>Subscribed On : <strong> {subDetails.current_period_start !== undefined ? formatDate(subDetails.current_period_start) : '-'} </strong></p>
        <p>Price : <strong> {price} </strong></p>
        <p>Expired On : <strong> {subDetails.current_period_end !== undefined ? formatDate(subDetails.current_period_end) : '-'} </strong></p>
        <p>Current Total Services : <strong> {servCount} </strong></p>
        <p>Current Total Products : <strong> {prodCount} </strong></p>

      </Card>
    )
  }

  const paymentHistory = () => {
      return history.map((data) => {
          return (
              <div>
                  <Row className=' border border-dark rounded-lg m-1 my-2'>
                      <Col md={2} className='py-1'>
                          <p className='m-0' style={{ fontWeight: '500' }}>Subscription Name</p>
                          <p className='m-0 text-capitalize'>{data.plan}</p>
                      </Col>
                      <Col md={2} className='py-1'>
                          <p className='m-0' style={{ fontWeight: '500' }}>Subscription Price</p>
                          <p className='m-0 text-capitalize'>RM {data.price}</p>
                      </Col>
                      <Col md={2} className='py-1'>
                          <p className='m-0' style={{ fontWeight: '500' }}>Subscriber Name</p>
                          <p className='m-0 text-capitalize'>{data.customer_name}</p>
                      </Col>
                      <Col md={3} className='py-1'>
                          <p className='m-0' style={{ fontWeight: '500' }}>Subscription Date</p>
                          {/* <p className='m-0'>{new Date(data.timestamp_subscribed).toLocaleDateString('en-MY')} - {new Date(data.timestamp_subscribed).toLocaleTimeString('en-MY')}</p> */}
                      </Col>
                      <Col md={3} className='py-1'>
                          <p className='m-0' style={{ fontWeight: '500' }}>Subscription Expiry Date</p>
                          {/* <p className='m-0'>{new Date(data.timestamp_expired).toLocaleDateString('en-MY')} - {new Date(data.timestamp_expired).toLocaleTimeString('en-MY')}</p> */}
                      </Col>
                      {/* <Col md={3} className='py-1' style={{ textAlign: 'right' }}>
                          <Button className='btn btn-dark py-1 px-2' style={{ fontSize: '0.7rem' }}>View</Button>
                      </Col> */}
                  </Row>
              </div>
          )
      })

  }

  return (
    <>
    <div className='pb-5 headerMargin mb-3' style={{ textAlign:'left', }}>
      <PageHeader title='Subscription' />

        <div className={dimensions.width > 800 ? 'p-5 pt-3' : 'p-3 mb-2'} >
          {planSection()}

          <div  className='mb-5' >
            <div className={dimensions.width > 800 ? 'd-flex mb-3' : ''}> 
              {subscriptionDetails()}
              <Card className={dimensions.width > 800 ? 'p-3 subscription-details' : 'mb-2 p-3 subscription-details' } style={{ width:dimensions.width > 800 ? '50%' : '100%', }}>
                <h5 className='mb-3'>Payment History</h5>
                {history.length !== 0 ? paymentHistory() : <p>No payment history available</p>}
              </Card> 
            </div>
          </div>          
        </div>
    </div>
    
    </>
  )
}