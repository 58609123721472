import React, { useEffect } from 'react'

export const ProgressResponsive = (props) => {
  let progress, stepCircles, currentActive = 1

	useEffect(() => {
    progress = document.getElementById('progress');
    stepCircles = document.querySelectorAll('.circle');
    currentActive = 1;

    update(props.current);
	}) 

  const update = (currentActive) => {
    stepCircles.forEach((circle, i) => {
      if (i < currentActive) {
        circle.classList.add('active');
      } else {
        circle.classList.remove('active');
      }
    });
  
    const activeCircles = document.querySelectorAll('.active');
    progress.style.width =
      ((activeCircles.length - 1) / (stepCircles.length - 1)) * 100 + '%';
  }

  return (
    <div className='container m-3 responsive'>
      <div className='progress-container responsive'>
        <div className='progress responsive' id='progress'> </div>
          {props.list.map((list, index) => {
            return <div key={index} className='circle responsive'></div>
          })}
      </div>
    </div>
  )
}