import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class PanelHeader extends Component {
  static propTypes = {
    size: PropTypes.string,   // To determine the size of the panel needed for the page
    children: PropTypes.any // The content that need to be displayed in the panel header
  }

  _renderContent = () => {
    const { children } = this.props
    return (
      <div>
        {children}
      </div>
    )
  }

  render = () => this._renderContent()
}
