import 'dotenv/config'

const URLChecker = () => {
	let result

	switch (process.env.REACT_APP_MODE) {
		case 'localhost': result = {link:process.env.REACT_APP_URL_LOCALHOST, map:process.env.REACT_APP_MAP_API_STAGING_KEY, stripe:process.env.REACT_APP_STRIPE_URL_LOCALHOST}
			break;
		case 'staging': result = {link:process.env.REACT_APP_URL_STAGING, map:process.env.REACT_APP_MAP_API_STAGING_KEY, stripe:process.env.REACT_APP_STRIPE_URL_STAGING}
			break;
		case 'production': result = {link:process.env.REACT_APP_URL_PRODUCTION, map:process.env.REACT_APP_MAP_API_PROD_KEY, stripe:process.env.REACT_APP_STRIPE_URL_PRODUCTION}
			break;
		default: result = {link:process.env.REACT_APP_URL_LOCALHOST, map:process.env.REACT_APP_MAP_API_STAGING_KEY, stripe:process.env.REACT_APP_STRIPE_URL_LOCALHOST}
			break;
	}

	return result
}

const util = {
	version: process.env.REACT_APP_VERSION,
	mapApi: URLChecker().map,
	mode: process.env.REACT_APP_MODE,
	expURL: URLChecker().link,
	stripeURL: URLChecker().stripe
}

export { util }