import React, { useState, useEffect } from 'react'
import { Button, Col, Row, Input, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { FaTrash } from 'react-icons/fa'
import { FaEllipsisV } from 'react-icons/fa'
import { FaPlusCircle } from "react-icons/fa";
import add from '../assets/img/add.png'

import { useAuthContext } from '../hooks/useAuthContext'
import { useUserContext } from '../hooks/useUserContext'
import { useDimensionContext } from '../hooks/useDimensionContext'
import { util } from '../utils/util'

import { BankAccountEdit } from './BankAccountEdit'

export const BankAccountDetails = () => {
    const { auth } = useAuthContext()
    const { user, dispatch:dispatchUser } = useUserContext()
    const [ banks, setBanks ] = useState([])
    const [ name, setName] = useState('')
    const [ phone, setPhone ] = useState('')
    const [ edit, setEdit ] = useState(false)
    const [ addBank, setAddBank] = useState(false)
    const [ index, setIndex ] = useState()
    const [ bank, setBank ] = useState({
        bank: '',
        accName: '',
        accNo: ''
    })
    const [ btnLoader, setBtnLoader] = useState(false)
    const [ deleteBtn, setBtnDelete ] = useState(true)
    const [ error, setError ] = useState(false)
    const [ bankDel, setBankDel ] = useState("")
    const { dimensions } = useDimensionContext()
    const [ dropdown, setDropdown ] = useState([])

    useEffect(() => {
        const fetchBank = async() => {
            if (user.banks){
                setBanks(user.banks)
                let arrayEdit = []
                let dropdownArr = []
                for (const i in user.banks){
                    arrayEdit.push(false)
                    dropdownArr.push(false)
                }
                setDropdown(dropdownArr)
            }
            
            setName(user.name)
            setPhone(user.phone)
        }

        if (auth && banks.length === 0) {
			fetchBank()
		} 

    }, [auth, user, banks, edit])

    const handleChange = ({target}) => {
        const {name, value} = target
        setBank({...bank, [name]: value})
    }

    const checker = () => {
        if (bank.bank === ''){
            alert("Please fill in the bank")
            setError(true)
            return 
        }
        if (bank.accName === ''){
            alert("Please fill in the account's holder name")
            setError(true)
            return 
        }
        if (bank.accNo === ''){
            alert("Please fill in the acccount number")
            setError(true)
            return  
        }
        return 'OK'
    }
    
    const cancel = () => {
        setBank({...bank, [name]: ""})
        setAddBank(false)
    }
    
    const saveBank = async() => {
        setBtnLoader(true)
        let check = checker(banks)

        if (check !== 'OK'){
            setBtnLoader(false)
            return 
        }

        let arr = banks
        arr[banks.length] = {
            bank: bank.bank,
            accName: bank.accName,
            accNo: bank.accNo,
        }
        setBanks(arr)
        const USER = {
            ...user, 
            banks
        }
   
        const response = await fetch(`${util.expURL}/api/user/${auth.id}`, {
            method: 'POST',
            body: JSON.stringify(USER),
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.token}`
            }
        })

        if (response.ok) {
            dispatchUser({ type:'SET_USER', payload:USER })
            setAddBank(false)
            setBtnLoader(false)
            return alert('Bank Account Details have been added')
        }
        setAddBank(false)
    }

    const deleteBank = async(ind, name) => {
        setBankDel(name)
        setBtnDelete(false)
        
        let r = window.confirm('Are you sure you want to delete this bank detail?\nClick on "OK" to confirm.')

		if (!r){
            setBankDel("")
            setBtnDelete(true)
            return;

        } else {
            banks.splice(ind,1)
            dropdown.splice(ind,1)
        
            const USER = {
                ...user,
                banks
            }
        
            const response = await fetch(`${util.expURL}/api/user/${auth.id}`, {
                method: 'POST',
                body: JSON.stringify(USER),
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.token}`
                },
            })
    
            const json = await response.json()
            if (response.ok) {
                setBankDel("")
                setBtnDelete(true)
                dispatchUser({ type:'SET_USER', payload:USER })
                return alert('Bank account has been deleted')
            }
        }
    }

    const addBankModal = () => {
        let display = <>
            <Modal isOpen={addBank} className="modal-dialog-centered modal-dialog add-modal">
                <ModalHeader>Add Bank Details</ModalHeader>
                <ModalBody>
                    <Row className={dimensions.width < 830 ? 'mt-2 mb-2 px-2' : 'mt-2 mb-2'} >
                        <Col md={5}>
                            <legend className='mb-2' style={{ fontSize:'14px', fontWeight:'700' }}>Bank Name</legend>
                        </Col>
                        <Col md={7}>
                            <FormGroup style={{ maxWidth:'100%' }}>
                                <Input className='' type='text' name='bank' style={{ fontSize:'13px' }} onChange={handleChange} > </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className={dimensions.width < 830 ? 'mt-2 mb-2 px-2' : 'mt-2 mb-2'} >
                        <Col md={5}>
                            <legend className='mb-2' style={{ fontSize:'14px', fontWeight:'700' }}>Bank Account Holder</legend>
                        </Col>
                        <Col md={7}>
                            <FormGroup style={{ maxWidth:'100%' }}>
                                <Input className='' type='text' name='accName' style={{ fontSize:'13px' }} onChange={handleChange} > </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className={dimensions.width < 830 ? 'mt-2 mb-2 px-2' : 'mt-2 mb-2'}>
                        <Col md={5}>
                            <legend className='mb-2' style={{ fontSize:'14px', fontWeight:'700' }}>Bank Account Number</legend>
                        </Col>
                        <Col md={7}>
                            <FormGroup style={{ maxWidth:'100%' }}>
                                <Input className='' type='text' name='accNo' style={{ fontSize:'13px' }} onChange={handleChange}  > </Input>
                            </FormGroup>
                        </Col>
                    </Row>    
                </ModalBody>
                <ModalFooter>
                    <Button style={{ fontSize:'13px', backgroundColor:'white', color:'black', borderRadius:'2rem', border: '2px solid #000', fontWeight:600 }} onClick={() => cancel()}>Cancel</Button>
                    {btnLoader? <Button  color='darkgreen' style={{ fontSize:'13px', fontWeight:600 }} className='ml-auto' disabled>
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="mr-2">Saving...</div>
                            <div className="buttonloader"></div>
                        </div>
                    </Button> :
                    <Button  color='darkGreen' style={{ fontSize:'13px',  fontWeight:600 }} className='ml-auto' onClick={saveBank}>Save</Button> }
                
            </ModalFooter>
            </Modal>
        </>

        return display
    }
    const limitBank = () => {
		return alert('New Bank cannot be added as it only limits to 3 banks')
	}

    const buttonDelete = (x, i) => {
        if(bankDel === x && !deleteBtn){
            return(
                <Button className='ms-2' disabled color='dark' style={{ backgroundColor:'#de0d2d', fontSize:dimensions.width > 300 ? '13px':'11px', borderRadius:'2rem', border: '2px solid #de0d2d', fontWeight:700 }} >
                <div className="d-flex align-items-center justify-content-center">
                    <div className="mr-2">Deleting... &nbsp; </div>
                    <div className="buttonloader"></div>
                </div>
            </Button>
            )
        } else {
            return( 
                <Button className='ms-2' style={{ fontSize:dimensions.width > 300 ? '13px':'11px', backgroundColor:'#de0d2d', borderRadius:'2rem', border: '1px solid black', fontWeight:600 }} onClick={() => deleteBank(i, x)}><FaTrash className='me-2' color='white' />Delete</Button>
            )
        }

    }

    const renderContent = () => {
        let display
        display = 
        <div style={{ textAlign:'left' }}>
            <Row  className={dimensions.width < 830 ? 'mt-4 px-2' : 'mt-5'}  style={{ textAlign:'left' }}>
                <Col md={9} xs={8}>
                    {dimensions.width > 300 ? <h5 style={{ color:'#47c07f' }} >Bank Account Details</h5> : <h6 style={{ color:'#47c07f' }} >Bank Account Details</h6>} 
                </Col>
                <Col md={3} xs={4} style={{ textAlign:'end' }}>
                    {banks.length < 3 ? 
                        <Button style={{  backgroundColor:'transparent', color:'black', fontSize: dimensions.width > 300 ? '13px':'11px' , border:'0px', padding:'5px', fontWeight:600, border:'1px solid black' }} onClick={()=>setAddBank(true)}><FaPlusCircle color='lightGreen' style={{ textAlign:'center', marginBottom:'3px', marginRight:'5px'}}/>Add Bank</Button> : 
                        <Button style={{  backgroundColor:'transparent', color:'black', fontSize: dimensions.width > 300 ? '13px':'11px', border:'5px', fontWeight:600, border:'1px solid black'}} onClick={limitBank}><FaPlusCircle color='lightGreen' style={{ textAlign:'center', marginBottom:'3px', marginRight:'5px' }} />Add Bank</Button>  
                    }  
                </Col>
            </Row>
            {banks.map((bank,index)=>{
                return ( 
                    <div key={index} className={dimensions.width < 830 ? 'px-2' : ''} >
                    <Row className='stuff-details ps-0 pe-0'>
                        <Row className='ps-0 pe-0 '>
                            {/* {banks.length > 1 ?
                            <div className='d-flex justify-content-end'>
                            <Dropdown isOpen={dropdown[index] === true} toggle={() => {updateDropdown(index)}}>
                                <DropdownToggle style={{backgroundColor:'transparent', border:0, padding:0}} >
                                    <FaEllipsisV color='black'/>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => deleteBank(bank.bank, index)} style={{fontSize:'12px'}}>
                                    <FaTrash className='me-1' color='black' />Delete
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            </div> : null } */}
                        </Row>
                        <Row className='ps-0 pe-0'>
                            <Col md={3} xs={6}>
                                <legend className='mb-2 ms-3' style={{ fontSize: dimensions.width > 770 ? '16px': '14px', fontWeight:'700' }}>Bank Name</legend>
                            </Col>
                            <Col md={6} xs={6}>
                                <legend className='mb-2' style={{ fontSize:'14px', }}>{bank && bank.bank? bank.bank : 'N/A'}</legend>
                            </Col>
                        </Row>
                        <Row className='ps-0 pe-0'>
                            <Col md={3} xs={6}>
                                <legend className='mb-2 ms-3' style={{ fontSize: dimensions.width > 770 ? '16px': '14px', fontWeight:'700' }}>Bank Account Holder</legend>
                            </Col>
                            <Col md={6} xs={6}>
                                <legend className='mb-2' style={{ fontSize:'14px', }}>{bank && bank.accName? bank.accName : 'N/A'}</legend>
                            </Col>
                        </Row>
                        <Row className='ps-0 pe-0'>
                            <Col md={3} xs={6}>
                                <legend className='mb-2 ms-3' style={{ fontSize: dimensions.width > 770 ? '16px': '14px', fontWeight:'700' }}>Bank Account Number</legend>
                            </Col>
                            <Col md={5} xs={6}>
                                <legend className='mb-2' style={{ fontSize:'14px', }}>{bank && bank.accNo? bank.accNo : 'N/A'} </legend>
                            </Col>
                            <Col md={4} style={{ textAlign:'end' }}>
                                <Button color='lightGreen' style={{ fontSize:dimensions.width > 300 ? '13px':'11px', fontWeight:600 }} onClick={()=>{setEdit(true)
                                setIndex(index)}}>Edit</Button>
                                { banks.length > 1 ? buttonDelete(bank.bank, index) : null }
                            </Col>
                        </Row>
                    </Row> </div> )     
                
            })
            }
            
        </div>
      
        return display
    }

    return (
        <div className='mb-2'>
            {renderContent()}
            {edit && <BankAccountEdit edit={edit} banks={banks} index={index} setEdit={setEdit}/>}
            {addBank &&  banks.length < 3  && addBankModal() }
        </div>
    )
}