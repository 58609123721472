import { useState } from 'react'
import { useAuthContext } from './useAuthContext'

import { util } from '../utils/util'

export const useSignUp = () => {
	const [ error, setError ] = useState(null)
	const [ isLoading, setIsLoading ] = useState(null)
	const { dispatch } = useAuthContext()

	const signUp = async (email, password) => {
		setIsLoading(true)
		setError(null)

		const response = await fetch(`${util.expURL}/api/auth/signup`, {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({ email, password })
		})

		const json = await response.json()

		if (!response.ok) {
			setIsLoading(false)
			setError(json.error)
		}

		if (response.ok) {
			sessionStorage.setItem('auth', JSON.stringify(json))

			dispatch({ type:'LOGIN', payload:json })

			setIsLoading(false)
		}
	}

	return { signUp, isLoading, error }
}