/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-self-compare */
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React, { useState, useEffect, useRef } from 'react'
import { Button, Col, Row, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap'

import { useAuthContext } from '../hooks/useAuthContext'
import { useUserContext } from '../hooks/useUserContext'
import { useStuffsContext } from '../hooks/useStuffContext'
import { useDimensionContext } from '../hooks/useDimensionContext'
import { useWorkspaceContext } from '../hooks/useWorkspaceContext'

import { FaTimes } from 'react-icons/fa'
import { util } from '../utils/util'
import Location from '../components/Location'


export const UserProfile = () => {
    const { auth } = useAuthContext()
    const { user, dispatch } = useUserContext()
    const { dimensions } = useDimensionContext()
    const { stuffs, dispatch:dispatchStuff } = useStuffsContext()
    // const { workspace } = useWorkspaceContext()

    const [ originalData, setOriginal] = useState(user)

    const [ name, setName] = useState(user.name ? user.name:'')
    const [ phone, setPhone ] = useState(user.phone ? user.phone: '')
    const [ saveBtn, setSaveBtn ] = useState(true)
    const [ editProfile, setEditProfile ] = useState(false)
    const [ disabled, setDisabled ] = useState(true)
    const [ banks, setBanks ] = useState([
        user.banks ? user.banks:
        {
            bank: '',
            accName: '',
            accNo: ''
        }
    ])
    const [ address, setAddress] = useState(user.address ? user.address : '')
    const [ newAddress, setNewAddress] = useState('')
	const [ lat, setLat ] = useState(user.lat ? user.lat : '')
	const [ lng, setLng ] = useState(user.lng ? user.lng : '')


    useEffect(() => {
        let updateBanks = []
        user.banks.map(item => {
            if(item.bank !== '' || item.accName !== '' || item.accNo !== '') {
                updateBanks.push(item)
            }
        })
        if (user) {
          setAddress(user.address)
          setBanks(updateBanks)
        }
      }, [user, address])

    useEffect(() => {
		const fetchStuffs = async() => {
			const response = await fetch(`${util.expURL}/api/stuffs/${user.current_workspace}`, {
				headers: {'Authorization': `Bearer ${auth.token}`}
			})

			const json = await response.json()

			if (response.ok) {
				dispatchStuff({ type:'SET_STUFFS', payload:json })
			}
		}

		if (auth && user) {
			fetchStuffs()
		}
	}, [dispatchStuff, auth, user]) 

    const handleChange = ({target}) => {
        const {name, value} = target
        if (name === 'name') { setName(value)}
        if (name === 'phone') { setPhone(value) }
    }

    const saveProfile = async() => {
        setSaveBtn(false)
        const USER = {
            ...user, 
            name,
            phone,
            banks,
            address,
            lat,
            lng,
        }
        const response = await fetch(`${util.expURL}/api/user/${auth.id}`, {
            method: 'POST',
            body: JSON.stringify(USER),
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.token}`
            }
        })
        
        let responseUpdate = false
        if (response.ok) {
            dispatch({ type:'SET_USER', payload:USER })
            for (const i in stuffs) {
                let updateStuff = stuffs[i]
                updateStuff = {
                    ...stuffs[i],
                    phone
                }

                const response2 = await fetch(`${util.expURL}/api/stuffs/update/${stuffs[i].stuff_id}`, {
                    method: 'POST',
                    body: JSON.stringify(updateStuff),
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.token}`
                    }
                })
             
                if (response2.ok && i === stuffs.length - 1) {
                    responseUpdate = true
                    // console.log(responseUpdate)
                }
            }
           
            if (responseUpdate){
                setEditProfile(false)
                setSaveBtn(true)
                setDisabled(true)
                return alert('User Profile has been updated')
            }
        }
        setEditProfile(false)
        setSaveBtn(true)
        setDisabled(true)

    }
    const handleBankChange = (e, ind, bank) => {
		let newBanks = [...banks]
		newBanks[ind] = {
			...bank,
			[e.target.name]: e.target.value !== '' ? e.target.value: null
		}
		setBanks(newBanks)
	}
    const addBank = () => {
        let newBanks = [...banks]
        newBanks.push({
            bank: '',
            accName: '',
            accNo: ''
        })
		setBanks(newBanks)
    }

    const removeBank = (ind) => {
        let newBanks = [...banks]
        newBanks.splice(ind,1)
        setBanks(newBanks)
    }

    const renderBanks = () => {
		return (
			<>
                <Row className={dimensions.width < 830 ? 'mt-2 mb-2 px-2' : 'mt-2 mb-2'}>
                    <h6 style={{ color:'#47c07f' }} >Bank Account Details</h6> 
                </Row>
				<Row className={dimensions.width < 830 ? 'mt-2 px-2' : 'mt-2 '} style={{ textAlign:'left' }}>
                    <Col xs='3'>
                        <Label className='m-0' style={{ fontWeight:'700' }} >Bank</Label>
                    </Col>
                    <Col xs='4'>
                        <Label className='m-0 px-0' style={{ fontWeight:'700' }} >Account Holder</Label>
                    </Col>
                    <Col xs='3'>
                        <Label className='m-0' style={{ fontWeight:'700' }} >Account Number</Label>
                    </Col>
                    <Col xs='2'></Col>
				</Row>
				{renderBanksDynamicInput()}
				<Row className={dimensions.width < 830 ? 'mt-2 px-2' : 'mt-2 '} style={{ textAlign:'left' }}>
					<Col >
					{!disabled && banks.length < 3 ?
						<div className='m-2 ms-0 mt-2'>
							<button className='pt-2 pb-2 ps-3 pe-3 m-1 continue-btn' style={{ fontSize:'12px' }} onClick={() => addBank()}>Add Bank</button>
						</div>
					:null}
					</Col>
				</Row>
			</>
		)
	}

    const renderBanksDynamicInput = () => {
		return banks !== undefined && banks.map((data, index) => {
			return (
				<Row className={dimensions.width < 830 ? 'mt-2 px-2' : 'mt-2 '} style={{ textAlign:'left' }} key={index}>
                    <Col xs='3'>
                        <Input  disabled={disabled} type='text' name='bank' className='m-2 ms-0' style={{ fontSize:'14px' }} value={banks[index].bank !== null ?banks[index].bank: ''} onChange={e => handleBankChange(e, index, data)} />
                    </Col>
                    <Col xs='4'>
                        <Input disabled={disabled} type='text' name='accName' className='m-2 ms-0 px-0' style={{ fontSize:'14px' }} value={banks[index].accName !== null ? banks[index].accName:''} onChange={e => handleBankChange(e, index, data)} />
                    </Col>
                    <Col xs='3'>
                        <Input disabled={disabled} type='number' name='accNo' className='m-2 ms-0' style={{ fontSize:'14px' }} value={banks[index].accNo!== null  ? banks[index].accNo : ''} onChange={e => handleBankChange(e, index, data)} />
                    </Col>
                    {editProfile === true ?
                    <Col xs='2'>
                        <Button size='sm' className='m-2 ms-0' color='danger' disabled={banks.length === 1 ? true : false } onClick={() => removeBank(index)}><FaTimes fontSize='1em' /></Button>
                    </Col>
                    : null}
                   
				</Row>
			)
		})
	}

    const btnCancel = () => {
        setEditProfile(false) !== undefined
        setDisabled(true) !== undefined
        setName(originalData.name)
        setPhone(originalData.phone)
        let orginBank = []
        originalData.banks.map(item => {
            if(item.bank !== '' || item.accName !== '' || item.accNo !== '') {
                orginBank.push(item)
            }
        })
        setBanks(orginBank)
    }

    const renderButton = () => {
        let display = <>
            <Row className={dimensions.width < 830 ? 'mt-2 px-2' : 'mt-2 '} style={{justifyContent:'flex-start'}}>
                    <Col xs={1} className={dimensions.width < 830 ? 'mt-2': 'px-3 mt-0'}>
                    <Button style={{ fontSize:'13px', backgroundColor:'white', color:'black', borderRadius:'2rem', border: '2px solid #000', fontWeight:600,  }} onClick={() => btnCancel()}>Cancel</Button>
                    </Col>
                    <Col xs={2} className={dimensions.width < 830 ?  'mt-2': 'mt-0'} style={dimensions.width < 830 ? {paddingLeft:70}: {marginLeft:8}}>
                    {!saveBtn ? <Button disabled color='darkGreen' style={{ fontSize:'13px', fontWeight:600 }} className='ml-auto'> 
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="mr-2">Saving... &nbsp; </div>
                            <div className="buttonloader"></div>
                        </div>
                    </Button> : 
                    <Button  color='darkGreen'  style={{ fontSize:'13px', fontWeight:600 }} className='ml-auto' onClick={saveProfile} >Save</Button>}
                    </Col>

                        
                {/* </div> */}
            </Row>
            
        </>
        return display
    }

    const sendLocation = (address, lat, lng) => {
        setNewAddress(address)
        setLat(lat)
        setLng(lng)
      }
    const renderLocation = () => {

        let display = <>{
            editProfile?  
                <Row className={dimensions.width < 830 ? 'mt-2 mb-2 px-2' : 'mt-2 mb-2'}>
                    <Location sendLocation={sendLocation} address={address} lat={lat} lng={lng} />
                </Row> :
            <>
            <Row className={dimensions.width < 830 ? 'mt-2 mb-2 px-2' : 'mt-2 mb-2'}>
                <h6 style={{ color:'#47c07f' }} >Location</h6>
            </Row>
            <Row className={dimensions.width < 830 ? 'mt-2 px-2' : 'mt-2 mb-2'}>
                <Col md={3}>
                    <legend className='mb-2' style={{ fontSize: dimensions.width > 770 ? '16px': '14px' , fontWeight:'700' }}>Address</legend>
                </Col>
                <Col md={9}>
                    <legend className='mb-2' style={{ fontSize:'14px', }}>{address ? address : 'N/A'}</legend>
                </Col>
            </Row></>}
        </>
        return display

    }

    const renderProfile = () => {
        let display = <div style={{ textAlign:'left' }}>
            <Row className={dimensions.width < 830 ? 'mt-2 px-2' : 'mt-2 '}>
                <Col md={10} xs={5}>
                    {dimensions.width > 300 ? <h5 style={{ color:'#47c07f' }} >User Profile</h5> : <h6 style={{ color:'#47c07f' }} >User Profile</h6>} 
                </Col>
                <Col md={2} xs={7} style={{ textAlign:'end' }}>
                    {editProfile === false ? <Button color='lightGreen' style={{ fontSize:dimensions.width > 300 ? '13px':'11px', fontWeight:600 }} onClick={()=>{setEditProfile(true); setDisabled(false)}}>Edit</Button> : null}
                    
                </Col>
            </Row>
            <Row className={dimensions.width < 830 ? 'mt-2 px-2' : 'mt-2 '}>
                <div className='mt-2 mb-2'>
                    <h6>Name: </h6>
                    <Input disabled={disabled} type='text' name='name' id='name' style={{ fontSize:'13px' }} onChange={handleChange} value={name}/>
                </div>
                <div className='mt-2 mb-2'>
                    <h6>Phone Number: </h6>
                    <Input disabled={disabled} type='number' name='phone' value={phone} style={{ fontSize:'13px' }} onChange={handleChange} />
                </div>
            </Row>
           
        </div>

        return display
    }

    return (
        <>
        <div >
            {renderProfile()}
            {renderBanks()}
            {renderLocation()}
            {editProfile && renderButton()}
        </div>
        </>
    )
}