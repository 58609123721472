import { Dashboard } from '../pages/Dashboard'
import { Register } from '../pages/Register'
import { Login } from '../pages/Login'
import { Stuff } from '../pages/Stuff'
import { AddStuff } from '../pages/StuffAdd'
import { OnboardingProfile } from '../pages/OnboardingProfile'
import { OnboardingAvailability } from '../pages/OnboardingAvailability'
import { OnboardingDetails } from '../pages/OnboardingDetails'
import { AccountSettings } from '../pages/AccountSettings'
import { BankAccountDetails } from '../pages/BankAccountDetails'
import { Subscriptions } from '../pages/Subscriptions'
import { WIP } from '../pages/WIP'
import { Feedback } from '../components/Feedback'
import { WelcomePage } from '../pages/WelcomePage'
import { Analytics } from '../pages/Analytics'
import { Team } from '../pages/Team'
import { ResetPassword } from '../pages/ResetPassword'
import { FaUsers, FaBox } from 'react-icons/fa'
import { BiHomeAlt, BiLineChart } from 'react-icons/bi'

const dashRoutes = [
    {
        path:'/dashboard', name:'Dashboard', component:Dashboard, display:true, topbar:false, navbar:true, icon:<BiHomeAlt />
    }, 
    {
        path:'/analytics', name:'Analytics', component:Analytics, display:true, topbar:false, navbar:true, icon:<BiLineChart />
    },
    {
        path:'/team', name:'Team', component:Team, display:true, topbar:false, navbar:true, icon:<FaUsers />
    },
    {
        path:'/product-services', name:'Product & Services', component:Stuff, display:true, topbar:false, navbar:true, icon:<FaBox />
    },
    {
        path:'/product-services/new', name:'Add Product & Services', component:AddStuff, display:true, topbar:false, navbar:false, icon:<FaBox />
    },
    {
        path:'/product-services/:id', name:'View Product & Services', component:AddStuff, display:true, topbar:false, navbar:false, icon:<FaBox />
    },
    {
        path:'/settings', name:'Account Settings', component:AccountSettings, display:true, topbar:true, navbar:false,
    },
    // {
    //     path:'/billing', name:'Billing', component:WIP, display:true, topbar:true, navbar:false,
    // },
    {
        path:'/subscription', name:'Subscription', component:Subscriptions, display:true, topbar:true, navbar:false,
    },
    {
        path:'/feedback', name:'Feedback', component:Feedback, display:true, topbar:true, navbar:false,
    },
    {
        path:'/settings/bank-details', name:'Setting-Bank Details', component:BankAccountDetails, display:true, topbar:false, navbar:false,
    },
    {
        path:'/onboarding/profile', name:'Onboarding Profile', component:OnboardingProfile, display:false, topbar:false, navbar:false,
    },
    {
        path:'/onboarding/availability', name:'Onboarding Availability', component:OnboardingAvailability, display:false, topbar:false, navbar:false,
    },
    {
        path:'/onboarding/details', name:'Onboarding Details', component:OnboardingDetails, display:false, topbar:false, navbar:false,
    },
    {
        path:'/register', name:'Register', component:Register, display:false, topbar:false, navbar:false,
    },
    {
        path:'/login', name:'Login', component:Login, display:false, topbar:false, navbar:false,
    },
    {
        path:'/welcome', name:'Welcome', component:WelcomePage, display:false, topbar:false, navbar:false,
    },
    {
        path:'/reset-password', name:'Reset Password', component:ResetPassword, display:false, topbar:false, navbar:false,
    },
    { redirect:true, path:'/', pathTo:'/dashboard', name:'Dashboard' },
]

// export default withRouter(dashRoutes)
export default dashRoutes