import React, { useState, useEffect } from 'react'

import { v4 as uuidv4 } from 'uuid'
import { Button, Row, Col, FormGroup, Label, Input } from 'reactstrap'

import logo from '../assets/img/LOGO.png'

import FormInput from '../components/FormInput'

import { useAuthContext } from '../hooks/useAuthContext'
import { useUserContext } from '../hooks/useUserContext'
import { useDimensionContext } from '../hooks/useDimensionContext'

import { withRouter } from '../utils/withRouter'
import { util } from '../utils/util'

// function changeTimezone(date, ianatz) {

//   var invdate = new Date(date.toLocaleString('en-US', {
//     timeZone: ianatz
//   }));
//   var diff = date.getTime() - invdate.getTime();

//   return new Date(date.getTime() - diff); 
// }

// var here = new Date();
// var there = changeTimezone(here, "America/Toronto");

// console.log(`Here: ${here.toString()}\nToronto: ${there.toString()}`);


export const OnboardingAvailability = withRouter((props) => {
	const { auth } = useAuthContext()
	const { dispatch } = useUserContext()
	const { dimensions } = useDimensionContext()

	const [timeZone, setTimeZone] = useState('')
	const [timeZones, setTimeZones] = useState([])
	const [dateFormat, setDateFormat] = useState('')
	const [timeFormat, setTimeFormat] = useState('')
	const [weekStart, setWeekStart] = useState(false)
	const [weekDisplay, setWeekDisplay] = useState(false)
	const [workspace, setWorkspace] = useState(false)
	const [workspaceIDs, setWorkspaceIDs] = useState([])
	const [workspaces, setWorkspaces] = useState([])
	const [teamName, setTeamName] = useState('')
	const [error, setError] = useState(null)

	useEffect(() => {
		const fetchTimeZones = async() => {
			const response = await fetch(`${util.expURL}/api/time-zones/`, {
				headers: {'Authorization': `Bearer ${auth.token}`}
			})

			const json = await response.json()

			if (response.ok) {
				// console.log(json)
				setTimeZones(json)
			}
		}

		if (auth) {
			fetchTimeZones()
		}
	}, [dispatch, auth]) 

	// create new workspace after sign up
	const handleWorkspaceData = (type, name) => {
		const uuid = uuidv4()
		let workspace_id = 'workspace_' + uuid.slice(uuid.length - 10)
		let members = []
		let newMember = {
			id: auth.id,
			role: 'Admin'
		}
		members.push(newMember)
		
		const newWorkspace = {
			workspace_id,
			current_subscription:'Basic',
			type,
			name,
			members,
			stuffs: [],
			logo: '',
			product_count: 0,
			service_count: 0
		}
		
		workspaceIDs.push(workspace_id)
		workspaces.push(newWorkspace)
	}

	const saveUser = async () => {
		if (!auth) {
			setError('You must be logged in')
			return
		}
	
		if (workspace === 'Team' || workspace === 'Personal') {
			// handleWorkspaceData('Personal', '')
			handleWorkspaceData(workspace, teamName)
		}	
		// else {
		// 	handleWorkspaceData(workspace, teamName)
		// }	

		// handleWorkspaceData(workspace, teamName)

		const user = {
			time_zone : timeZone,
			date_format : dateFormat !== '' ? dateFormat : 'DD/MM/YYYY',
			time_format : timeFormat !== '' ? timeFormat : '12h (am/pm)',
			week_start : weekStart !== false ? weekStart : 'Sunday',
			week_display : weekDisplay !== false ? weekDisplay : '7 Days',
			availability: [],
			current_workspace : workspaceIDs[0],
			workspaces: workspaceIDs,
		}

		const response = await fetch(`${util.expURL}/api/user/${auth.id}`, {
			method: 'POST',
			body: JSON.stringify(user),
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${auth.token}`
			}
		})

		workspaces.map(async workspace => {
			await fetch(`${util.expURL}/api/create-workspace`, {
				method: 'POST',
				body: JSON.stringify(workspace),
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${auth.token}`
				}
			})
		})

		const json = await response.json()

		if (!response.ok) {
			setError(json.error)
		}

		if (response.ok) {
			setTimeZone('')
			setDateFormat('')
			setTimeFormat('')
			setWeekStart('')
			setWeekDisplay('')
			setWorkspace('')
			setWorkspaceIDs([])
			setWorkspaces([])
			setTeamName('')
			setError(null)
			// console.log('New user profile added', json)
			dispatch({ type:'CREATE_USER', payload:json })
			auth && redirect()
		}
 	}

	const redirect = () => {
		props.navigate('/onboarding/details')
	}

	const skip = async () => {
		handleWorkspaceData('Personal', '')

		const user = {
			time_zone : timeZone,
			date_format : dateFormat !== '' ? dateFormat : 'DD/MM/YYYY',
			time_format : timeFormat !== '' ? timeFormat : '12h (am/pm)',
			week_start : weekStart !== false ? weekStart : 'Sunday',
			week_display : weekDisplay !== false ? weekDisplay : '7 Days',
			availability: [],
			current_workspace : workspaceIDs[0],
			workspaces: workspaceIDs,
		}

		const response = await fetch(`${util.expURL}/api/user/${auth.id}`, {
			method: 'POST',
			body: JSON.stringify(user),
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${auth.token}`
			}
		})

		workspaces.map(async workspace => {
			const response1 = await fetch(`${util.expURL}/api/create-workspace`, {
				method: 'POST',
				body: JSON.stringify(workspace),
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${auth.token}`
				}
			})

			const json1 = await response1.json()

			if (!response1.ok) {
				setError(json1.error)
			}
		})

		const json = await response.json()

		if (!response.ok) {
			setError(json.error)
		}

		if (response.ok) {
			setTimeZone('')
			setDateFormat('')
			setTimeFormat('')
			setWeekStart('')
			setWeekDisplay('')
			setWorkspace('')
			setWorkspaceIDs([])
			setWorkspaces([])
			setTeamName('')
			setWorkspaceIDs([])
			setWorkspaces([])
			setError(null)
			dispatch({ type:'CREATE_USER', payload:json })
			props.navigate('/')
		}
	}

	const dateFormatList = ['MM/DD/YYYY', 'DD/MM/YYYY']
	const timeFormatList = ['12h (am/pm)','24h']

	return (
		<div className='starter-box-content' style={{ marginTop:dimensions.width >= 800? '5rem': '2rem', marginBottom:dimensions.width >= 800? '5rem': '2rem', maxWidth: dimensions.width >= 1000? '40%':'90%' }} >
			<Row className='m-1 p-3' style={{  alignItems:'center', paddingTop:'10px', paddingBottom:'10px', paddingLeft:'20px', paddingRight:'20px' }}>
				<img src={logo} alt='sfera-plots-logo' className='center' style={{ width: dimensions.width < 500? '35%': '100px'}}/>
			</Row>
			<Row className='pt-3 pb-3'>
				<h4 ><b>Welcome to SFERA!</b></h4>
				<p style={{ color:'black' }}> Define your default calendar preferences. This will determine how your Calendar will display. </p>
			</Row>
			<div style={{border:'1px solid #63dca0', borderRadius: dimensions.width >= 800? '3%': '5%', padding:'10px', }}>
				<Row className={dimensions.width >= 800 ? 'p-3' : 'p-2'}>
					<h6><b>Setting up calendar</b></h6>
				</Row>
				<Row className={dimensions.width >= 800 ? 'p-3 pt-0' : 'p-2'}>
					<Col md={12}>
						<FormInput title='timeZone' label='Time Zone' type='select' value={timeZone} list={timeZones} defaultOption='---Select Time Zone---' onChange={e => setTimeZone(e.target.value)} />
					</Col>
					<Col md={6}>
						<FormInput title='date_format' label='Date Format' type='select' value={dateFormat} list={dateFormatList} defaultOption='---Select Date Format---' onChange={e => setDateFormat(e.target.value)} />
					</Col>
					<Col md={6}>
						<FormInput title='time_format' label='Time Format' type='select' value={timeFormat} list={timeFormatList} defaultOption='---Select Time Format---' onChange={e => setTimeFormat(e.target.value)} />
					</Col>
					<Col md={12}>
						<Label style={{ fontSize:'15px', fontWeight:'600' }}>Week Start</Label>
						<FormGroup tag='fieldset' className='d-flex'>
							<FormGroup check className='pe-4'>
								<Label check style={{ fontSize:'15px', fontWeight:'600' }}>
									<Input type='radio' name='weekStart' value='Monday' onChange={e => setWeekStart(e.target.value)} /> 
									Monday
								</Label>
							</FormGroup>
							<FormGroup check className='pe-4'>
								<Label check style={{ fontSize:'15px', fontWeight:'600' }}>
									<Input type='radio' name='weekStart' value='Sunday' onChange={e => setWeekStart(e.target.value)} /> 
									Sunday
								</Label>
							</FormGroup>
						</FormGroup>
					</Col>
					<Col md={12}>
						<Label style={{ fontSize:'15px', fontWeight:'600' }}>Week Display</Label>
						<FormGroup tag='fieldset' className='d-flex'>
							<FormGroup check className='pe-4'>
								<Label check style={{ fontSize:'15px', fontWeight:'600' }}>
									<Input type='radio' name='weekDisplay' value='5 Days' onChange={e => setWeekDisplay(e.target.value)} /> 
									5 Days
								</Label>
							</FormGroup>
							<FormGroup check className='pe-4'>
								<Label check style={{ fontSize:'15px', fontWeight:'600' }}>
									<Input type='radio' name='weekDisplay' value='7 Days' onChange={e => setWeekDisplay(e.target.value)} /> 
									7 Days
								</Label>
							</FormGroup>
						</FormGroup>
					</Col>
					<Col md={12}>
						<Label style={{ fontSize:'15px', fontWeight:'600' }}>Workspace</Label>
						<FormGroup tag='fieldset' className='d-flex'>
							<FormGroup check className='pe-4'>
								<Label check style={{ fontSize:'15px', fontWeight:'600' }}>
									<Input type='radio' name='workspace' value='Personal' onChange={e => setWorkspace(e.target.value)} /> 
									Personal
								</Label>
							</FormGroup>
							<FormGroup check className='pe-4'>
								<Label check style={{ fontSize:'15px', fontWeight:'600' }}>
									<Input type='radio' name='workspace' value='Team' onChange={e => setWorkspace(e.target.value)} /> 
									Team
								</Label>
							</FormGroup>
						</FormGroup>
					</Col>
						<Col md={12}>
							{workspace === 'Team' ? <FormInput title='team_name' label='Team name:' type='text' value={teamName} onChange={e => setTeamName(e.target.value)} />:<FormInput title='personal_name' label='Personal name:' type='text' value={teamName} onChange={e => setTeamName(e.target.value)} />}
						</Col>
					
				</Row>
			</div>
			<Row className='p-3 pt-4'>
				<div className='d-flex justify-content-between center' style={{width:'80%'}}>
					<Button className='m-2' color='lightGreen' style={{ width: '100%', fontSize:dimensions.width < 400 ? '12px' : '', }} onClick={() => skip()}>SKIP</Button>
					<Button className='m-2' color='darkGreen' style={{ width: '100%', fontSize:dimensions.width < 400 ? '12px' : '', }} onClick={() => saveUser()}>CONTINUE</Button>
				</div>
			</Row>

			{error && <div className='error'>{error}</div>}
		</div>
	)
})