import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {Label, FormGroup, Input} from 'reactstrap'

export default class FormInput extends Component {
	static propTypes = {
		title: PropTypes.string.isRequired,
		label: PropTypes.string,
		type: PropTypes.string.isRequired,
		onChange: PropTypes.func.isRequired,
		placeholder: PropTypes.string,
		className: PropTypes.string,
		value: PropTypes.string,
		disabled: PropTypes.func,
	}

	_renderContent = () => {
		const { title, type, onChange, placeholder, label, className, value, disabled, list, defaultOption} = this.props

		const optionList = list && list.map((item, index) => {
			return <option key={index} value={item}>{item}</option>
		})
		
		return (
			<FormGroup>
				<Label style={{ fontSize:'15px', fontWeight:'600' }} for={title}> {label} </Label>
				<Input 
					type={type} 
					name={title}
					id={title}
					onChange={onChange}
					placeholder={placeholder}
					className={className}
					value={value}
					disabled={disabled}
					style={{ border:'1px solid black', borderRadius:'0.5rem' }}
				>
					{type === 'select' ?
						<>
						<option value=''>{defaultOption}</option>
						{optionList}
						</>
						: undefined
					}
				</Input>
			</FormGroup>
		)
	}

	render = () => this._renderContent()
}


