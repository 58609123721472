
import React, { useState } from 'react'
import { Button, Col, FormGroup, Input, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import { useAuthContext } from '../hooks/useAuthContext'
import { useUserContext } from '../hooks/useUserContext'
import { util } from '../utils/util'

export const BankAccountEdit = (props) => {
    const { user, dispatch } = useUserContext()
    const { auth } = useAuthContext()
    const [ banks, setBanks ] = useState(props.banks)
    const [ index, setIndex ] = useState(props.index)
    const [ btnLoader, setBtnLoader] = useState(false)
    const [ bank, setBank ] = useState({
        bank: props.banks[props.index].bank ,
        accName: props.banks[props.index].accName ,
        accNo: props.banks[props.index].accNo ,
    })

    const handleChange = ({target}) => {
        const {name, value} = target
        setBank({...bank, [name]: value})
    }

    const saveBank = async() => {
        setBtnLoader(true)
        let arr = banks
        arr[index] = {
            bank: bank.bank,
            accName: bank.accName,
            accNo: bank.accNo,
        }
        setBanks(arr)
        const USER = {
            ...user, 
            banks
        }
   
        const response = await fetch(`${util.expURL}/api/user/${auth.id}`, {
            method: 'POST',
            body: JSON.stringify(USER),
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.token}`
            }
        })

        if (response.ok) {
            dispatch({ type:'SET_USER', payload:USER })
        }
        props.setEdit(false)

    }
    
    return (
        <>
            <Modal isOpen={props.edit} className="modal-dialog-centered modal-dialog add-modal">
                <ModalHeader>Edit Bank Details</ModalHeader>
                <ModalBody>
                    <Row className='mt-2 mb-2' >
                        <Col md={5}>
                            <legend className='mb-2' style={{ fontSize:'14px', fontWeight:'700' }}>Bank Name</legend>
                        </Col>
                        <Col md={7}>
                            <FormGroup style={{ maxWidth:'100%' }}>
                                <Input className='' type='text' name='bank' value={bank.bank} style={{ fontSize:'13px' }} onChange={handleChange} > </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className='mt-2 mb-2' >
                        <Col md={5}>
                            <legend className='mb-2' style={{ fontSize:'14px', fontWeight:'700' }}>Bank Account Holder</legend>
                        </Col>
                        <Col md={7}>
                            <FormGroup style={{ maxWidth:'100%' }}>
                                <Input className='' type='text' name='accName' value={bank.accName} style={{ fontSize:'13px' }} onChange={handleChange} > </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className='mt-2 mb-2' >
                        <Col md={5}>
                            <legend className='mb-2' style={{ fontSize:'14px', fontWeight:'700' }}>Bank Account Number</legend>
                        </Col>
                        <Col md={7}>
                            <FormGroup style={{ maxWidth:'100%' }}>
                                <Input className='' type='text' name='accNo' value={bank.accNo} style={{ fontSize:'13px' }} onChange={handleChange}  > </Input>
                            </FormGroup>
                        </Col>
                    </Row>    
                </ModalBody>
                <ModalFooter>
                    <Button style={{ fontSize:'13px', backgroundColor:'white', color:'black', borderRadius:'2rem', border: '2px solid #000', fontWeight:600 }} onClick={() => props.setEdit(false)}>Cancel</Button>
                    {btnLoader? <Button style={{ fontSize:'13px', backgroundColor:'#cdf5a0', color:'black', borderRadius:'2rem', border: '2px solid #000', fontWeight:600 }} className='ml-auto' disbaled>
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="mr-2">Saving...</div>
                            <div className="buttonloader"></div>
                        </div>
                    </Button> :
                    <Button style={{ fontSize:'13px', backgroundColor:'#cdf5a0', color:'black', borderRadius:'2rem', border: '2px solid #000', fontWeight:600 }} className='ml-auto' onClick={saveBank}>Save</Button> }
                
            </ModalFooter>
            </Modal>
        </>
    )
}
