import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const PrivateRoute = ({ authenticated, children, redirectPath = '/welcome' }) => {
  const auth = JSON.parse(sessionStorage.getItem('auth'))

  if (!authenticated && !auth) {
    return <Navigate to={redirectPath} replace />
  }

  return children ? children : <Outlet />
}

export default PrivateRoute