import React, { useState } from 'react'

import { Calendar, globalizeLocalizer } from 'react-big-calendar'
import globalize from 'globalize'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Col, Row, Table } from 'reactstrap'
import { useDimensionContext } from '../hooks/useDimensionContext'

const localizer = globalizeLocalizer(globalize)

export const DashboardCalendar = (props) => {

	const [ view, setView ] = useState('week')
	const [ selectedEvent, setSelectedEvent ] = useState(null)
	const [ selectedDay, setSelectedDay ] = useState(null)
	const [ toggleModal, setToggleModal ] = useState(false)
	const { dimensions } = useDimensionContext()

	const handleView = (view) => {
		setView(view)
		setSelectedEvent({})
		setSelectedDay('')
	}

	const handleSelectedEvent = (event, e) => {
		setSelectedEvent(event)
		setSelectedDay(event.start)
		setToggleModal(!toggleModal)
	}

  	const formatDate = (date) => {
		const dateObj = date

		var month = dateObj.getUTCMonth() + 1
		var day = dateObj.getDate()
		var year = dateObj.getUTCFullYear()

		const finalDate = props.user.date_format === 'DD/MM/YYYY' ? `${day}/${month}/${year}` : `${month}/${day}/${year}`
		const newDate = date !== '' ? finalDate : 'N/A'

		return newDate
	}

	const renderOrderForm = () => {
		let x = selectedEvent
		// const convertDate = new Date(x.start)
		// const newDate = format(convertDate, 'PPP')
		const newDate = formatDate(x.date)
		const TITLE = x.title.split("-")[0]

		return (
			<div>
				<Row className='mb-4' >
					<Col>
					<Row >
						<Col xs={4}>
							<legend style={{  fontSize:'14px', fontWeight:'700' }}>Category:</legend>
						</Col>
						<Col xs={8}>
							<legend style={{ fontWeight:'normal', fontSize:'13px' }}>{x.category} </legend>
						</Col>
					</Row>				
					<Row >
						<Col xs={4}>
							<legend style={{  fontSize:'14px', fontWeight:'700' }}>Booking Ref:</legend>
						</Col>
						<Col xs={8}>
							<legend style={{ fontWeight:'normal', fontSize:'13px' }}>{x.booking_id}</legend>
						</Col>
					</Row>

					<Row >
						<Col xs={4}>
							<legend style={{  fontSize:'14px', fontWeight:'700' }}>Issued to:</legend>
						</Col>
						<Col xs={8}>
							<legend style={{ fontWeight:'normal', fontSize:'13px' }}>{x.name !== '' ? x.name : '-'}</legend>
						</Col>
					</Row>
					</Col>
					<Col>
					<Row>
						<Col xs={4}>
							<legend style={{  fontSize:'14px', fontWeight:'700' }}>Phone Number:</legend>
						</Col>
						<Col xs={8}>
							<legend style={{ fontWeight:'normal', fontSize:'13px' }}>{x.phone !== '' ? x.phone : '-'}</legend>
						</Col>
					</Row>
					<Row>
						<Col xs={4}>
							<legend style={{  fontSize:'14px', fontWeight:'700' }}>Email:</legend>
						</Col>
						<Col xs={8}>
							<legend style={{ fontWeight:'normal', fontSize:'13px' }}>{x.email}</legend>
						</Col>
					</Row>
					</Col>
				</Row>
				
				

				<Table responsive>
					<thead className='thead-light mt-3'>
					<tr>
						<th scope='col' className='ps-0' style={{ fontSize:'15px' }}>Details</th>
						<th scope='col' className='ps-0' style={{ fontSize:'15px' }}></th>
						<th scope='col' className='ps-0' style={{ fontSize:'15px', textAlign:'center' }}>Amount (CUR)</th>
					</tr>
					</thead>
					<tbody>
						<tr style={{ fontSize:'13px' }}>
							<td width='50%' className='ps-0'>
								<div>
								<span style={{fontWeight:'700'}}>{TITLE}</span><br/>
								<span style={{ fontWeight:'normal' }}>
									Booked Date: {!newDate.includes('NaN') ? newDate : 'NA'} <br/> 
									Booked Time: {x.time !== "" ? x.time : "-"} <br/>
									Location: {x.address !== "" ? x.address : "-"} <br/>
									{x.notes !== "" ? "Notes: " + x.notes : null}
								</span></div>
							</td>
							<td width='25%' style={{ whiteSpace:'pre-wrap' }}>
							</td>
							<td width='25%' style={{ whiteSpace:'pre-wrap', textAlign:'center' }}>
								<div><span style={{ fontWeight:'normal' }}>{x.code !== '' && x.code !== undefined ? x.code : 'RM'} {x.price !== '' ? x.price : ' -'}</span></div>
							</td>
						</tr>

						
						<tr s>
						
							<td  colSpan="2" style={{ whiteSpace:'pre-wrap',fontSize:'15px',  textAlign:'right' }}>
								<div style={{ fontWeight:'600' }}>Total Amount:</div>
							</td>
							<td  width='25%' style={{ whiteSpace:'pre-wrap',  textAlign:'center' }}>
								<div><span style={{ fontWeight:'normal' }}>{x.code !== '' && x.code !== undefined ? x.code : 'RM'} {x.price !== '' ? x.price : ' -'}</span></div>
							</td>
						</tr>
					</tbody>
				</Table>
			</div>
		)
	}

  const renderModal = () => {
    return (
      <Modal isOpen={toggleModal} toggle={() => setToggleModal(!toggleModal)} className='modal-dialog-centered modal-dialog' style={{ width:dimensions.width >= 800 ? '70%' : '95%', maxWidth:'100%' }} >
        <ModalHeader toggle={() => setToggleModal(!toggleModal)}>Order Form</ModalHeader>
        <ModalBody className='p-4'>
          {renderOrderForm()}
        </ModalBody>
        <ModalFooter>
			<Button  style={{ fontWeight: 'bold', backgroundColor:'#cdf5a0', color:'black', border:'2px solid black' }} onClick={() => setToggleModal(!toggleModal)}>Download Invoice</Button>
          	<Button  style={{ fontWeight: 'bold', backgroundColor:'#cdf5a0', color:'black', border:'2px solid black' }} onClick={() => setToggleModal(!toggleModal)}>Close</Button>
        </ModalFooter>
		  </Modal>
    )
  }

  const minTime = `${new Date().toDateString()} 08:00:00`
  const maxTime = `${new Date().toDateString()} 22:00:00`

  const customEventPropGetter = (event, start, end, isSelected) => {
    return { style: { backgroundColor:'#cdf5a0', color:'black', fontWeight:'600', border:'1px solid black' } }
}

	return (
    <>
      <Calendar
        defaultDate={new Date()}
        events={props.events}
        selectable
        popup
        localizer={localizer}
        view={view}
        defaultView='week'
        date={selectedDay}
        views={['day', 'week', 'month']}
        min={(view === 'day' || view === 'week') && new Date(minTime)}
        max={(view === 'day' || view === 'week') && new Date(maxTime)}
        onNavigate={(date) => setSelectedDay(date)}
        onSelectEvent={handleSelectedEvent}
        onView={view => handleView(view)}
        style={{ height:'100vh' }}
        eventPropGetter={customEventPropGetter}
      />

      {!!toggleModal && renderModal()}
    </>
	)
}