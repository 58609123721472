import { useState, useRef, useEffect } from "react";
import { withRouter } from "../utils/withRouter";
import { Button, Col, Row } from 'reactstrap'

import logo from '../assets/img/LOGO.png'

import { useDimensionContext } from '../hooks/useDimensionContext'

export const WelcomePage = withRouter((props) => {
    const { dimensions } = useDimensionContext()
    const animations = [logo,logo, logo];
    const delay = 5000;
    const [index, setIndex] = useState(0);
    const timeoutRef = useRef(null);

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }
    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
          () =>
            setIndex((prevIndex) =>
              prevIndex === animations.length - 1 ? 0 : prevIndex + 1
            ),
          delay
        );
    
        return () => {
          resetTimeout();
        };
    }, [index]);

    const register = () => {
        props.navigate('/register')
    }

    const login = () => {
        props.navigate('/login')
    }

    return (
        <div className='starter-box-content' style={{marginTop:dimensions.width >= 800? '5rem': '3rem', marginBottom:dimensions.width >= 800? '5rem': '2rem', maxWidth: dimensions.width >= 800? '70%':'90%'}}>
			<Row style={{ alignItems:'center', paddingTop:'10px', paddingBottom:'10px', paddingLeft:'20px', paddingRight:'20px' }}>
				<Col lg={6}>
                    <div>
						<img src={logo} alt='sfera-plots-logo' className='center' style={{width:'30%'}}/>
					</div>

					<div className='pt-4 pb-2' style={{ color:'black', textAlign:'center' }}>
						<h4  ><b>Welcome to SFERA Plots!</b></h4>
						<p>Start selling your products and services in 5 minutes</p>
					</div>
                    {/* Animation of their values  */}
                    <div className="slideshow">
                        <div className="slideshowSlider" style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }} >
                            {animations.map((data, index) => (
                               
                                <div className="slide" key={index}  style={{ textAlign:'center', }}>
                                    {index === 0 ? 
                                        <div className='mt-2 p-2' style={{textAlign:'center', }} >
                                            <p><b>Easy Checkout</b></p>
                                            <p style={{ whiteSpace:'initial', overflow:'auto'}}>Promote your time and products availability to the customer with an easy checkout process. No more delayed response.</p>
                                        </div> :
                                        index === 1 ? <div className='mt-2 p-2' style={{textAlign:'center', }} >
                                            <p><b>Eliminate Menial Task</b></p>
                                            <p style={{ whiteSpace:'initial', overflow:'auto'}}>Automated reminder so no more missed appointments. Reschedule within a minute and make better plans for the day</p>
                                        </div> : 
                                        index === 2 ? <div className='mt-2 p-2' style={{textAlign:'center', }} >
                                            <p><b>Boosting Productivity</b></p>
                                            <p style={{ whiteSpace:'initial', overflow:'auto'}}>You can easily delegate task, build staff schedule and strategize your resources to reduce downtime and maximizing reservation</p>
                                        </div> :null
                                    }
                                 
                                    
                                </div>
                            ))}
                        </div>

                        <div className="slideshowDots">
                            {animations.map((_, idx) => (
                                <div
                                    key={idx}
                                    className={`slideshowDot${index === idx ? " active" : ""}`}
                                    onClick={() => {
                                    setIndex(idx);
                                    }}
                                ></div>
                            ))}
                        </div>
                    </div>
                   
				</Col>
				<Col lg={6}>
                    <div className='pt-2 pb-2' >
                        <div className='pt-2 pb-2' style={{textAlign:'center'}}>
                            <Button color="lightGreen" style={{ width: dimensions.width > 700 && dimensions.width <= 990 ? '80%' : '100%', fontSize: dimensions.width < 400 ? '13px': '16px'}} onClick={e => register(e)}>SIGN UP FOR AN ACCOUNT</Button>
                        </div>
                        <div className='pt-2 pb-2' style={{textAlign:'center'}}>
                            <Button color='darkGreen' style={{ width: dimensions.width > 700 && dimensions.width <= 990 ? '80%' : '100%',  backgroundColor: 'transparent', color:'black', border:'2px solid black', fontSize: dimensions.width < 400 ? '13px': '16px'  }} onClick={e => login(e)}>LOGIN TO EXISTING ACCOUNT</Button>
                        </div>
                        <div className='pt-2 pb-2'>
                            <div style={{ color:'black', textAlign:'center' }}>By signing up, you agree to our Terms of Service and Privacy Policy.</div>

                        </div>
                    </div>
				</Col>
			</Row>
		</div>
    )
})