/* eslint-disable no-fallthrough */
import { createContext, useReducer } from 'react'

export const WorkspaceContext = createContext() // make a new context to provide components in the application

// state - previous state before making changes
// action - have object of type property and payload
export const workspaceReducer = (state, action) => {
	switch (action.type) {
		case 'SET_WORKSPACE':
			return {
				workspace: action.payload
			}
		case 'DELETE_WORKSPACE':
			return {
				workspace: action.payload
			}
		default:
			return state
	}
}

// provide context to the whole application component tree, so our components can access it
export const WorkspaceContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(
		workspaceReducer,    // reducer function name
		{ workspace: null }  // initial value for states
	)

	// template
	return (
		<WorkspaceContext.Provider value={{...state, dispatch}}>
			{children}
		</WorkspaceContext.Provider>
	)
}