import React, { useState, useEffect } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import { Button, Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

import logo from '../assets/img/LOGO.png'

import { useAuthContext } from '../hooks/useAuthContext'
import { useUserContext } from '../hooks/useUserContext'
import { useDimensionContext } from '../hooks/useDimensionContext'
import { useLogOut } from '../hooks/useLogOut'

import { util } from '../utils/util'

export default function Navbar ({ props, routes}) {
	const { auth } = useAuthContext()
	const { user, dispatch } = useUserContext()
	const { dimensions } = useDimensionContext()

	const [ workspace, setWorkspace ] = useState(null)

	const [ currentPage, setCurrentPage ] = useState('')

	const [dropdown, setDropdown] = useState(false)
	const location = useLocation()
	const navigate = useNavigate()
	const { logOut } = useLogOut()

	useEffect(() => {
		const fetchUser = async() => {
			if(auth !== null){
				const response = await fetch(`${util.expURL}/api/user/${auth.id}`, {
					headers: {'Authorization': `Bearer ${auth.token}`}
				})
	
				const json = await response.json()
	
				if (response.ok) {
					dispatch({ type:'SET_USER', payload:json })
				}  else {
					handleLogOut()
				}
			}
		}
		if (auth) fetchUser()
		
	}, [dispatch, auth])

	useEffect(() => {
		const fetchWorkspaceList = async() => {
			const response = await fetch(`${util.expURL}/api/workspaces/`, {
				headers: {'Authorization': `Bearer ${auth.token}`}
			})
	
			const json = await response.json()
			if (response.ok && user) {
				json.map(data => {
					if (data.workspace_id === user.current_workspace) {
						setWorkspace(data.name)
					}
				})
			}
		}
		if (auth && user){
			fetchWorkspaceList()
		}
	},[dispatch, user, auth, workspace])

	useEffect(() => {
		const fetchCurrentPage = () => {
			let current = props.location.pathname.slice(1)
			if (current === 'settings') setCurrentPage('Account Settings')
			else if (current === 'subscription') setCurrentPage('Subscription')
			else if (current === 'dashboard') setCurrentPage('dashboard')
			else setCurrentPage('')

		}
	
		if (props.location.pathname){
			fetchCurrentPage()
		}
	}, [user, auth, currentPage, props.location.pathname])

	const createDropdownLinks = routes => {
		return routes.map((prop, key) => {
			if(!prop.redirect && prop.display && prop.topbar) {
				if (prop.name === 'Feedback') {
					return (
						<NavLink key={key} className='nav-link' to={prop.path} style={{ backgroundColor:currentPage === prop.name ? '#cdf5a0' : '' }}><DropdownItem style={{ fontSize: dimensions.width >770? '14px' : dimensions.width <300 ? '10px': '12px'}} onClick={() => setCurrentPage('dashboard')}><b>{prop.name}</b></DropdownItem></NavLink>
					)
				}
				return (
					<NavLink key={key} className='nav-link' to={prop.path} style={{ backgroundColor:currentPage === prop.name ? '#cdf5a0' : '' }}><DropdownItem style={{ fontSize: dimensions.width >770? '14px' : dimensions.width <300 ? '10px': '12px'}} onClick={() => setCurrentPage(prop.name)}><b>{prop.name}</b></DropdownItem></NavLink>
				)
			}

			return null
		})
	}

	const handleLogOut = async () => {
		await logOut()
		await navigate('/login')
	}

	let locationName = ''
	if (location.pathname){
		let name = location.pathname.split('/')
		if (name[1] === 'product-services') locationName = 'Product & Services'
		else locationName = name[1].charAt(0).toUpperCase() + name[1].slice(1)
	}

	return (
		<>
			{dimensions.width >= 830 ?
				<div className='navbar d-flex justify-content-between px-5' style={{backgroundColor:'white'}}>
				{/* <div > */}
					{/* <Button style={{ background:'transparent', border:'' }} ><img src={logo} alt='yo' className='center' style={{ width:'100px' }}/></Button> */}
					<div onClick={() => navigate('/dashboard')}>
					<img src={logo} alt='logo' style={{ width:'60px' }}/>
					</div>
					
					<div className='d-flex justify-content-end'>
						<div style={{ alignSelf:'center' }}>
							{auth && <span className='m-2' style={{ fontWeight:'600', fontSize: dimensions.width >400? '14px' : '13px' }} >Current User: {auth.email}</span>}<br/>
							<span className='m-2' style={{ fontWeight:'600', fontSize: dimensions.width >400? '14px' : '13px' }}>{workspace ? workspace : 'Personal'} Space</span>
						</div>
		
						<Dropdown size='sm' className='m-2 me-1' isOpen={dropdown} toggle={() => {setDropdown(prevState => !prevState)}}>
							<DropdownToggle style={{ fontWeight: 'bold', backgroundColor:'#cdf5a0', color:'black', border:'2px solid black' }} caret>{locationName}</DropdownToggle>
							<DropdownMenu end style={{ fontSize:'14px' }}>
								<NavLink to='/dashboard' className='nav-link' style={{ backgroundColor:currentPage === 'dashboard' ? '#cdf5a0' : '' }} onClick={() => setCurrentPage('dashboard')}><DropdownItem style={{ fontSize: dimensions.width >770? '14px' : '13px'}}><b>Dashboard</b></DropdownItem></NavLink>
								{createDropdownLinks(routes)}
							</DropdownMenu>
						</Dropdown>
						<Button size='sm' className='m-2' style={{ fontWeight: 'bold', backgroundColor:'#cdf5a0', color:'black', border:'2px solid black' }} onClick={handleLogOut}>Log out</Button>
					</div>
				</div> :
				<div className={dimensions.width >= 350 ? 'd-flex justify-content-center mt-2 px-2': 'd-flex px-1 '}>
					<Row style={{width:'100%'}} >
						<Col md={1} xs={2} className='mt-3'>
							<img src={logo} alt='logo' style={{ width:dimensions.width >= 400 ? '50px' : dimensions.width < 200 ? '30px' : '40px', alignSelf:'center'}}/>
						</Col>
						<Col md={5} xs={5}>
							<div >
								{auth && <div className='mt-3' style={{ fontWeight:'600', fontSize:dimensions.width >= 400 ?'14px' : dimensions.width <300  ?'11px' :'12px' }} >Current User: {auth.email}</div>}
								<div className='mt-2' style={{ fontWeight:'600', fontSize: dimensions.width >400? '14px' : dimensions.width <300  ?'11px' :'12px' }}>{workspace ? workspace : 'Personal'} Space</div>
							</div>
						</Col>
						<Col md={6} xs={5} style={{textAlign:'right'}}>
							<Button size='sm' className='mt-3' style={{ fontWeight: 'bold', fontSize:dimensions.width >= 300 ?'12px' :'10px', backgroundColor:'#cdf5a0', color:'black', border:'1px solid black' , }} onClick={handleLogOut}>Log out</Button>
					
							<Dropdown size='sm' className='mt-2' isOpen={dropdown} toggle={() => {setDropdown(prevState => !prevState)}}>
								<DropdownToggle style={{ fontWeight: 'bold', fontSize:dimensions.width >= 300 ?'12px' :'10px', backgroundColor:'#cdf5a0', color:'black', border:'1px solid black' }} caret>{locationName}</DropdownToggle>
								<DropdownMenu end style={{ fontSize:'10px' }}>
									<NavLink to='/dashboard' className='nav-link' style={{ backgroundColor:currentPage === 'dashboard' ? '#cdf5a0' : '' }} onClick={() => setCurrentPage('dashboard')}><DropdownItem style={{ fontSize: dimensions.width >770? '14px' : dimensions.width <300 ? '10px': '12px'}}><b>Dashboard</b></DropdownItem></NavLink>
									{createDropdownLinks(routes)}
								</DropdownMenu>
							</Dropdown>
						</Col>
					</Row>
				</div>}
		</>
		
	
	)
}
