import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'
import { useDimensionContext } from '../hooks/useDimensionContext'

export default function PageHeader (props) {
	// static propTypes = {
	// 	title: PropTypes.string,            // The title of for the page
	// 	text: PropTypes.string,             // The sub title or text that need to be displayed under the title
	// }
	const [ title, setTitle ] = useState(props.title)
	const { dimensions } = useDimensionContext()
	// state = { screen: 0 }

	// componentDidMount = () => {
	// 	window.addEventListener("resize", this.resize.bind(this))
	// 	this.resize()
	// }

	// resize = () => this.setState({ screen: window.innerWidth })


	return (
		<div  >
			<div className='d-flex' style={{ textAlign:'left', marginTop:'10rem' }}>
				{dimensions.width >= 830 ? <h2 ><b>{title}</b></h2>: <h4 className='px-2'><b>{title}</b></h4> }
			</div>
		</div>
	)
	
}

