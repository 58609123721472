/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react'
import { Button, Col, Row, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap'

import { useAuthContext } from '../hooks/useAuthContext'
import { useUserContext } from '../hooks/useUserContext'
import { useStuffsContext } from '../hooks/useStuffContext'
import { useDimensionContext } from '../hooks/useDimensionContext'
import { useWorkspaceContext } from '../hooks/useWorkspaceContext'
import { util } from '../utils/util'
import { FaTimes } from 'react-icons/fa'

export const WorkspaceDetails = () => {
    const { auth } = useAuthContext()
    const { user, dispatch } = useUserContext()
    const { dimensions } = useDimensionContext()
    const { workspace, dispatch:dispatchWorkspace } = useWorkspaceContext()
    const { stuffs, dispatch:dispatchStuff } = useStuffsContext()


    const [ saveBtn, setSaveBtn ] = useState(true)
    const [ editWorkspace, setEditWorkspace ] = useState(false)
    const [ workspaceList, setWorkspaceList ] = useState([])
    const [ disabled, setDisabled ] = useState(true)
    const [ originalData, setOriginal] = useState(workspace)
    const [ name, setName ] = useState('')
    const [ phone, setPhone ] = useState()
    const [ banks, setBanks ] = useState([])
    const [ prefill, setPrefill ] = useState(false)
	useEffect(() => {
		const fetchWorkspaceList = async() => {
			const response = await fetch(`${util.expURL}/api/workspaces/`, {
				headers: {'Authorization': `Bearer ${auth.token}`}
			})
			const json = await response.json()
			if (response.ok && user) {
				let store = []
				
				json.map(data => {
                    if(data.type === 'Team') {
                        return store.push(data)
                    }
				})
				setWorkspaceList(store)
			}
		}

		if (auth && user) {
			fetchWorkspaceList()
		}
	}, [dispatch, auth, user]) 



    useEffect(() => {
      
        const fetchWorkspace = async() => {
            let updateBanks = []
            if(workspace.banks !== undefined) {
               workspace.banks.map(item => {
                    if(item.bank !== ''  || item.accName !== '' || item.accNo) {
                        updateBanks.push(item)
                    }
               })
            }
            if (workspace){
                setName(workspace.name? workspace.name : '')
                setPhone(workspace.phone? workspace.phone : '')   
                setBanks(updateBanks)  
            }else {
                const response = await fetch(`${util.expURL}/api/workspace/${workspace._id}`, {
                    headers: {'Authorization': `Bearer ${auth.token}`}
                })
                // console.log("inline 36,", response)
                const json = await response.json()
    
                if (response.ok) {
                    // console.log(json)
                    
                    dispatchWorkspace({ type:'SET_ WORKSPACE', payload:json })
                }
            }
        }
        if (auth){
            fetchWorkspace()
        }
    }, [auth, workspace])


    //fetch from user details [prefill]
    useEffect(() => {
        if (user && prefill) {
            setPhone(user.phone)
            setBanks(user.banks)
        }
    }, [user, prefill])

    useEffect(() => {
        const fetchStuffs = async() => {
            const response = await fetch(`${util.expURL}/api/stuffs/${user.current_workspace}`, {
                headers: {'Authorization': `Bearer ${auth.token}`}
            })

            const json = await response.json()

            if (response.ok) {
                dispatchStuff({ type:'SET_STUFFS', payload:json })
            }
        }

        if (auth && user) {
            fetchStuffs()
        }
    }, [dispatchStuff, auth, user]) 

    const handleChange = ({target}) => {
        const {name, value} = target
        if (name === 'name') { setName(value) }
        if (name === 'phone') { setPhone(value) }
    }

    const saveWorkspace = async() => {
        setSaveBtn(false)
        let updateBanks = []
        banks.map(item => {
            if(item.bank !== '' || item.accName !== '' || item.accNo !== '') {
                updateBanks.push(item)
            }
        })
        setBanks(updateBanks)
        const newWorkspace = {
            ...workspace,
            banks,
            name,
            phone
        }


        const response = await fetch(`${util.expURL}/api/workspace/update/${user.current_workspace}`, {
            method: 'POST',
            body: JSON.stringify(newWorkspace),
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${auth.token}`
			}
        })

        
        if (response.ok) {
            let responseUpdate = false
            for (const i in stuffs) {
                let updateStuff = stuffs[i]
                updateStuff = {
                    ...stuffs[i],
                    phone
                }
    
                const response2 = await fetch(`${util.expURL}/api/stuffs/update/${stuffs[i].stuff_id}`, {
                    method: 'POST',
                    body: JSON.stringify(updateStuff),
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.token}`
                    }
                })
             
                if (response2.ok && i === stuffs.length - 1) {
                    responseUpdate = true
                }
            }
            if (responseUpdate) {
                setEditWorkspace(false)
                setSaveBtn(true)
                setDisabled(true)
            }
            
        }
        setEditWorkspace(false)
        setSaveBtn(true)
        setDisabled(true)
    }

    const btnCancel = () => {
        setEditWorkspace(false) !== undefined
        setDisabled(true) !== undefined
        setName(originalData.name)
        setPhone(originalData.phone)
        let orginBanks = []
        originalData.banks.map(item => {
            if(item.bank !== '' || item.accName !== '' || item.accNo !== '') {
                orginBanks.push(item)
            }
        })
        
        // let orginBanks = [...originalData.banks]
        setBanks(orginBanks)
    }

    const renderButton = () => {
        let display = <>
            <Row className={dimensions.width < 830 ? 'mt-2 px-2' : 'mt-2 '}>
            {/* <div className='mt-2'> */}
            <Col xs={1} className={dimensions.width < 830 ? 'mt-0': 'px-3 mt-0'} style={dimensions.width < 830 ? {paddingLeft:15}: {paddingLeft:0}}>
            <Button style={{ fontSize:'13px', backgroundColor:'white', color:'black', borderRadius:'2rem', border: '2px solid #000', fontWeight:600 }} onClick={() => btnCancel()}>Cancel</Button>
            </Col>
            <Col  xs={2} className={dimensions.width < 830 ? 'mt-0': 'px-4 mt-0'} style={dimensions.width < 830 ? {paddingLeft:70}: {paddingLeft:0}}>
            {!saveBtn ? <Button disabled color='darkGreen' style={{ fontSize:'13px', fontWeight:600, width:'max-content' }} className='ml-auto'> 
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="mr-2">Saving... &nbsp; </div>
                        <div className="buttonloader"></div>
                    </div>
                </Button> : 
                <Button  color='darkGreen'  style={{ fontSize:'13px', fontWeight:600 }} className='ml-auto' onClick={saveWorkspace} >Save</Button>}
            </Col>     
            {/* </div> */}
            </Row>
             
        </>
        return display
    }

    const handleBankChange = (e, ind, bank) => {
		let newBanks = [...banks]
		newBanks[ind] = {
			...bank,
			[e.target.name]: e.target.value !== '' ? e.target.value:null

		}
		setBanks(newBanks)
	}
    const addBank = () => {
        let newBanks = [...banks]
        newBanks.push({
            bank: '',
            accName: '',
            accNo: ''
        })
		setBanks(newBanks)
    }

    const removeBank = (ind) => {
        let newBanks = [...banks]
        newBanks.splice(ind,1)
        // console.log('remove bank', newBanks)
        setBanks(newBanks)
    }

    const deleteWorkspace = async () => {
        if (!auth) return
        let r = window.confirm('Are you sure you want to delete this Workspace on "OK" to confirm.')
		if (!r) return

        const TEAM_WORKSPACE = {
            Arrayworkspace: user.workspaces,
            Currentworkspace: user.current_workspace,
            ...workspace
            
        }
        const response = await fetch(`${util.expURL}/api/workspace/${user.current_workspace}`, {
			method: 'POST',
			body: JSON.stringify(TEAM_WORKSPACE),
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${auth.token}`
			}
		})
        const json = await response.json()
        // console.log("inline 215", json)
		if (response.ok) {
			dispatchWorkspace({ type:'DELETE_WORKSPACE', payload:json })
			alert('Workspace has been successfully deleted')
		}
    }

    const renderBanks = () => {
		return (
			<>
                <Row className={dimensions.width < 830 ? 'mt-2 mb-2 px-2' : 'mt-2 mb-2'}>
                    <h6 style={{ color:'#47c07f' }} >Bank Account Details</h6> 
                </Row>
				<Row className={dimensions.width < 830 ? 'mt-2 px-2' : 'mt-2 '} style={{ textAlign:'left' }}>
                    <Col xs='3'>
                        <Label className='m-0' style={{ fontWeight:'700' }} >Bank</Label>
                    </Col>
                    <Col xs='4'>
                        <Label className='m-0 px-0' style={{ fontWeight:'700' }} >Account Holder</Label>
                    </Col>
                    <Col xs='3'>
                        <Label className='m-0' style={{ fontWeight:'700' }} >Account Number</Label>
                    </Col>
                    <Col xs='2'></Col>
				</Row>
				{renderBanksDynamicInput()}
				<Row className={dimensions.width < 830 ? 'mt-2 px-2' : 'mt-2 '} style={{ textAlign:'left' }}>
					<Col >
					{!disabled && banks.length < 3 ?
						<div className='m-2 ms-0 mt-2'>
							<button className='pt-2 pb-2 ps-3 pe-3 m-1 continue-btn' style={{ fontSize:'12px' }} onClick={() => addBank()}>Add Bank</button>
						</div>
					:null}
					</Col>
				</Row>
			</>
		)
	}

    const renderBanksDynamicInput = () => {
		return banks !== undefined && banks.map((data, index) => {
			return (
				<Row className={dimensions.width < 830 ? 'mt-2 px-2' : 'mt-2 '} style={{ textAlign:'left' }} key={index}>
                    <Col xs='3'>
                        <Input  disabled={disabled} type='text' name='bank' className='m-2 ms-0' style={{ fontSize:'14px' }} value={banks[index].bank !== null ? banks[index].bank : ''} onChange={e => handleBankChange(e, index, data)} />
                    </Col>
                    <Col xs='4'>
                        <Input disabled={disabled} type='text' name='accName' className='m-2 ms-0 px-0' style={{ fontSize:'14px' }} value={banks[index].accName !== null ? banks[index].accName : ''} onChange={e => handleBankChange(e, index, data)} />
                    </Col>
                    <Col xs='3'>
                        <Input disabled={disabled} type='number' name='accNo' className='m-2 ms-0' style={{ fontSize:'14px' }} value={banks[index].accNo ? banks[index].accNo : ''} onChange={e => handleBankChange(e, index, data)} />
                    </Col>
                    {editWorkspace === true ?
                    <Col xs='2'>
                        <Button size='sm' className='m-2 ms-0' color='danger' disabled={banks.length === 1 ? true : false } onClick={() => removeBank(index)}><FaTimes fontSize='1em' /></Button>
                    </Col>
                    : null
                    }
                   
				</Row>
			)
		})
	}


    const renderContent = () => {
        let display = 
        <div style={{ textAlign:'left' }}>
            <Row className={dimensions.width < 830 ? 'mt-2 px-2' : 'mt-2 '}>
                <Col md={10} xs={5}>
                    {dimensions.width > 300 ? <h5 style={{ color:'#47c07f' }} >Workspace {name && <span> - {name} </span>}</h5> : <h6 style={{ color:'#47c07f' }} >Workspace <span>{name? - name : null}</span></h6>} 
                </Col>
                <Col md={1} xs={7} style={{ textAlign:'end' }}>
                    {editWorkspace === false ? <Button color='lightGreen' style={{ fontSize:dimensions.width > 300 ? '13px':'11px', fontWeight:600 }} onClick={()=>{setEditWorkspace(true); setDisabled(false)}}>Edit</Button> : null}
                </Col>
                {editWorkspace === true ? 
                 <Col md={1} xs={7} style={{ textAlign:'end'}}>
                 <Button color='danger' style={{ fontSize:dimensions.width > 300 ? '13px':'11px', fontWeight:600 }} onClick={()=>{ deleteWorkspace()}}>Delete</Button>
             </Col>:null}
                {dimensions.width > 300 ? <h6 style={{ color:'#47c07f' }} className={'mt-2'}>{workspace.type}</h6> : <h5 style={{ color:'#47c07f' }} className={'mt-'}>{workspace.type}</h5>} 
            </Row>
            {
                workspace && <Row className={dimensions.width < 830 ? 'mt-2 px-2' : 'mt-2 '}>
                    <div className='mt-2 mb-2'>
                        {editWorkspace === false ? null : <Label check className='d-flex'>
                            {/* <Input disabled={disabled} className='ms-1 me-3' type='checkbox' name='prefill' id='prefill' onChange={()=>{setPrefill(true);console.log(prefill,banks)}} style={{ fontSize:'13px' }} value={prefill}/> */}
                            <span style={{ fontWeight:'600' }}>Prefill details from Profile</span>
                        </Label>}
                       
                    </div>
                    <div className='mt-2 mb-2'>
                        <h6>Name: </h6>
                        <Input disabled={disabled} type='text' name='name' id='name' onChange={handleChange} style={{ fontSize:'13px' }} value={name}/>
                    </div>
                    <div className='mt-2 mb-2'>
                        <h6>Phone: </h6>
                        <Input disabled={disabled} type='number' name='phone' id='phone' onChange={handleChange} style={{ fontSize:'13px' }} value={phone}/>
                    </div>
                </Row>
            }
       
        </div>

        return display
    }

    return (
        <>
        {renderContent()}
        {renderBanks()}
        {editWorkspace && renderButton()}
        </>
    )
}