import { useAuthContext } from './useAuthContext'
import { useStuffsContext } from './useStuffContext'

export const useLogOut = () => {
	const { dispatch } = useAuthContext()
	const { dispatch:dispatchStuffs } = useStuffsContext()

	const logOut = () => {
		sessionStorage.removeItem('auth')

		dispatch({ type:'LOGOUT' })
		dispatchStuffs({ type:'SET_STUFFS', payload: null })
	}

	return { logOut }
}