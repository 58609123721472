import { useState } from 'react'
import { useAuthContext } from './useAuthContext'

import { util } from '../utils/util'

export const useLogin = () => {
	let loginError = ''
	let isLoading = null
	const { dispatch } = useAuthContext()

	const login = async (email, password) => {
		isLoading = true
		loginError = null

		const response = await fetch(`${util.expURL}/api/auth/login`, {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({ email, password })
		})

		const json = await response.json()
		if (!response.ok) {
			isLoading = false
			sessionStorage.setItem('auth', JSON.stringify(json.error))
			loginError = json.error
			return { login, isLoading, loginError }
		}

		if (response.ok) {
			sessionStorage.setItem('auth', JSON.stringify(json))

			dispatch({ type:'LOGIN', payload:json })

			isLoading = false
			
			return { login, isLoading, loginError }
		}
	}

	return { login, isLoading, loginError }
}