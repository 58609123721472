import React from 'react'

import { Button, Row, Col } from 'reactstrap'

import logo from '../assets/img/LOGO.png'

import { useDimensionContext } from '../hooks/useDimensionContext'

import { withRouter } from '../utils/withRouter'

export const OnboardingDetails = withRouter((props) => {
	const { dimensions } = useDimensionContext()

	const redirect = () => {
		props.navigate('/dashboard')
	}

	const skip = () => {
		props.navigate('/')
	}

	return (
		<div className='starter-box-content' style={{ marginTop:dimensions.width >= 800? '6rem': '4rem', marginBottom:dimensions.width >= 800? '6rem': '4rem', maxWidth: dimensions.width >= 1000? '40%':'90%' }} >
			<Row className='m-1 p-3' style={{  alignItems:'center', paddingTop:'10px', paddingBottom:'10px', paddingLeft:'20px', paddingRight:'20px' }}>
				{/* <Col md={9}>
					<h2 style={{ color:'black', fontWeight:800 }}> How it works </h2>
					<p style={{ color:'black' }}> Follow the following steps to add your Product & Services and have it ready to be shared to the world in less than 5 minutes. </p>
				</Col> */}
				
					<img src={logo} alt='sfera-plots-logo' className='center' style={{ width: dimensions.width < 500? '35%': '100px'}}/>
				
			</Row>

			<Row className='m-1 p-3' >
				<div style={{border:'1px solid #63dca0', borderRadius: dimensions.width >= 800? '3%': '5%', padding:'10px',  }}>
					<Row className='mt-2 mb-2'>
						<Col xs={2}><div className='onboardingNumBox'>1</div></Col>
						<Col xs={10}>Tap  +  to add 'Product & Services'</Col>
					</Row>
					<Row className='mt-2 mb-2' >
						<Col xs={2}><div className='onboardingNumBox'>2</div></Col>
						<Col xs={10}>Choose your date, time, location and fee</Col>
					</Row>
					{/* <Row className='mt-2 mb-2' >
						<Col xs={2}><div className='onboardingNumBox'>3</div></Col>
						<Col xs={10}>You can select multiple times to share with invitees</Col>
					</Row> */}
					{/* <Row className='mt-2 mb-2' >
						<Col xs={2}><div className='onboardingNumBox'>4</div></Col>
						<Col xs={10}>You can group your products and services to create add-on deals</Col>
					</Row> */}
					<Row className='mt-2 mb-2' >
						<Col xs={2}><div className='onboardingNumBox'>3</div></Col>
						<Col xs={10}>Share the link via text, email or any platform</Col>
					</Row>
					<Row className='mt-2 mb-2' >
						<Col xs={2}><div className='onboardingNumBox'>4</div></Col>
						<Col xs={10}>Check your schedule/calendar</Col>
					</Row>
					<Row className='mt-2 mb-2' >
						<Col xs={2}><div className='onboardingNumBox'>5</div></Col>
						<Col xs={10}>Tap on event to adjust details and availability</Col>
					</Row>
				
				</div>
			</Row>

			<Row className='p-3 pt-0'>
					<div className='d-flex justify-content-between center' style={{width:'80%'}}>
						<Button className='m-2' color='lightGreen' style={{ width:'100%', fontSize:dimensions.width < 400 ? '12px' : '' }} onClick={() => skip()}>SKIP</Button>
						<Button className='m-2'  color='darkGreen' style={{ width:'100%', fontSize:dimensions.width < 400 ? '12px' : '', }} onClick={() => redirect()}>CONTINUE</Button>
					</div>
				</Row>
		</div>
	)
})