import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import PanelHeader from '../components/PanelHeader.jsx'
import Navbar from '../components/Navbar.jsx'
import Topbar from '../components/Topbar.jsx'

import { useAuthContext } from '../hooks/useAuthContext'
import { useUserContext } from '../hooks/useUserContext'
import { useWorkspaceContext } from '../hooks/useWorkspaceContext'

import dashRoutes from '../routes/routes'

import { withRouter } from '../utils/withRouter.js'
import { util } from '../utils/util'

export const Dashboard = withRouter((props) => {
	const { user, dispatch } = useUserContext()
	const { auth } = useAuthContext()
  	const { dispatch:dispatchWorkspace } = useWorkspaceContext()

	useEffect(() => {
		const fetchUser = async() => {
			const response = await fetch(`${util.expURL}/api/user/${auth.id}`, {
				headers: {'Authorization': `Bearer ${auth.token}`}
			})

			const json = await response.json()

			if (response.ok) {
				dispatch({ type:'SET_USER', payload:json })
			}
		}

		if (auth) {
			fetchUser()
		}
	}, [dispatch, auth]) 

	useEffect(() => {
		const fetchWorkspaceList = async() => {
			const response = await fetch(`${util.expURL}/api/workspaces/`, {
				headers: {'Authorization': `Bearer ${auth.token}`}
			})

			const json = await response.json()

			if (response.ok && user) {
				json.map(data => {
					if (data.workspace_id === user.current_workspace) {
						dispatchWorkspace({ type:'SET_WORKSPACE', payload:data })
					}
				})
			}
		}

		if (auth && user) {
			fetchWorkspaceList()
		}
	}, [dispatch, auth, user, dispatchWorkspace]) 
    
	return (
		<div className='wrapper'>
			<PanelHeader>
				<div className='fixed-top' style={{backgroundColor:'white'}}>
					<Topbar props={props} routes={dashRoutes} />
					<Navbar props={props} routes={dashRoutes} />
				</div>
			</PanelHeader>
			
			<div className='container'>
				<Outlet />
			</div>
		</div>
	)
})