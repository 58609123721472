import { useState } from 'react'
import { NavLink } from 'react-router-dom'

import { Button, Col, Row, FormGroup, Label, Input } from 'reactstrap'

import logo from '../assets/img/LOGO.png'

import FormInput from '../components/FormInput'

import { useSignUp } from '../hooks/useSignUp'
import { useDimensionContext } from '../hooks/useDimensionContext'
import { useAuthContext } from '../hooks/useAuthContext'
import { useUserContext } from '../hooks/useUserContext'
import { util } from '../utils/util'

import { withRouter } from '../utils/withRouter'

export const Register = withRouter((props) => {
	const { signUp, error } = useSignUp()
	const { dimensions } = useDimensionContext()
	const { user, dispatch } = useUserContext()

	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [password, setPassword] = useState('')
	const [passConfirm, setPassConfirm] = useState('')

	const [signUpBtn, setSignUpBtn] = useState(true)
	const [nameError, setNameError] = useState('');
	const [emailError, setEmailError] = useState('');
	const [phoneError, setPhoneError] = useState('')
	const [passError, setPassError] = useState('');
	const [passConfirmError, setPassConfirmError] = useState('');
	const [checked, setChecked] = useState(false);
	const [boxError, setBoxError] = useState('');

	const checker = (e) => {
		if (!name) setNameError('Name is required')
		else if (!email) setEmailError('Email is required')
		// else if (!phone) setPhoneError('Phone number is required')
		else if (!password) setPassError('Password is required')
		else if (passConfirm !== password) setPassConfirmError('Password and re-typed password should be the same')
		// else if (!checked) setBoxError('Please check the box above to continue registering')
		else if (name && email && password) signUpUser(e)
	}

	const signUpUser = async (e) => {
		e.preventDefault()

		setEmailError('')
		setPassError('')

		setSignUpBtn(false)
		await signUp(email, password)

		const auth = JSON.parse(sessionStorage.getItem('auth'))
		const USER = {
            ...user, 
            name,
            email
        }

        const response = await fetch(`${util.expURL}/api/user/${auth.id}`, {
            method: 'POST',
            body: JSON.stringify(USER),
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.token}`
            }
        })

		if (auth && response.ok) {
			dispatch({ type:'SET_USER', payload:USER })
            // console.log('User Profile has been updated')
			redirect()
		} 
		else {
			setSignUpBtn(true)
			// setBoxError('No authentication')
		}
	}

	const redirect = () => {
		props.navigate('/onboarding/profile')
	}

	const renderSignUpButton = () => {
		// console.log(signUpBtn)
		if (signUpBtn) {
		  return <Button color='darkGreen' style={{ width: '60%' }} onClick={(e) => checker(e)}>SIGN UP</Button>
		}
		if (!signUpBtn) {
			return (
				<Button disabled color='darkGreen' style={{ width: '60%' }}>
					<div className='d-flex align-items-center justify-content-center'>
						<div className='mr-2'>SIGNING UP &nbsp;</div>
						<div className='buttonloader'></div>
					</div>
				</Button>
			)
		}
	}

	return (
		<div className='starter-box-content' style={{marginTop:dimensions.widtrenderSignUpButtonh >= 800? '5rem': '3rem', marginBottom:dimensions.width >= 800? '5rem': '2rem', maxWidth: dimensions.width >= 1000? '70%':'90%'}}>
			<Row style={{ alignItems:'center', paddingTop:'10px', paddingBottom:'10px', paddingLeft:'20px', paddingRight:'20px' }}>
				<Col lg={6}>
					<div>
						<img src={logo} alt='sfera-plots-logo' className='center' style={{width: dimensions.width < 700? '40%': null}}/>
					</div>

					<div className='pt-4 pb-2' style={{ color:'black' }}>
						<h4><b>Welcome to SFERA Plots!</b></h4>
						<p>Create an account to build your autonomous store, and start to run your busineses smoothly  </p>
					</div>
				</Col>

				<Col lg={6}>
					{/* <div className='pb-4'>
						<Row>
							<Col md={6}><Button className='m-2' style={{ width:'100%' }}>Continue with Google</Button></Col>
							<Col md={6}><Button className='m-2' style={{ width:'100%' }}>Continue with Apple</Button></Col>
						</Row>
					</div>
					<div>
						<h6 className='lines'><span className='lines-background'>OR</span></h6>
					</div> */}
					<div className='pt-2 pb-2'>
						<FormInput title='name' label='Name' type='text' value={name}  onChange={e => {setName(e.target.value); setNameError('') }} />
						<div className='error-email' style={{color:'red', marginTop:-10, marginBottom:10}} > {nameError} </div>

						<FormInput title='email' label='E-mail' type='text' value={email}  onChange={e => {setEmail(e.target.value); setEmailError('') }} />
						<div className='error-email' style={{color:'red', marginTop:-10, marginBottom:10}} > {emailError} </div>
						
						{/* <FormInput title='phone' label='Phone Number' type='text' value={phone}  onChange={e => {setPhone(e.target.value); setPhoneError('') }} /> */}
						<div className='error-email' style={{color:'red', marginTop:-10, marginBottom:10}} > {phoneError} </div>
						
						<FormInput title='password' label='Password (minimum 8 characters)' type='password' value={password} onChange={e =>{ setPassword(e.target.value); setPassError('') }} />
						<div className='error-pass' style={{color:'red', marginTop:-10, marginBottom:10}} > {passError} </div>

						<FormInput title='passConfirm' label='Re-Type password' type='password' value={passConfirm} onChange={e =>{ setPassConfirm(e.target.value); setPassConfirmError('') }} />
						<div className='error-pass' style={{color:'red', marginTop:-10, marginBottom:10}} > {passConfirmError} </div>
					</div>
					{/* <div >
						<FormGroup check>
							<Label check>
								<Input type='checkbox' 
									onChange={(e) => {setChecked(e.target.value); setBoxError('')} }
								/>{' '}
								<div style={{ color:'black' }}>By signing up, you agree to our Terms of Service and Privacy Policy.</div>
							</Label>
						</FormGroup>
						{boxError && <div className='error-checkbox' style={{color:'red', marginBottom:10}} > {boxError} </div>}

					</div> */}

					<div className='pt-3 pb-2' style={{textAlign:'center'}}>
						{renderSignUpButton()}
						<div className='p-3'>
							<p style={{ color:'black' }}>Have an account? <NavLink to='/login' style={{ color:'#2abb73', textDecoration:'none' }} >Login Here</NavLink></p>
						</div>
						{error && <div className='mt-4 error'>{error}</div>}
					</div>            
				</Col>
			</Row>
		</div>
	)
})