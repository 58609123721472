import React, { useState } from 'react'

import { useUserContext } from '../hooks/useUserContext'
import { useDimensionContext } from '../hooks/useDimensionContext'
import { Card, Col, Row, Button, ModalFooter, ModalHeader, ModalBody, Modal } from 'reactstrap';

import { UserProfile } from './UserProfile'
import { WorkspaceDetails } from './WorkspaceDetails';
import { BankAccountDetails } from '../pages/BankAccountDetails'
import { LocationDetails } from '../pages/LocationDetails'
import PageHeader from '../components/PageHeader'
import { util } from '../utils/util'

export const AccountSettings = () => {
    const { user } = useUserContext()
    const { dimensions } = useDimensionContext()
    const [ selectTab, setSelectTab ] = useState(0)

    const _renderTabs = () => {

		const tabList = [
			{ item:'Profile' },
			{ item:'Workspace' }
		]

		let tabDisplay = tabList.map((data, index) => {
			const background = selectTab === index ? '#cdf5a0' : ''
			const color = selectTab === index ? '#63dca0' : ''
            const bold = selectTab === index ? 'bold' : 'normal'
			const borderRadius = selectTab === index ? '0.5rem' : ''
			const className = selectTab === index ? 'mb-4 cursor-pointer p-1 pl-3' : 'm-2 mb-4 pl-2 cursor-pointer'

			return <div key={index} className={className} style={{ fontSize:'18px',  color:color,  borderRadius:borderRadius, textAlign:'left', fontWeight:bold }} onClick={() => setSelectTab(index)}>{data.item}</div>
		})

		return <div>{tabDisplay}</div>
    }
    const _renderDetails = () => {
		
		let display

		switch (selectTab) {
			case 0: display = <UserProfile />
				break;
			case 1: display = <WorkspaceDetails />
				break;
			default: display = <UserProfile />
				break;
		}

		return <div>{display}</div>
	}
    return (
        <>
        {user? 
        <>
            <div className='headerMargin' style={{ textAlign:'left', }}>
                <PageHeader title='Account Settings' />
                <Card className='mt-4' style={{border:0}} >
                    <Row>
                        <Col md={3} >
                            {_renderTabs()}
                        </Col>
                        <Col md={9}>
                            {_renderDetails()}
                        </Col>
                    </Row>
                </Card>
                {/* {<UserProfile />}
                {<BankAccountDetails />}
                {<LocationDetails />} */}
            </div>
            <div className={dimensions.width < 830 ? 'mt-2' : 'mt-2'} style={{textAlign:'center', bottom:dimensions.width < 830 ? '60px' :10, position:'relative'}}>
                <small>Version {util.version}</small>
            </div>
            </>:
            <div className='headerMargin loader'></div> 
        }
        </>  
    )
}