import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Geocode from 'react-geocode'
import GoogleMapReact from 'google-map-react'
import { useGeolocated } from 'react-geolocated'
import Autocomplete from 'react-google-autocomplete'
import { Input } from 'reactstrap'

import { util } from '../utils/util'

const draggable = true

const Location = (props) => {
  const [ address, setAddress ] = useState('')
  const [ lat, setLat ] = useState(null)
  const [ lng, setLng ] = useState(null)
  const [ center, setCenter ] = useState({
    lat: null,
    lng: null
  })
  const [ zoom, setZoom ] = useState(15)
	const [ error, setError ] = useState(null)

  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  })

  const MapMarker = () => <div className='mapMarker'></div>

  const Deception = () => <div className='mapMarker' style={{ position: 'absolute', top: '50%', left: '50%' }}></div>

  useEffect(() =>{
    if (!isGeolocationAvailable) setError('Your browser does not support Geolocation!')

    if (!isGeolocationEnabled) setError('Geolocation is not enabled!')
  }, [])

  useEffect(() => {
    const setCoordinates = () => {
      setAddress(props.address ? props.address : '')
      setLat(props.lat ? parseFloat(props.lat) : coords.latitude)
      setLng(props.lat ? parseFloat(props.lng) : coords.longitude)
      setCenter({
        lat: props.lat ? parseFloat(props.lat) : coords.latitude,
        lng: props.lat ? parseFloat(props.lng) : coords.longitude,
      })
  
      if (lat !== null || lng !== null) getCoordinates()
    }

    if (coords && address === '') {
      setCoordinates()
    }
  }, [coords])

  const getCoordinates = (newCenter) => {
    const currentLat = newCenter ? newCenter.lat : center.lat
    const currentLng = newCenter ? newCenter.lng : center.lng

    Geocode.setApiKey(util.mapApi)
    Geocode.enableDebug()
    Geocode.fromLatLng(currentLat, currentLng).then(
      response => {
        const address = response.results[0].formatted_address

        setAddress(address)
        props.sendLocation(address, currentLat, currentLng)
      },
      error => {
        setError(error)
      }
    )
  }

  const onPlaceSelected = (place) => {
    const latValue = place.geometry.location.lat()
    const lngValue = place.geometry.location.lng()
    const address = place.formatted_address

    setLat(latValue)
    setLng(lngValue)
    setCenter({
      lat: latValue,
      lng: lngValue,
    })
    setAddress(address)
    props.sendLocation(address, latValue, lngValue)
  }

  const handleMapChange = ({ center, zoom }) => {
    setCenter(center)
    setZoom(zoom)
    getCoordinates(center)
  }

  return (
    <>
    <div className='parent-map'>
      <div style={{ height:'350px' }}>
        <GoogleMapReact
          draggable={draggable}
          onChange={handleMapChange}
          center={center}
          zoom={zoom}   
          bootstrapURLKeys={{ key:util.mapApi }}
        >
          <MapMarker
            className='place'
            lat={center.lat}
            lng={center.lng}
            text={'A'}>
          </MapMarker>
        </GoogleMapReact>
        <Deception className='place'></Deception>
      </div>
      <div className='autocomplete'>
        <Autocomplete
          apiKey={util.mapApi}
          style={{
            width: window.location.href.includes('settings') ? '960px' : '600px',
            maxWidth: '100%',
            height: '40px',
            marginBottom: 10,
            marginRight: 10,
            marginLeft: 10,
            paddingLeft: '16px',
            border:'1px solid #cad1d7',
            borderRadius: '0.375rem',
            color: '#8898aa'
          }}
          onPlaceSelected={onPlaceSelected}
          options={{
            types: [],
            componentRestrictions: { country:'my' },
          }}
        />
      </div>
      <Input className='mt-3' defaultValue={address} style={{ fontSize:'15px' }} />
    </div>

    {error && <div className='mt-4 error'>{error}</div>}
    </>
  )
}

Location.propTypes = {
  sendLocation: PropTypes.func.isRequired,
}

export default Location;