import { createContext, useReducer } from 'react'

export const StuffsContext = createContext() // make a new context to provide components in the application

// state - previous state before making changes
// action - have object of type property and payload
export const stuffsReducer = (state, action) => {
	switch (action.type) {
		case 'SET_STUFFS':
			return {
				stuffs: action.payload
			}
		case 'CREATE_STUFF':
			return {
				stuffs: [action.payload, ...state.stuffs]
			}
		case 'DELETE_STUFF':
			return {
				stuffs: state.stuffs.filter(booking => booking._id !== action.payload._id)
			}
		default:
			return state
	}
}

// provide context to the whole application component tree, so our components can access it
export const StuffsContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(
		stuffsReducer,    // reducer function name
		{ stuffs: null }  // initial value for states
	)

	// template
	return (
		<StuffsContext.Provider value={{...state, dispatch}}>
			{children}
		</StuffsContext.Provider>
	)
}