import React from 'react'
import { useLocation } from 'react-router-dom'

import { Login } from '../pages/Login'
import { Register } from '../pages/Register'
import { WelcomePage } from '../pages/WelcomePage'
import { ResetPassword } from '../pages/ResetPassword'
import { OnboardingProfile } from '../pages/OnboardingProfile'
import { OnboardingAvailability } from '../pages/OnboardingAvailability'
import { OnboardingDetails } from '../pages/OnboardingDetails'

export const Auth = () => {
  const location = useLocation()

  const content = () => {
    let display
    switch (location.pathname) {
      case '/welcome':
        display = <WelcomePage />
        break
      case '/login':
        display = <Login />
        break
      case '/register':
        display = <Register />
        break
      case '/reset-password':
        display = <ResetPassword />
        break
      case '/onboarding/profile':
        display = <OnboardingProfile />
        break
      case '/onboarding/availability':
        display = <OnboardingAvailability />
        break
      case '/onboarding/details':
        display = <OnboardingDetails />
        break
      default:
        break
    }
    return display
  }

  return <div>{content()}</div>
}