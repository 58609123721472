import { UserContext } from '../context/UserContext'
import { useContext } from 'react'

export const useUserContext = () => {
	// pass the value of UserContext from provider component
	const context = useContext(UserContext)

	if (!context) {
		throw Error('useUserContext must be used inside an UserContextProvider')
	}

	return context
}