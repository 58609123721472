import { DimensionContext } from '../context/DimensionContext'
import { useContext } from 'react'

export const useDimensionContext = () => {
	// pass the value of DimensionContext from provider component
	const context = useContext(DimensionContext)

	if (!context) {
		throw Error('useDimensionContext must be used inside an DimensionContextProvider')
	}

	return context
}