import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'

import StuffDetails from './StuffDetails'
import PageHeader from '../components/PageHeader'

import { useStuffsContext } from '../hooks/useStuffContext'
import { useAuthContext } from "../hooks/useAuthContext"
import { useUserContext } from '../hooks/useUserContext'
import { useDimensionContext } from '../hooks/useDimensionContext'

import { util } from '../utils/util'
import { useState } from 'react'

import { withRouter } from '../utils/withRouter'
import add from '../assets/img/add.png'

export const Stuff = withRouter((props) => {
	const { auth } = useAuthContext()
	const { stuffs, dispatch } = useStuffsContext()
	const { user } = useUserContext()
	const [ load, setLoad ] = useState('1')
	const { dimensions } = useDimensionContext()

	const [ productCount, setProductCount ] = useState(0)
	const [ serviceCount, setServiceCount ] = useState(0)

	useEffect(() => {
		const fetchStuffs = async() => {
			const response = await fetch(`${util.expURL}/api/stuffs/${user.current_workspace}`, {
				headers: {'Authorization': `Bearer ${auth.token}`}
			})

			const json = await response.json()

			if (response.ok) {
				dispatch({ type:'SET_STUFFS', payload:json })
				setLoad('2')
			}
		}

		if (auth && user) {
			fetchStuffs()
		}
	}, [dispatch, auth, user]) 

	useEffect(() => {
		const countStuff = () => {
			let count = 0, count1 = 0

			stuffs.map(stuff => {
				if (stuff.category === 'Product'){
					count = count + 1
				}
				if (stuff.category === 'Service'){
					count1 = count1 + 1
				}
			})

			setProductCount(count)
			setServiceCount(count1)
		}

		if (stuffs) {
			countStuff()
		}
	}, [stuffs])

	const renderContent = () => {
		return (
			<div className='mt-0' style={{ textAlign:'left' }}>
				{stuffs && stuffs.length !== 0 ? 
					stuffs.map(stuff => (
						<StuffDetails key={stuff._id} stuff={stuff} />
					))
					:
					<div className='p-5 text-center'>No product & services available. Please click on 'Add Product & Services' to add product & services.</div>
				}
			</div>
		)
	}

	const errorAdd = () => {
		return alert('Please add your Bank Account Details in Settings before adding Product & Services')
	}

	const navigate = () => {
		if (productCount > 4 && serviceCount > 4) {
			alert('You have reached the limit for adding items. \n\nPlease subscribe to add more items.')
			props.navigate('/subscription')
		} else {
			props.navigate('/product-services/new')
		}
	}
	
	const renderTop = () => {
		return (
			<>
			<div className='d-flex justify-content-start px-2 mt-2'>
				{stuffs && user.banks.length !== 0 ?
					// <Link className='link-button mt-2 mb-2' style={{ fontWeight: 'bold', backgroundColor:'#cdf5a0', color:'black', border:'2px solid black' }} to='/product-services/new'>Add Product & Services</Link>
					<Button style={{  backgroundColor:'transparent', color:'black', fontSize:dimensions.width >=830 ?'14px' : '12px' , fontWeight:'bold',border:'1px solid black'}} onClick={navigate}><img src={add} style={{width:'20px', marginBottom:'3px', marginRight:'5px' }}/>Add Product & Services</Button> 
				: <Button className='link-button mt-2 mb-2' style={{  backgroundColor:'transparent', color:'black', fontSize:dimensions.width >=830 ?'14px' : '12px', fontWeight:'bold', border:'1px solid black' }} onClick={errorAdd}><img src={add} style={{width:'20px', marginBottom:'3px', marginRight:'5px' }}/>Add Product & Services</Button> 
				}
			</div>
			</>
			
		)
	}

	return (
		<>
		{load === '1'? 
			<div className='headerMargin loader'></div>
			: <div className='pb-5 headerMargin' >
				<PageHeader title='Product & Services'/>
				{renderTop()}

				{renderContent()}
			</div>
		}
		</>
		
	)
})