import React from 'react'
import ReactDOM from 'react-dom/client'

import { App } from './App'

import { AuthContextProvider } from './context/AuthContext'
import { DimensionContextProvider } from './context/DimensionContext'
import { StuffsContextProvider } from './context/StuffContext'
import { UserContextProvider } from './context/UserContext'
import { WorkspaceContextProvider } from './context/WorkspaceContext'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <DimensionContextProvider>
    <AuthContextProvider>
      <UserContextProvider>
        <WorkspaceContextProvider>
          <StuffsContextProvider>
            <App />
          </StuffsContextProvider>
        </WorkspaceContextProvider>
      </UserContextProvider>
    </AuthContextProvider>
  </DimensionContextProvider>
)