import React, { useState } from 'react'

import { v4 as uuidv4 } from 'uuid'
import { Button, Row, Col } from 'reactstrap'

import logo from '../assets/img/LOGO.png'

import FormInput from '../components/FormInput'

import { useAuthContext } from '../hooks/useAuthContext'
import { useUserContext } from '../hooks/useUserContext'
import { useDimensionContext } from '../hooks/useDimensionContext'

import { withRouter } from '../utils/withRouter'
import { util } from '../utils/util'

export const OnboardingProfile = withRouter((props) => {
	const { auth } = useAuthContext()
	const { dispatch } = useUserContext()
	const { dimensions } = useDimensionContext()

	const [name, setName] = useState('')
	const [phone, setPhone] = useState('')
	const [workspaceIDs, setWorkspaceIDs] = useState([])
	const [workspaces, setWorkspaces] = useState([])

	const [error, setError] = useState(null)

	const saveUser = async() => {
		const user = { name, phone }

		if (!auth) {
			setError('You must be logged in')
			return
		}

		const response = await fetch(`${util.expURL}/api/user/${auth.id}`, {
			method: 'POST',
			body: JSON.stringify(user),
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${auth.token}`
			}
		})

		const json = await response.json()

		if (!response.ok) {
			setError(json.error)
		}

		if (response.ok) {
			setName('')
			setPhone('')
			setError(null)
			// console.log('New user profile added', json)
			dispatch({ type:'CREATE_USER', payload:json })
			auth && redirect()
		}
 	}

	const redirect = () => {
		props.navigate('/onboarding/availability')
	}

	const handleWorkspaceData = (type, name) => {
		const uuid = uuidv4()
		let workspace_id = 'workspace_' + uuid.slice(uuid.length - 10)

		let members = []
		let newMember = {
			id: auth.id,
			role: 'Admin'
		}
		members.push(newMember)
		
		const newWorkspace = {
			workspace_id,
			current_subscription:"Basic",
			type,
			name,
			members,
			stuffs: [],
			logo: '',
		}
		
		workspaceIDs.push(workspace_id)
		workspaces.push(newWorkspace)
	}

	const skip = async () => {
		handleWorkspaceData('Personal', '')

		const user = {
			name, 
			phone,
			current_workspace : workspaceIDs[0],
			workspaces: workspaceIDs,
			time_zone : '',
			date_format : 'DD/MM/YYYY',
			time_format : '12h (am/pm)',
			week_start : 'Sunday',
			week_display : '7 Days',
			availability: [],
		}

		const response = await fetch(`${util.expURL}/api/user/${auth.id}`, {
			method: 'POST',
			body: JSON.stringify(user),
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${auth.token}`
			}
		})

		workspaces.map(async workspace => {
			const response1 = await fetch(`${util.expURL}/api/create-workspace`, {
				method: 'POST',
				body: JSON.stringify(workspace),
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${auth.token}`
				}
			})

			const json1 = await response1.json()

			if (!response1.ok) {
				setError(json1.error)
			}
		})

		const json = await response.json()

		if (!response.ok) {
			setError(json.error)
		}

		if (response.ok) {
			setName('')
			setPhone('')
			setWorkspaceIDs([])
			setWorkspaces([])
			setError(null)
			dispatch({ type:'CREATE_USER', payload:json })
			props.navigate('/')
		}
	}

	return (
		<div className='starter-box-content' style={{ marginTop:dimensions.width >= 800? '5rem': '3rem', marginBottom:dimensions.width >= 800? '5rem': '2rem', maxWidth: dimensions.width >= 1000? '50%':'90%' }} >
			<Row className='m-1 p-2' style={{ borderBottom:'1px solid #d2d2d2' }}>
				<Col xs={8}>
					<h2 style={{ color:'black', fontWeight:800 }}>Set Up Profile</h2>
					<p style={{ color:'black' }}> Please input your name and contact information inside the space provided below. </p>
				</Col>
				<Col xs={4} className='align-self-center'>
					<img src={logo} alt='yo' className='center' style={{ width:dimensions.width < 400 ? '60px' : '80px' }}/>
				</Col>
			</Row>

			<Row className={dimensions.width >= 800 ? 'form-input p-4' : 'form-input p-0 mt-4 mb-2'}>
				<FormInput title='name' label='Name:' type='text' value={name} onChange={e => setName(e.target.value)} />
				<FormInput title='phone' label='Phone:' type='text' value={phone} onChange={e => setPhone(e.target.value)} />
			</Row>

			<Row className='p-3 pt-4 pe-2'>
				<div  className='d-flex justify-content-between center' style={{width:'80%'}}>
					<Button className='m-2' color='lightGreen' style={{ width:'100%', fontSize:dimensions.width < 400 ? '12px' : '',}} onClick={() => skip()}>SKIP</Button>
					<Button className='m-2' color='darkGreen' style={{ width: '100%', fontSize:dimensions.width < 400 ? '12px' : '', }} onClick={() => saveUser()}>CONTINUE</Button>
				</div>
			</Row>

			{error && <div className='error'>{error}</div>}
		</div>
	)
})