import { createContext, useReducer, useEffect } from 'react';

export const AuthContext = createContext()

export const authReducer = (state, action) => {
	switch (action.type) {
		case 'LOGIN': 
			return { auth: action.payload }
		case 'EXISTING_EMAIL': 
			return { auth: action.payload }
		case 'LOGOUT': 
			return { auth: null }
		default:
			return state
	}
}

export const AuthContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(authReducer, {
		auth: null
	})

	// check token
	useEffect(() => {
		const auth = JSON.parse(sessionStorage.getItem('auth'))

		// if user has value
		if (auth) {
			dispatch({ type:'LOGIN', payload:auth })
		}
	}, [])

	return (
		<AuthContext.Provider value={{...state, dispatch}}>
			{ children }
		</AuthContext.Provider>
	)
}