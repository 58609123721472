/* eslint-disable no-lone-blocks */
import { useState, useRef, useEffect } from "react";
import { withRouter } from "../utils/withRouter";
import { Button, CloseButton, Col, Container, Row } from 'reactstrap'

import logo from '../assets/img/LOGO.png'
import FormInput from '../components/FormInput'

import { useResetPassword } from '../hooks/useResetPassword'
import { useExistingEmail } from "../hooks/useExistingEmail";
import { useAuthContext } from '../hooks/useAuthContext'
import { useDimensionContext } from '../hooks/useDimensionContext'
import { useUserContext } from '../hooks/useUserContext'

import { util } from '../utils/util'

export const ResetPassword = withRouter((props) => {
    const { resetPassword} = useResetPassword()
    const { existingEmail, isLoading } = useExistingEmail()
    const { user, dispatch } = useUserContext()
    const { dimensions } = useDimensionContext()
	const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ passConfirm, setPassConfirm ] = useState('')
    const [ emailError, setEmailError ] = useState('');
    const [ passError, setPassError ] = useState('');
	const [ passConfirmError, setPassConfirmError ] = useState('');
    const [ currentView, setCurrentView ] = useState('email')
    const [ resetBtn, setResetBtn ] = useState(true)

    const checker = (e) => {
		if (!password) setPassError('Password is required')
		else if (passConfirm !== password) setPassConfirmError('Password and confirm password should be the same')
		else if (password && passConfirm) resetPass(e)
	}
 


    const emailChecker =  async () => {
        await existingEmail(email)
        const auth =  JSON.parse(sessionStorage.getItem('auth'))
        if (!email) setEmailError('Email is required')
        else if(auth.email !== email) setEmailError('Incorrect email!')
       else {
            setCurrentView('password')
        }
        
    }

    const resetPass = async (e) => {
		e.preventDefault()

        setEmailError('')
		setPassError('')

		setResetBtn(false)
		await resetPassword(email, password)


		const auth = JSON.parse(sessionStorage.getItem('auth'))
		const USER = {
            ...user, 
        }
        const response = await fetch(`${util.expURL}/api/user/${auth.id}`, {
            method: 'POST',
            body: JSON.stringify(USER),
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.token}`
            }
        })

		if (auth && response.ok) {
			dispatch({ type:'SET_USER', payload:USER })
            // console.log('User Profile has been updated')
			redirect()
		} 
		else {
			setResetBtn(true)
			// setBoxError('No authentication')
		}
	}

    const redirect = () => {
		props.navigate('/login')
	}

    const renderResetButton = () => {
		if (resetBtn) {
		  return <Button color='darkGreen' style={{ width: '60%' }} onClick={(e) => checker(e)}>Reset Password</Button>
		}
		if (!resetBtn) {
			return (
				<Button disabled={isLoading} color='darkGreen' style={{ width: '60%' }}>
					<div className='d-flex align-items-center justify-content-center'>
						<div className='mr-2'>Resetting &nbsp;</div>
						<div className='buttonloader'></div>
					</div>
				</Button>

			)
		}
	}

    return (
        <div className='starter-box-content' style={{marginTop:dimensions.width >= 800? '5rem': '3rem', marginBottom:dimensions.width >= 800? '5rem': '2rem', maxWidth: dimensions.width >= 800? '70%':'90%'}}>
			<Row style={{ alignItems:'center', paddingTop:'10px', paddingBottom:'10px', paddingLeft:'20px', paddingRight:'20px' }}>
				<Col lg={6}>
                    <div>
						<img src={logo} alt='sfera-plots-logo' className='center' style={{width:'30%'}}/>
					</div>
                    {currentView === 'email' ? 
                        <div className='pt-4 pb-2' style={{ color:'black' }}>
                            <h4><b>Forgot Password</b></h4>
                            <p>Enter your email address</p>
					    </div>:
                        <div className='pt-4 pb-2' style={{ color:'black' }}>
                            <h4><b>Reset Password</b></h4>
                            <p>Create a new password</p>
                        </div>
                    }
                    
				</Col>
                {currentView === 'email' ? 
                    <Col lg={6}>
                        <div className='pt-2 pb-0'>
                
                            <FormInput title='email' label='E-mail' type='text' value={email} onChange={e => {setEmail(e.target.value) ; setEmailError('')}} />
                            <div className='error-pass' style={{color:'red', marginTop:-10, marginBottom:10}} > {emailError} </div>
                        </div>
                        <Row>
                            <Col>
                                <div className='pt-2 pb-2' >
                                <Button style={{ width: '60%' , fontSize: dimensions.width < 400 ? '13px': '16px',backgroundColor:'white',color:'black', borderWidth:2, borderColor:'black'}} onClick={redirect}>BACK</Button>
                                </div> 
                            </Col>
                            <Col>
                                <div className='pt-2 pb-2' style={{textAlign: 'end'}}  >
                                <Button color="lightGreen" style={{ width: '60%' , fontSize: dimensions.width < 400 ? '13px': '16px'}} onClick={e => emailChecker(e)}>CONTINUE</Button>
                                </div> 
                            </Col>
                       
                        </Row>
                        
                    </Col>: 
                    <Col lg={6}>
                        <FormInput title='password' label='Password (minimum 8 characters)' type='password' value={password} onChange={e =>{ setPassword(e.target.value); setPassError('') }} />
						<div className='error-pass' style={{color:'red', marginTop:-10, marginBottom:10}} > {passError} </div>

						<FormInput title='passConfirm' label='Confirm password' type='password' value={passConfirm} onChange={e =>{ setPassConfirm(e.target.value); setPassConfirmError('') }} />
						<div className='error-pass' style={{color:'red', marginTop:-10, marginBottom:10}} > {passConfirmError} </div>
                        
                        <div className='pt-3 pb-2' style={{textAlign:'center'}}>
                            {renderResetButton()}
                        </div>      
                    </Col>
                    }
				
                 
                    
				
			</Row>
		</div>
    )
})