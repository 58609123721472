import React, { useState, useEffect } from 'react'

import { FormGroup, Label, Input, Card, CardHeader, CardBody, Row, Col, FormText, Button, InputGroup, Popover, PopoverBody, Alert } from 'reactstrap'
import { DayPicker } from 'react-day-picker'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { v4 as uuidv4 } from 'uuid'
// import axios from 'axios'

import { FaLongArrowAltLeft } from 'react-icons/fa'
import { FaInfoCircle } from 'react-icons/fa'
import { FaTimes } from 'react-icons/fa'
import { FaTrash } from 'react-icons/fa'
import { FaExclamationTriangle} from 'react-icons/fa'

import { ProgressUnresponsive } from '../components/ProgressUnresponsive'
import { ProgressResponsive } from '../components/ProgressResponsive'
import Location from '../components/Location'

import { useStuffsContext } from '../hooks/useStuffContext'
import { useAuthContext } from '../hooks/useAuthContext'
import { useUserContext } from '../hooks/useUserContext'
import { useWorkspaceContext } from '../hooks/useWorkspaceContext'
import { useDimensionContext } from '../hooks/useDimensionContext'

import { util } from '../utils/util'
import { withRouter } from '../utils/withRouter'

export const AddStuff = withRouter((props) => {
	const { auth } = useAuthContext()
	const { stuffs, dispatch } = useStuffsContext()
	const { user, dispatch: dispatchUser} = useUserContext()
  	const { workspace:globalWorkspace } = useWorkspaceContext()
	const { dimensions } = useDimensionContext()

	const [ link, setLink ] = useState('')
	const [ stuffID, setStuffID ] = useState('')
	const [ userID, setUserID ] = useState('')
	const [ workspaceID, setWorkspaceID ] = useState('')
	const [ bookings, setBookings ] = useState([])

	const [ locationOption, setLocationOption ] = useState('')
	const [ locationChecker, setLocationChecker ] = useState(false)
	const [ dateTimeChecker, setDateTimeChecker ] = useState(false)
	const [ paymentChecker, setPaymentChecker ] = useState(false)
	const [ copied, setCopied ] = useState(false)
	const [ setError ] = useState('')
	const [ currentSection, setCurrentSection ] = useState(1)
	const [ linkDisplay, setLinkDisplay ] = useState(false)
	const [ currency, setCurrency ] = useState('MYR')
	const [ currencyList, setCurrencyList ] = useState([])

	// Section 1
	const [ title, setTitle ] = useState('')
	const [ category, setCategory ] = useState('Service')
	const [ photo, setPhoto ] = useState('')
	const [ description, setDescription ] = useState('')
	const [ deal, setDeal ] = useState(false)
	const [ deals, setDeals ] = useState([{ id:'', name: '', price: '' }])
	const [ address, setAddress ] = useState('')
	const [ lat, setLat ] = useState('')
	const [ lng, setLng ] = useState('')

	// Section 2
	const [ recurring, setRecurring ] = useState('One time')
	const [ reschedule, setReschedule ] = useState(null)
	const [ price, setPrice ] = useState('')

	// Section 2 - one time
	const [ date, setDate ] = useState(new Date())
	const [ startTime, setStartTime ] = useState('')
	const [ endTime, setEndTime ] = useState('')
	const [ availabilityID, setAvailabilityID ] = useState('')
	const [ availability, setAvailability ] = useState([])
	const [ availabilityScheme, setAvailabilityScheme ] = useState({
		sunday: {
			available: false,
			hours: [{
				start_time: "",
				end_time: ""
			}]
		},
		monday: {
			available: false,
			hours: [{
				start_time: "",
				end_time: ""
			}]
		},
		tuesday: {
			available: false,
			hours: [{
				start_time: "",
				end_time: ""
			}]
		},
		wednesday: {
			available: false,
			hours: [{
				start_time: "",
				end_time: ""
			}]
		},
		thursday: {
			available: false,
			hours: [{
				start_time: "",
				end_time: ""
			}]
		},
		friday: {
			available: false,
			hours: [{
				start_time: "",
				end_time: ""
			}]
		},
		saturday: {
			available: false,
			hours: [{
				start_time: "",
				end_time: ""
			}]
		},
	})
	const [ productCount, setProductCount ] = useState(0)
	const [ serviceCount, setServiceCount ] = useState(0)
	const [ prevCategory, setPrevCategory ] = useState('')

	// Section 2 - recurring
	const [ duration, setDuration ] = useState('')
	const [ maxBooking, setMaxBooking ] = useState('')
	const [ popover, setPopover ] = useState(false)

	// Section 3 - if set price
	const [ banks, setBanks ] = useState([])
	const [ phone, setPhone ] = useState('')
	const [ createBtn, setCreateBtn ] = useState(false)

	const dummyStartTime = [ '8:00AM', '8:30AM', '9:00AM', '9:30AM', '10:00AM', '10:30AM', '11:00AM', '11:30AM', '12:00PM', '12:30PM', '1:00PM', '1:30PM', '2:00PM', '2:30PM', '3:00PM', '3:30PM', '4:00PM', 
	'4:30PM', '5:00PM', '5:30PM', '6:00PM' ]

	const dummyEndTime = [ '8:30AM', '9:00AM', '9:30AM', '10:00AM', '10:30AM', '11:00AM', '11:30AM', '12:00PM', '12:30PM', '1:00PM', '1:30PM', '2:00PM', '2:30PM', '3:00PM', '3:30PM', '4:00PM', 
	'4:30PM', '5:00PM', '5:30PM', '6:00PM', '6:30PM' ]

	const dummyStartTimeHrs = [ '0800', '0830', '0900', '0930', '1000', '1030', '1100', '1130', '1200', '1230', '1300', '1330', '1400', '1430', '1500', '1530', '1600', '1630', '1700', '1730', '1800' ]

	const dummyEndTimeHrs = [ '0830', '0900', '0930', '1000', '1030', '1100', '1130', '1200', '1230', '1300', '1330', '1400', '1430', '1500', '1530', '1600', '1630', '1700', '1730', '1800', '1830' ]

	const dummyDuration = [ '15min', '30min', '45min', '60min', '90min', '120min' ]

	const dummyAvailability12Start = [ '8:00AM', '9:00AM', '10:00AM', '11:00AM', '12:00PM', '1:00PM', '2:00PM', '3:00PM', '4:00PM', '5:00PM' ]

	const dummyAvailability12End = [ '9:00AM', '10:00AM', '11:00AM', '12:00PM', '1:00PM', '2:00PM', '3:00PM', '4:00PM', '5:00PM', '6:00PM' ]

	const dummyAvailability24Start = [ '0800', '0900', '1000', '1100', '1200', '1300', '1400', '1500', '1600', '1700' ]

	const dummyAvailability24End = [ '0900', '1000', '1100', '1200', '1300', '1400', '1500', '1600', '1700', '1800' ]

	useEffect(() => {
		const fetchCurrency = async() => {
			const response = await fetch(`${util.expURL}/api/currency/`, {
				headers: {'Authorization': `Bearer ${auth.token}`}
			})

			const json = await response.json()

			if (response.ok) {
				setCurrencyList(json)
			}
		}

		if (auth) {
			fetchCurrency()
		}
	}, [dispatch, auth]) 

	useEffect(() => {
		const fetchStuffs = async() => {
			const response = await fetch(`${util.expURL}/api/stuff/${props.id}`, {
				headers: {'Authorization': `Bearer ${auth.token}`}
			})

			const jsonResp = await response.json()
			
			let json = jsonResp.stuff
			if (response.ok) {
				if (json.deals && json.deals[0].id !== '') {
					setDeal(true)
				}
				setCategory(json.category)
				setPrevCategory(json.category)
				setTitle(json.title)
				setPhoto(json.photo? json.photo : null)
				setDescription(json.description)
				setRecurring(json.recurring)
				setDeals(json.deals)
				setAddress(json.address)
				setLat(json.lat)
				setLng(json.lng)
				json.date !== '' ? setDate(new Date(json.date)) : setDate(new Date())
				setStartTime(json.start_time)
				setEndTime(json.end_time)
				setReschedule(json.reschedule === true ? 'Yes' : 'No')
				setCurrency(json.currency)
				setPrice(json.price)
				setDuration(json.duration)
				setMaxBooking(json.max_booking)
				setAvailabilityID(json.availability_id)
				setUserID(json.user_id)
				setWorkspaceID(json.workspace_id)
				setStuffID(json.stuff_id)
				setLink(json.link)
				setLocationChecker(json.location_checker)
				setDateTimeChecker(json.date_time_checker)
				setPaymentChecker(json.payment_checker)
				setLocationOption(json.location_option)
				setBookings(json.bookings)
			}

			if (stuffID && photo === '') {
				getSingleFile()
			}
		}

		const getSingleFile = async () => {
			try {
				const response = await fetch(`${util.expURL}/api/getSingleFile/${stuffID}`)
	
				const json = await response.json()
			
				if (!response.ok) {
					setError(json.error)
				}
				
				if (response.ok) {
					setPhoto(json)
				}
			} catch (error) {
				throw error;
			}
		}
		
		if (auth && props.id) {
			fetchStuffs()
		}
		if (!props.id && stuffID === '') {
			const uuid = uuidv4()
			setStuffID('stuff_' + uuid.slice(uuid.length - 10))
		}
	}, [auth, photo, stuffID])

	useEffect(()=> {
		const fetchUserStuff = async() => {
			if (user && user.banks){
				setLat(user.lat)
				setLng(user.lng)
				setBanks(user.banks)
				setPhone(user.phone)
			}else {
				const response = await fetch(`${util.expURL}/api/user/${auth.id}`, {
					headers: {'Authorization': `Bearer ${auth.token}`}
				})
	
				const json = await response.json()
	
				if (response.ok) {
					dispatchUser({ type:'SET_USER', payload:json })
				}
			}
			
			if (stuffs){	
				let count = productCount
				let count1 = serviceCount
				stuffs.map((stuff, ind) => {
					if (stuff.category === 'Product'){
						count = count + 1
					}
					if (stuff.category === 'Service'){
						count1 = count1 + 1
					}
				})

				localStorage.setItem('stuffs', JSON.stringify(stuffs))

				if (count > 4) setCategory('Service')
				if (count1 > 4) setCategory('Product')

				setProductCount(count)
				setServiceCount(count1)
			}else {
				let stuffData = JSON.parse(localStorage.getItem('stuffs'))
				dispatch({ type:'SET_STUFFS', payload:stuffData })
			}
		}
		if (auth ) {
			fetchUserStuff()
			// console.log(user)
		}
	}, [user])

	useEffect(() => {
		const fetchAvailability = async () => {
			const response = await fetch(`${util.expURL}/api/availability/${availabilityID}`, {
				headers: {'Authorization': `Bearer ${auth.token}`}
			})

			const json = await response.json()

			if (response.ok) {
				setAvailability(json.availability)
				setAvailabilityScheme(json.availability)
			}
		}

		if (auth && availabilityID) {
			fetchAvailability()
		}
	}, [auth, availabilityID])

	useEffect(() => {
		const fetchAvailability = () => {
			let newAvailability = []
			let weekDisplay = user.week_display
			let weekStart = user.week_start
			// console.log(weekStart,weekDisplay)
			if ((weekDisplay === '7 Days' && weekStart === 'Sunday') || (!weekDisplay || !weekStart)) {
				for (const day in availabilityScheme) {
					let currentDay = availabilityScheme[day]
					currentDay.day = availabilityScheme[day].day ? availabilityScheme[day].day  : day
	
					newAvailability.push(currentDay)
					setAvailability(newAvailability)
				}
			}
			if (weekDisplay === '5 Days' && weekStart === 'Sunday') {
				for (const day in availabilityScheme) {
					if (day !== 'friday' && day !== 'saturday') {
						let currentDay = availabilityScheme[day]
						currentDay.day = availabilityScheme[day].day ? availabilityScheme[day].day  : day
		
						newAvailability.push(currentDay)
						setAvailability(newAvailability)
					}
				}
			}
			if (weekDisplay === '7 Days' && weekStart === 'Monday') {
				let sunday = {
					...availabilityScheme['sunday'],
					day: 'sunday'
				}
			
				for (const day in availabilityScheme) {
					if (day !== 'sunday') {
						let currentDay = availabilityScheme[day]
						currentDay.day = availabilityScheme[day].day ? availabilityScheme[day].day  : day
		
						newAvailability.push(currentDay)
					}
				}
				newAvailability.push(sunday)
				setAvailability(newAvailability)
			}
			if (weekDisplay === '5 Days' && weekStart === 'Monday') {
				for (const day in availabilityScheme) {
					if (day !== 'sunday' && day !== 'saturday' ) {
						let currentDay = availabilityScheme[day]
						currentDay.day = availabilityScheme[day].day ? availabilityScheme[day].day  : day
		
						newAvailability.push(currentDay)
						setAvailability(newAvailability)
					}
				}
			}
		}
	
		if (auth && availability.length === 0 && user) {
			fetchAvailability()
		}
	}, [auth, availability, availabilityScheme, user])

	const _back = () => {
		return props.navigate('/product-services')
	}

	const handleCurrentSection = (current) => {
		if (title === ''){
            alert("Please fill in the Title")
            return 
        } else {
			setCurrentSection(current)
			window.scrollTo({ top: 100, left: 100, behavior: 'smooth' })
		}
	}

	const handleUpload = async e => {
		// setPhoto(e.target.files[0])
		if (e.target.files[0] !== '' && e.target.files[0] !== null) {
			const formData = new FormData()

			formData.append('file', e.target.files[0])
			try {
				e.target.files[0] !== undefined && await fetch(`${util.expURL}/api/uploadSingleFile/${stuffID}`, {
					method: 'POST',
					body: formData,
				})
				.then(async(res)=>{

					const response = await fetch(`${util.expURL}/api/getSingleFile/${stuffID}`, {
						headers: {'Authorization': `Bearer ${auth.token}`}
					})
		
					const json = await response.json()
					setPhoto(json)
				})
			} catch (error) {
				throw error
			}
		}
	}
	const handleDeleteImage = () => {
		// setPhoto(null)
		// reminder that state is not set instantinously
		if (photo !=='' && photo !== null) {
			try {
				fetch(`${util.expURL}/api/deleteSingleFile/${stuffID}`, {
					method: 'POST',
					headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${auth.token}`},
					body: JSON.stringify({'path': photo.file_path})
				}).then( () => {
					setPhoto(null)}
					)
			} catch (error) {
				throw error
			}
		}
	}

	const handleDealChange = (deal, index, state) => {
		let newDeals = [...deals]

		stuffs.map(item => {
			if (item.title === deal) {
				newDeals[index].id = item.stuff_id
				newDeals[index].price = item.price
			}

			return newDeals
		})

		newDeals[index][state] = deal

		setDeals(newDeals)
	}

	// const handleBankChange = (e, ind, bank) => {
	// 	let newBanks = [...banks]
	// 	newBanks[ind] = {
	// 		...bank,
	// 		[e.target.name]: e.target.value

	// 	}

	// 	setBanks(newBanks)
	// }

	const handleDealChecker = () => {
		setDeal(!deal)

		if (deal === false) {
			setDeals([{ id:'', name: '', price: '' }])
		}
	}

	const addDeal = () => {
		const updateDeals = [
      ...deals,
      {
				id: '',
        name: '',
        price: ''
      }
    ]

    setDeals(updateDeals)
	}

	const removeDeal = (targetID, targetIndex) => {
		let array = deals.filter(item => item.id !== targetID)

		setDeals(array)
	}

	const addTime = (hours,ind) => {
		const updateHours = [
			...hours.hours,
			{
				start_time: '',
				end_time:''		
			}
		]
		const arr = availability
		arr[ind]= {...hours, hours:updateHours}
		setAvailability(arr)
		setAvailabilityScheme({...availabilityScheme,[hours.day]:{...hours,hours:updateHours}})
	}

	const removeTime = (item, hours, hourInd, itemInd) => {
		const array = hours.filter((hour,ind )=> ind !== hourInd )
	
		const arr = availability
		if (array.length === 0) {
			arr[itemInd]= {
				...item, 
				available: false,
				hours: [{ start_time: '', end_time: ''}],
			}
			setAvailabilityScheme({...availabilityScheme,[hours.day]:{...hours, available: false, hours: [{ start_time: '', end_time: ''}]}})
		}else {
			arr[itemInd]= {...item, hours:array}
			setAvailabilityScheme({...availabilityScheme,[hours.day]:{...hours,hours:array}})
		}

		setAvailability(arr)
	}

	const sendLocation = (address, lat, lng) => {
		setAddress(address)
		setLat(lat)
		setLng(lng)
  }

	const clearState = () => {
		setTitle('')
		setCategory(false)
		setPhoto('')
		setDescription('')
		setDeal(false)
		setDeals([{ id:'', name: '', price: '' }])
		setAddress('')
		setLat('')
		setLng('')
		setRecurring('One Time')
		setReschedule(null)
		setPrice('')
		setDate(new Date())
		setStartTime('')
		setEndTime('')
		setAvailability([])
		setAvailabilityScheme({
			sunday: {
				available: false,
				hours: []
			},
			monday: {
				available: false,
				hours: []
			},
			tuesday: {
				available: false,
				hours: []
			},
			wednesday: {
				available: false,
				hours: []
			},
			thursday: {
				available: false,
				hours: []
			},
			friday: {
				available: false,
				hours: []
			},
			saturday: {
				available: false,
				hours: []
			},
		})
		setDuration('')
		setMaxBooking('')

		return
	}

	const triggerAPI = (API, body) => {
		const response = fetch(API, {
			method: 'POST',
			body: JSON.stringify(body),
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${auth.token}`
			}
		})

		return response
	}

	const handleChange = (e,ind, item, hour) => {
		let arr = item.hours
		arr[ind] = {
			...hour,
			[e.target.name]: e.target.value.toUpperCase()
		}

		setAvailabilityScheme({
			...availabilityScheme,
			[item.day]: {
				...item,
				hours: arr
			},
		})
	}

	const saveStuff = async () => {
		setCreateBtn(true)
		let newLinkDisplay
		let currentAvailabilityID = availabilityID ? availabilityID : `availability_${uuidv4()}`
		
		if (!auth) {
			setError('You must be logged in')
			return
		}
		
		let code

		currencyList.find(item => {
			if (item.code === currency) {
				code = item.symbol
			}
		})

		let finalLink
		switch (util.mode) {
			case 'localhost': finalLink = `http://localhost:3001/#/confirmation/${stuffID}`
				break;
			case 'staging': finalLink = `https://reka.tech/staging/sfera-plots/user/#/confirmation/${stuffID}`
				break;
			case 'production': finalLink = `https://myplots.sfera.ai/user/#/confirmation/${stuffID}`
				break;
			default: finalLink = `https://myplots.sfera.ai/user/#/confirmation/${stuffID}`
				break;
		}

		const stuff = { 
			category, 
			title, 
			photo, 
			description, 
			recurring, 
			deals, 
			address, 
			lat, 
			lng, 
			date: dateTimeChecker === true && recurring === 'One time' ? date : '', 
			start_time: startTime, 
			end_time: endTime, 
			reschedule: reschedule === 'Yes' ? true : false, 
			currency, 
			code: code !== '' ? code : 'RM',
			price, 
			duration, 
			max_booking: maxBooking, 
			availability_id: recurring === 'Recurring' ? currentAvailabilityID : '',
			user_id: userID ? userID : '',
			workspace_id: workspaceID ? workspaceID : user.current_workspace,
			stuff_id: stuffID,
			link: link ? link : finalLink,
			location_checker: locationChecker,
			date_time_checker: dateTimeChecker,
			payment_checker: paymentChecker,
			location_option: locationOption,
			bookings: bookings ? bookings : [],
			phone,
		}

		const API = props.id === undefined ? `${util.expURL}/api/stuffs/` : `${util.expURL}/api/stuffs/update/${stuffID}`
	  
		const response = await triggerAPI(API, stuff)
		const json = await response.json()
	  
		if (!response.ok) {
		 	setError(json.error)
		}
	  
		if (response.ok) {
			setLink(json.link)
			setStuffID(json.stuff_id)
			clearState()
		}
		
		if (recurring === 'Recurring') {
			if (availabilityID === '') {
				const tempAvailability = user.availability
				const availabilityIDChecker = tempAvailability.find(id => id === currentAvailabilityID)
				availabilityIDChecker === undefined && tempAvailability.push(currentAvailabilityID)
				
				const userAvailability = {
					availability:tempAvailability,
				}
			
				const API = `${util.expURL}/api/user/${auth.id}`
				
				const response = await triggerAPI(API, userAvailability)
				const json = await response.json()
				if (!response.ok) {
					setError(json.error)
				}
			}
			
			const updateAvailability = availability.map((item,index) => {
				let updatedItem 
				if (item.available === true && item.hours) {
					let arr = item.hours

					arr.map((hour, ind)=> {
						const tempTimeStart = user.time_format === '12h (am/pm)' ? '8:00AM' : '0800'
						const tempTimeEnd = user.time_format === '12h (am/pm)' ? '9:00AM' : '0900'

						updatedItem = arr[ind]
						if (arr[ind].start_time === '' && arr[ind].end_time === '') arr[ind] = {start_time:tempTimeStart, end_time: tempTimeEnd}
						if (arr[ind].start_time === '') arr[ind] = {...hour, start_time:tempTimeStart}
						if (arr[ind].end_time === '') arr[ind] = {...hour, end_time: tempTimeEnd}
						
					})
				} 
				updatedItem = {...item}
				return updatedItem
			})

			// console.log((updateAvailability))
			const currentAvailability = {
				availability_id: currentAvailabilityID,
				name: '',
				custom: true,
				availability:updateAvailability,
			}

			const API1 = availabilityID === '' ? `${util.expURL}/api/availability-create/` : `${util.expURL}/api/availability/update/${currentAvailabilityID}/`
		
			const response1 = await triggerAPI(API1, currentAvailability)
			const json1 = await response1.json()
		
			if (!response1.ok) {
				setError(json1.error)
			}
		}

		const updateBanks = {
			banks
		}
	
		const API_BANK = `${util.expURL}/api/user/${auth.id}`
		
		const responseBank = await triggerAPI(API_BANK, updateBanks)
		const jsonBank = await responseBank.json()
		
		if (!response.ok) {
			setError(json.error)
		}

		if (photo === null) {
			try {
				// console.log('clearing',)
				await fetch(`${util.expURL}/api/deleteSingleFile/${stuffID}`, {
					method: 'POST',
				})
			} catch (error) {
				throw error
			}
		}

		if (!props.id) {
			const tempWorkspace = globalWorkspace.stuffs
			const stuffIDChecker = tempWorkspace.find(id => id === stuffID)
			stuffIDChecker === undefined && tempWorkspace.push(stuffID)

			let service_count = serviceCount, product_count = productCount
			if (category === 'Product') product_count = productCount + 1
			if (category === 'Service') service_count = serviceCount + 1

			const workspace = {
				stuffs:tempWorkspace,
				product_count: product_count,
				service_count: service_count
			}
		
			const API2 = `${util.expURL}/api/workspace/update/${user.current_workspace}`
		
			const response2 = await triggerAPI(API2, workspace)
			const json2 = await response2.json()
		
			if (!response2.ok) {
				setError(json2.error)
			}
		
			if (response2.ok) {
				newLinkDisplay = !linkDisplay
			}
		}else {
			if (prevCategory !== category) {
				let service_count, product_count 

				if (prevCategory === 'Service' && category === 'Product') {
					product_count = productCount + 1
					service_count = serviceCount - 1
				}
				if (prevCategory === 'Product' && category === 'Service') {
					service_count = serviceCount + 1
					product_count = productCount - 1 
				}

				const workspace2 = {
					product_count,
					service_count
				}
				const API3 = `${util.expURL}/api/workspace/update/${user.current_workspace}`
		
				const response3 = await triggerAPI(API3, workspace2)
				const json3 = await response3.json()
			
				if (!response3.ok) {
					setError(json3.error)
				}
			
				if (response3.ok) {
					newLinkDisplay = !linkDisplay
				}
			}
		}
		
		auth && !props.id ? setLinkDisplay(newLinkDisplay) : _back()
		setCreateBtn(false)
	}

	const renderCreationLink = () => {
		return (
			<>
			<h4>Woohoo your event is successfully created!</h4>
			<h6>Share the link of your stuff via text, email or any platform to your client for them to book/RVSP/buy a slot</h6>

			<InputGroup className='mt-5' style={{ width:'625px', maxWidth:'100%' }}>
				{copied ? <Input className='mt-2 mb-2 font-weight-bold form-control-sm' disabled value={link} /> : <Input className=' mt-2 mb-2 font-weight-bold form-control-sm' disabled value={link} />}
					<CopyToClipboard text={link} 
						onCopy={async () => {
							// await setCopyValue(link)
							await setCopied(true)
							await setTimeout(() => setCopied(false), 3000)
						}}>
						<Button className='ms-2 mt-2 mb-2' size='sm' style={{ backgroundColor:'#cdf5a0', color:'black', border:'2px solid black' }} ><span style={{ fontWeight:'600' }}>Copy Link</span></Button>
					</CopyToClipboard>
			</InputGroup>
			</>
		)
	}

	const info = (text) => {
		return (
			<div style={{alignContent:'left', marginLeft:5}}>
				{/* <button size='sm' style={{ backgroundColor:'#cdf5a0', color:'black', border:'2px solid black' }}  id='popover_info' onClick={handleClick}><i className='now-ui-icons travel_info' /> <FaInfoCircle /> </button> */}
				<FaInfoCircle id='popover_info'  onMouseOver={()=>setPopover(true)} onMouseOut={()=>setPopover(false)}/> 
				<Popover placement='bottom' isOpen={popover} target='popover_info'   >
					<PopoverBody>
					{text}
					</PopoverBody>
				</Popover>
			</div>
		)
		
	}
	const renderAvailability = () => {
		let dummyAvailabilityStart = user.time_format === '12h (am/pm)' ? dummyAvailability12Start : dummyAvailability24Start

		let dummyAvailabilityEnd = user.time_format === '12h (am/pm)' ? dummyAvailability12End : dummyAvailability24End
		
		const optionListStart = dummyAvailabilityStart.map((item, index) => {
			return <option key={index} value={item}>{item}</option>
		})
		const optionListEnd = dummyAvailabilityEnd.map((item, index) => {
			return <option key={index} value={item}>{item}</option>
		})
		
		return (
			<Row className='mt-4' style={{ textAlign:'left' }}>
				<Col md={3} style={{ display:'flex', }} >
					<p className='mb-4' style={{ fontSize:'18px', fontWeight:'700' }}>Availability</p>
					{info('lorem ipsum')}
				</Col>
				<Col md={9}>
					{
						availability.map((item, index) => (
							<Row key={index} className='mb-3'>
								<Col  xs={4}>
									<div className='d-flex mb-3'>
										<Label check className='d-flex'>
											<Input className='ms-1 me-3' type='checkbox' name='day' style={{ width:'17px', height:'17px' }} value={item.available} checked={item.available === true ? true : false}
												onChange={() => {
													const arr = availability
													arr[index]= {...item, available:!item.available}
													setAvailability(arr)
													setAvailabilityScheme({
														...availabilityScheme,
														[item.day]: {
															...item,
															available : !item.available
														}
													})
												}
												} 
											/> 
											<span style={{ fontSize:dimensions.width > 400? '15px' : '13px', fontWeight:'600' }}>{item.day.charAt(0).toUpperCase() + item.day.slice(1)}</span>
										</Label>
									</div>
								</Col>
								<Col xs={8}>
									{item.available && 
									<>
										{item.hours.map((hour, ind)=> (
											<div key={ind} className='d-flex'>
												{/* {console.log(hour, ind, availabilityScheme)} */}
												<FormGroup className='m-0' style={{ width:'150px', maxWidth:'100%' }}>
													<Input type='select' style={{ fontSize:dimensions.width > 400? '15px' :'12px' }} name='start_time' value={hour.start_time} onChange={(e) => handleChange(e,ind,item,hour)}>
														{optionListStart}
													</Input>
												</FormGroup>
											<div className='m-3 mb-2 mt-2' style={{ fontSize:'15px', fontWeight:'700' }}>-</div>
												<FormGroup className='m-0' style={{ width:'150px', maxWidth:'100%' }}>
													<Input type='select' style={{ fontSize:dimensions.width > 400? '15px' :'12px' }} name='end_time' value={hour.end_time} onChange={(e) => handleChange(e,ind,item,hour)}>
														{optionListEnd}
													</Input>
												</FormGroup>
												<Button className='m-2 mt-0 mb-3' size='sm' onClick={()=>removeTime(item, item.hours, ind, index)} style={{  backgroundColor:'#de0d2d', border: '2px solid #de0d2d', }} ><FaTrash color='white' style={{width:dimensions.width > 400? '20px' :'12px'}}/></Button>
										</div>
										))}
										<div className='d-flex'>
											<Button size='sm' color='lightGreen' onClick={()=> addTime(item,index)}>Slot</Button>
										</div>
									</>
									}
								</Col>	
							</Row>
						))
					}
				</Col>
			</Row>
		)
	}

	const renderMaxBooking = () => {
		return (
			<Row className='mt-4' style={{ textAlign:'left' }}>
				<Col md={3} xs={6}>
					<legend className='mb-4' style={{ fontSize:dimensions.width < 400 ? '16px':'18px', fontWeight:'700' }}>Max booking per slot</legend>
				</Col>
				<Col md={9} xs={6}>
				 	<FormGroup style={{ width:'625px', maxWidth:'100%' }}>
						<Input type='text' name='maxBooking' value={maxBooking} onChange={e => setMaxBooking(e.target.value)} />
					</FormGroup>
				</Col>
			</Row>
		)
	}

	const renderDefaultDuration = () => {
		const optionList = dummyDuration.map((item, index) => {
			return <option key={index} value={item}>{item}</option>
		})

		return (
			<Row className='mt-4' style={{ textAlign:'left' }}>
				<Col md={3}>
					<legend className='mb-4' style={{ fontSize:dimensions.width < 400 ? '16px':'18px', fontWeight:'700' }}>Default duration</legend>
				</Col>
				<Col md={9}>
				 	<FormGroup style={{ width:'625px', maxWidth:'100%' }}>
						<Input className='' type='select' name='duration' value={duration} style={{ fontSize:dimensions.width < 400 ? '12px':'14px' }} onChange={e => setDuration(e.target.value)}>
							<option value=''>---Select Time---</option>
							{optionList}
						</Input>
					</FormGroup>
				</Col>
			</Row>
		)
	}

	const renderRecurring = () => {
		return (
			<>
			{renderAvailability()}
			{renderDefaultDuration()}
			{renderMaxBooking()}
			{renderReschedule()}
			</>
		)
	}

	const renderPrice = () => {
		const optionList = currencyList.map((item, index) => {
			return <option key={index} value={item.code}>{item.code}</option>
		})

		return (
			<>
			
			<Row className='d-flex mt-2' style={{ textAlign:'left' }}>
				<Col md={3} xs={4}>
					<legend className='mb-2' style={{ fontSize:dimensions.width < 400 ? '16px':'18px', fontWeight:'700' }}>Price</legend>
				</Col>
				<Col md={9} xs={8}>
					<div className='d-flex mb-3'>
						<Label check>
							<Input className='ms-1 me-3 mt-1' type='checkbox' checked={paymentChecker} name='paymentChecker' style={{ width:'17px', height:'17px' }} value={paymentChecker} onChange={() => setPaymentChecker(!paymentChecker)} /> 
							<span style={{ fontSize:dimensions.width < 400 ? '13px':'15px' }}>Set pricing</span>
						</Label>
					</div>
					{
						// !!paymentChecker &&
						// <FormGroup style={{ width:'625px', maxWidth:'100%' }}>
						// 	<Input className='' type='number' name='price' value={price} onChange={e => setPrice(e.target.value)} />
						// </FormGroup>
						!!paymentChecker && dimensions.width >= 770 &&
						<Row className='d-flex'>
						<Col xs={3}>
							<FormGroup style={{ width:'625px', maxWidth:'100%' }}>
								<Input className='' type='select' name='currency' value={currency} style={{ fontSize:'14px' }} onChange={e => setCurrency(e.target.value)}>
									<option value='MYR'>MYR</option>
									{optionList}
								</Input>
							</FormGroup>
						</Col>
						<Col xs={9}>
							<FormGroup style={{ width:'625px', maxWidth: '100%'}}>
								<Input className='' type='number' name='price' value={price} onChange={e => setPrice(e.target.value)} />
							</FormGroup>
						</Col>
							{renderBanks()}
						</Row>
					}
				</Col>
			</Row>
				{
				// !!paymentChecker &&
				// <FormGroup style={{ width:'625px', maxWidth:'100%' }}>
				// 	<Input className='' type='number' name='price' value={price} onChange={e => setPrice(e.target.value)} />
				// </FormGroup>
				!!paymentChecker && dimensions.width < 770 &&
					<Row className='d-flex' style={{ textAlign:'left' }}>
						<Col xs={4}>
							<FormGroup style={{  maxWidth:'100%' }}>
								<Input className='' type='select' name='currency' value={currency} style={{ fontSize:'14px' }} onChange={e => setCurrency(e.target.value)}>
									<option value='MYR'>MYR</option>
									{optionList}
								</Input>
							</FormGroup>
						</Col>
						<Col xs={8}>
							<FormGroup style={{  maxWidth: '100%'}}>
								<Input className='' type='number' name='price' value={price} onChange={e => setPrice(e.target.value)} />
							</FormGroup>
						</Col>
						{renderBanks()}
					</Row>
				}
			</>
		)
	}

	const renderReschedule = () => {
		return (
			<Row className='mt-4' style={{ textAlign:'left' }}>
				<Col md={3} xs={6}>
					<legend className='mb-4' style={{ fontSize:dimensions.width < 400 ? '16px':'18px', fontWeight:'700' }}>Allow Reschedule</legend>
				</Col>
				<Col md={9} xs={6}>
				 	<div className='d-flex mb-3'>
					 	<Label check className='d-flex'>
							<Input className='ms-1 me-2' type='radio' style={{ width:'17px', height:'17px' }} name='reschedule' checked={reschedule === 'Yes' ? true : false} value='Yes' onChange={e => setReschedule(e.target.value)} /> 
							<span style={{ fontSize:dimensions.width < 400 ? '13px':'15px' }}>Yes</span>
						</Label>

						<Label check className='d-flex ms-3'>
							<Input className='ms-1 me-2' type='radio' style={{ width:'17px', height:'17px' }} name='reschedule' checked={reschedule === 'No' ? true : false} value='No' onChange={e => setReschedule(e.target.value)} /> 
							<span style={{ fontSize:dimensions.width < 400 ? '13px':'15px' }}>No</span>
						</Label>
					</div>
				</Col>
			</Row>
		)
	}

	const renderEndTime = () => {
		let setTimeList = user.time_format === '12h (am/pm)' ? dummyEndTime : dummyEndTimeHrs

		const optionList = setTimeList.map((item, index) => {
			return <option key={index} value={item}>{item}</option>
		})

		return (
			<Row className='mt-4' style={{ textAlign:'left' }}>
				<Col md={3} xs={6}>
					<legend className='mb-4' style={{ fontSize:dimensions.width < 400 ? '16px':'18px', fontWeight:'700' }}>End Time</legend>
				</Col>
				<Col md={9} xs={6}>
					<FormGroup style={{ width:'625px', maxWidth:'100%' }}>
						<Input className='' type='select' name='endTime' value={endTime} style={{ fontSize:dimensions.width < 400 ? '12px':'14px' }} onChange={e => setEndTime(e.target.value)}>
							<option value=''>---Select Time---</option>
							{optionList}
						</Input>
					</FormGroup>
				</Col>
			</Row>
		)
	}

	const renderStartTime = () => {
		let setTimeList = user.time_format === '12h (am/pm)' ? dummyStartTime : dummyStartTimeHrs

		const optionList = setTimeList.map((item, index) => {
			return <option key={index} value={item}>{item}</option>
		})

		return (
			<Row className='mt-4' style={{ textAlign:'left' }}>
				<Col md={3} xs={6}>
					<legend className='mb-4' style={{ fontSize:dimensions.width < 400 ? '16px':'18px', fontWeight:'700' }}>Start Time</legend>
				</Col>
				<Col md={9} xs={6}>
					<FormGroup style={{ width:'625px', maxWidth:'100%' }}>
						<Input className='' type='select' name='startTime' value={startTime} style={{ fontSize:dimensions.width < 400 ? '12px':'14px' }} onChange={e => setStartTime(e.target.value)}>
							<option value=''>---Select Time---</option>
							{optionList}
						</Input>
					</FormGroup>
				</Col>
			</Row>
		)
	}

	const renderDate = () => {
		return (
			<Row className='mt-2' style={{ textAlign:'left' }}>
				<Col md={3}>
					<legend className='mb-2' style={{ fontSize:dimensions.width < 400 ? '16px':'18px', fontWeight:'700' }}>Date</legend>
				</Col>
				<Col md={9}>
					<>
						<DayPicker
							mode='single'
							selected={date}
							onSelect={setDate}
							// defaultMonth={date} 
							defaultYear={date}
						/>
					</>
				</Col>
			</Row>
		)
	}

	const renderOneTime = () => {
		return (
			<>
			{renderDate()}
			{renderStartTime()}
			{renderEndTime()}
			{renderMaxBooking()}
			{renderReschedule()}
			{/* {renderPrice()} */}
			</>
		)
	}

	const renderSchedule = () => {
		return (
			<Row style={{ textAlign:'left' }}>
				<Col md={3}>
					<legend className='mb-4' style={{ fontSize:dimensions.width < 400 ? '16px':'18px', fontWeight:'700' }}>One time/Recurring</legend>
				</Col>
				<Col md={9}>
				 	<div className='d-flex mb-3'>
						<Card className={dimensions.width > 800 ? 'me-4' : 'me-2' } style={{ width:dimensions.width > 800 ? '45%' : '100%' }}>
							<CardHeader>
								<Label check>
									<Input className='ms-1 me-3' type='radio' name='recurring' checked={recurring === 'One time' ? true : false} value='One time' onChange={e => setRecurring(e.target.value)} /> 
									<span style={{ fontWeight:'700' }}>One time</span>
								</Label>
							</CardHeader>
							<CardBody>An event that has either specific time, date or both.</CardBody>
						</Card>

						<Card className={dimensions.width > 800 ? 'me-5' : '' } style={{ width:dimensions.width > 800 ? '45%' : '100%' }}>
							<CardHeader>
								<Label check>
									<Input className='ms-1 me-3' type='radio' name='recurring' checked={recurring === 'Recurring' ? true : false} value='Recurring' onChange={e => setRecurring(e.target.value)} /> 
									<span style={{ fontWeight:'700' }}>Customized Schedule</span>
								</Label>
							</CardHeader>
							<CardBody>A customisable event that has either multiple available time or unavailable at certain period.</CardBody>
						</Card>
					</div>
				</Col>
			</Row>
		)
	}

	const renderLocation = () => {
		return (
			<>
			<Row className='mt-2' style={{ textAlign:'left' }}>
				<Col md={3} xs={4}>
					<legend className='mb-2' style={{ fontSize:dimensions.width < 400 ? '16px':'18px', fontWeight:'700' }}>Location</legend>
				</Col>
				<Col md={9} xs={8}>
					<div className='d-flex mb-3'>
						<Label check>
							<Input className='ms-1 me-3' type='checkbox' checked={locationChecker} name='locationChecker' style={{ width:'17px', height:'17px' }} value={locationChecker} onChange={() => setLocationChecker(!locationChecker)} /> 
							<span style={{ fontSize:dimensions.width < 400 ? '14px':'15px' }}>Set location</span>
						</Label>
					</div>
					{
						!!locationChecker && dimensions.width >= 770 && <>
						
						<FormGroup style={{ width:'625px', maxWidth:'100%' }}>
							<Label check>
								<Input className='ms-1 me-3' type='radio' style={{ width:'17px', height:'17px' }} name='location' checked={locationOption === 'Seller' ? true : false} value='Seller' onChange={e => setLocationOption(e.target.value)} /> 
								<span style={{ fontSize:dimensions.width < 400 ? '13px':'15px' }}>Seller</span>
							</Label>

							<Label check className='ms-5'>
								<Input className='ms-1 me-3' type='radio' style={{ width:'17px', height:'17px' }} name='location' checked={locationOption === 'Buyer' ? true : false} value='Buyer' onChange={e => setLocationOption(e.target.value)} /> 
								<span style={{ fontSize:dimensions.width < 400 ? '13px':'15px' }}>Buyer</span>
							</Label>
						</FormGroup>
						{
							locationOption === 'Seller' && <Location sendLocation={sendLocation} address={address} lat={lat} lng={lng} />
						}
						</>
					}		
				</Col>
			</Row>
			{
				!!locationChecker && dimensions.width < 770 &&
				<>
				<Row>
					<Col xs={12} className='d-flex justify-content-start'>
						<FormGroup >
							<Label check>
								<Input className='ms-1 me-3' type='radio' style={{ width:'17px', height:'17px' }} name='location' checked={locationOption === 'Seller' ? true : false} value='Seller' onChange={e => setLocationOption(e.target.value)} /> 
								<span style={{ fontSize:dimensions.width < 400 ? '13px':'15px' }}>Seller</span>
							</Label>

							<Label check className='ms-5'>
								<Input className='ms-1 me-3' type='radio' style={{ width:'17px', height:'17px' }} name='location' checked={locationOption === 'Buyer' ? true : false} value='Buyer' onChange={e => setLocationOption(e.target.value)} /> 
								<span style={{ fontSize:dimensions.width < 400 ? '13px':'15px' }}>Buyer</span>
							</Label>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col xs={12} >
						{
							locationOption === 'Seller' && <Location sendLocation={sendLocation} address={address} lat={lat} lng={lng} />
						}
					</Col>
				</Row>
				</>
			}
			</>
		)
	}

	const renderDealsDynamicInput = () => {
		const optionList = stuffs.map((item, index) => {
			if (item._id !== props.id) {
				return <option key={index} value={item.title}>{item.title}</option>
			}
		})

		return deals !== undefined && deals.map((data, index) => {
			return (
				<Row style={{ textAlign:'left' }} key={index}>
					<Col md={3}></Col>
					<Col md={9}>
						<div style={{ fontSize:'16px', width:'625px', maxWidth:'100%' }}>
							<div className='d-flex'>
								<Input type='select' placeholder='Select deal' className='m-2 ms-0' style={{ width:'450px', fontSize:'14px' }} value={deals[index] ? deals[index].name : ''} onChange={e => handleDealChange(e.target.value, index, 'name')} >
									<option value=''>---Select Deal---</option>
									{optionList}
								</Input>
								<Input type='number' className='m-2' style={{ width:'150px', fontSize:'14px' }} value={deals[index] ? deals[index].price : ''} onChange={e => handleDealChange(e.target.value, index, 'price')} />
								<Button size='sm' className='m-3 ms-2 me-2 p-2 pt-1 pb-1' color='danger' disabled={deals.length === 1 ? true : false } onClick={() => removeDeal(data.id, index)}><FaTimes fontSize='1em' /></Button>
							</div>
						</div>
					</Col>
				</Row>
			)
		})
	}

	const renderDealsHeader = () => {
		return (
			<Row style={{ textAlign:'left' }}>
				<Col md={3}></Col>
				<Col md={9}>
					<div style={{ fontSize:'14px', width:'625px', maxWidth:'100%' }}>
						<div className='d-flex'>
							<Label className='m-2' style={{ width:'425px', maxWidth:dimensions.width > 830 ? '60' : '70%', fontWeight:'700' }} >Deal</Label>
							<Label className='m-2 ms-3' style={{ width:'150px', fontWeight:'700' }} >Price</Label>
						</div>
					</div>
				</Col>
			</Row>
		)
	}

	const renderDeals = () => {
		return (
			<>
			{renderDealsHeader()}
			{renderDealsDynamicInput()}

			<Row style={{ textAlign:'left' }}>
				<Col md={3}></Col>
				<Col md={9}>
					<div className='m-2 ms-0 mt-2'>
						<button className='pt-2 pb-2 ps-3 pe-3 m-1 continue-btn' style={{ fontSize:'12px' }} onClick={() => addDeal()}>Add Deal</button>
					</div>
				</Col>
			</Row>
			</>
		)
	}

	const renderBanks = () => {
		return(
			<>	
				<div>
					{dimensions.width >=830 ? <h4>Bank Account Details</h4> : <h5>Bank Account Details</h5>}	
				</div>
				{banks && banks.map((bank, ind) => {
					return (
						<Row className="mt-2 mb-2">
							<Col> 
								<div >
									<b>Bank Name : </b><span >{bank.bank}</span>
								</div>
								<div >
									<b>Account Holder : </b><span >{bank.accName}</span>
								</div>
								<div >
									<b>Account Number : </b><span >{bank.accNo}</span>
								</div>
							</Col>
						</Row>	
					)
				})}
			</>
		)
	}
	
	const renderDescription = () => {
		return (
			<Row className='mt-2' style={{ textAlign:'left' }}>
				<Col md={3}>
					<legend className='mb-2' style={{ fontSize:dimensions.width < 400 ? '16px':'18px', fontWeight:'700' }}>Description</legend>
				</Col>
				<Col md={9}>
				 	<FormGroup style={{ width:'625px', maxWidth:'100%' }}>
						<Input className='' type='textarea' name='description' value={description} style={{ height:'200px' }} onChange={e => setDescription(e.target.value)} />
					</FormGroup>
				</Col>
			</Row>
		)
	}

	const renderPhoto = () => {
		return (
			<Row className='mt-4' style={{ textAlign:'left' }}>
				<Col md={3}>
					<legend className='mb-4' style={{ fontSize:dimensions.width < 400 ? '16px':'18px', fontWeight:'700' }}>Photo</legend>
				</Col>
				<Col md={9}>
					<FormGroup style={{ width:'625px', maxWidth:'100%' }}>
						<div className='d-flex'>
							<Input name='file' type='file' style={{ fontSize:'15px', fontWeight: 'bold' }} onChange={e => handleUpload(e)} />
							<Button className='ms-2' style={{ fontWeight: 'bold', backgroundColor:'#cdf5a0', color:'black', border:'2px solid black'}} size='sm' onClick={() => {handleDeleteImage()}}>Clear</Button>
						</div>

						<FormText className='mt-3'>
							**File size has to be less than 100KB
						</FormText>
						{photo !== '' && photo !== null && <img src={`${util.expURL}/${photo.file_path}`} height="200" className="card-img-top img-responsive" alt="img"/>}
					</FormGroup>
				</Col>
			</Row>
		)
	}

	const renderTitle = () => {
		return (
			<Row className='mt-2' style={{ textAlign:'left' }}>
				<Col md={3}>
					<legend className='mb-2' style={{ fontSize:dimensions.width < 400 ? '16px':'18px', fontWeight:'700' }}>Title</legend>
				</Col>
				<Col md={9}>
				 	<FormGroup style={{ width:'625px', maxWidth:'100%' }}>
						<Input className='' type='text' name='title' value={title} onChange={e => setTitle(e.target.value)} />
					</FormGroup>
				</Col>
			</Row>
		)
	}

	const renderCategory = () => {
		return (
			<Row style={{ textAlign:'left' }}>
				<Col md={3}>
					<legend className='mb-4' style={{ fontSize:dimensions.width < 400 ? '16px':'18px', fontWeight:'700' }}>Category</legend>
				</Col>
				<Col md={9}>
				 	<div className='d-flex mb-3'>
						<Card className={dimensions.width > 800 ? 'me-4' : 'me-2' } style={{ width:dimensions.width > 800 ? '45%' : '100%', backgroundColor:serviceCount > 4 ? 'rgb(184 180 180 / 25%)': false, }} >
							{serviceCount > 4 ? 
							null
							:
							<CardHeader>
								<Label check>
									<Input className='ms-1 me-3' type='radio' name='category' checked={category === 'Service' ? true : false} disabled={serviceCount> 4 ? true:false} value='Service' onChange={e => {setCategory(e.target.value)}} /> 
									<span style={{ fontWeight:'700' }}>Service</span>
								</Label>
							</CardHeader>
							}
							
							{serviceCount > 4 ?
							<CardBody>
							<div className='mb-1'>
								<FaExclamationTriangle color='black' style={{width:dimensions.width > 400? '55px' :'40px', height:dimensions.width > 400 ? '55px': '40px', position: 'relative', left:dimensions.width > 850 ? '100px': '40px' }}/>
								<h6 style={{ marginTop:20, textAlign:'center'}}>Please subscribe to add more Service</h6>
							</div>
							</CardBody>
							:
							<CardBody>
								<div className='mb-1'>Work done or services provided based on time and energy.</div>
								<div>Example: Meetings, Rentals, Location Space, Skilled Services, Consultation</div>
							</CardBody>
							}
							
						</Card>
						{/* {productCount > 4 ? info('You have reached the limit for the category Product. \n\nPlease subscribe to add more Product categories') : null} */}
						<Card className={dimensions.width > 800 ? 'me-5' : '' } style={{ width:dimensions.width > 800 ? '45%' : '100%', backgroundColor:productCount > 4 ? 'rgb(184 180 180 / 25%)': false }}>
							{productCount > 4 ? null :
							<CardHeader>
							<Label check>
								<Input className='ms-1 me-3' type='radio' name='category' checked={category === 'Product' ? true : false} disabled={productCount > 4 ? true:false} value='Product' onChange={e => {setCategory(e.target.value)}} /> 
								<span style={{ fontWeight:'700' }}>Product</span>
							</Label>
							</CardHeader>
							}
							{productCount > 4 ?
							<CardBody>
							<div className='mb-1'>
								<FaExclamationTriangle color='black' style={{width:dimensions.width > 400? '55px' :'40px', height:dimensions.width > 400 ? '55px': '40px', position: 'relative', left:dimensions.width > 850 ? '100px': '40px' }}/>
								<h6 style={{ marginTop:20, textAlign:'center'}}>Please subscribe to add more Product</h6>
							</div>
							</CardBody>
							:
							<CardBody>
								<div className='mb-1'>Item or goods offered for sale.</div>
								<div>Example: Food, Devices, Clothing, Cosmetics</div>
							</CardBody>
							}
					
						</Card>
					</div>
				</Col>
			</Row>
		)
	}

	const renderBackBtn = (current) => {
		return (
			<Button className={dimensions.width < 300 ? 'pt-1 pb-1 ps-2 pe-2 m-1 previous-btn' : 'pt-2 pb-2 ps-3 pe-3 m-1 previous-btn'} color='lightGreen' style={{fontSize: dimensions.width < 300 ? '11px': dimensions.width < 400 ? '12px':  ''}} onClick={() => handleCurrentSection(current)}>
				Previous
			</Button>	
		)
	}

	const renderContinueBtn = (current) => {
		return <Button className={ dimensions.width < 300 ? 'pb-1 ps-2 pe-2 m-1 continue-btn' : 'pb-2 ps-3 pe-3 m-1 continue-btn'} color='darkGreen' style={{fontSize: dimensions.width < 300 ? '11px': dimensions.width < 400 ? '12px': ''}} onClick={() => handleCurrentSection(current)}>Continue</Button>
	} 

	const btnName = props.id ? 'Update Product & Services' : 'Add Product & Services'

	const section3 = () => {
		return (
			<>
				<div>{renderLocation()}</div>

				<Row className={dimensions.width > 800 ? 'me-4 mt-4' : 'mt-4'} style={{ textAlign:'left' }}>
					<Col md={3}>
					</Col>
					<Col md={9}  xs={12}className='d-flex justify-content-between'>
						{renderBackBtn(2)}
						{createBtn ? <Button disabled color='darkGreen' style={{ fontSize: dimensions.width < 400 ? '12px': '',  color:'black', fontWeight:600 }} className='ml-auto'> 
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="mr-2">{btnName === 'Update Product & Services'? 'Updating' : 'Adding'}... &nbsp; </div>
                            <div className="buttonloader"></div>
                        </div>
						</Button> : 
						<Button className={dimensions.width < 300 ? 'pt-1 pb-1 ps-2 pe-2 m-1 continue-btn' : 'pt-2 pb-2 ps-3 pe-3 m-1 continue-btn'} color='darkGreen' style={{fontSize: dimensions.width < 300 ? '11px': dimensions.width < 400 ? '12px':  ''}} onClick={() => saveStuff()}>{btnName}</Button>}
					</Col>
				</Row>
			</>
		)
	}

	const section2 = () => {
		return (
			<>
				<Row style={{ textAlign:'left' }}>
					<Col md={3}></Col>
					<Col md={9}>
						<div className='d-flex mb-3'>
							<Label check>
								<Input className='ms-1 me-3' type='checkbox' checked={dateTimeChecker} name='dateTimeChecker' style={{ width:'17px', height:'17px' }} value={dateTimeChecker} onChange={() => setDateTimeChecker(!dateTimeChecker)} /> 
								<span style={{ fontSize:dimensions.width < 400 ? '14px':'15px' }}>Set date and time</span>
							</Label>
						</div>
						<div>
							{!dateTimeChecker?
							<span>
								*** Setting up the date and time indicates that the product is not available at all time.
							</span>: null}
						</div>
					</Col>
				</Row>
				{
					!!dateTimeChecker &&
					<>
						{renderSchedule()}
		
						{recurring ? recurring === 'One time' ? renderOneTime() : renderRecurring() : null}
					</>
				}

				<Row className={dimensions.width > 800 ? 'me-4 mt-4' : 'mt-4'} style={{ textAlign:'left' }}>
					<Col md={3}>
					</Col>
					<Col md={9} className='d-flex justify-content-between'>
						{renderBackBtn(1)}
						{renderContinueBtn(3)}
					</Col>
				</Row>
			</>
		)
	}

	const section1 = () => {
		return (
			<>
			{renderCategory()}
			
			{renderTitle()}

			{renderPhoto()}

			{renderDescription()}

			{renderPrice()}

			{stuffs !== null && stuffs.length !== 0 ?
				<>
				{/* {renderDeal()} */}

				{deal && renderDeals()}
				</>
				: null
			}

			
			<Row className={dimensions.width > 800 ? 'me-4 mt-4' : 'mt-4'} style={{ textAlign:'left' }}>
				<Col md={3}>
				</Col>
				<Col md={9} className='d-flex justify-content-end'>
					{renderContinueBtn(2)}
		
				</Col>
			</Row>
			</>
		)
	}
	
	let display
	switch (currentSection) {
		case 1: display = section1()
			break
		case 2: display = section2()
			break
		case 3: display = section3()
			break
		default:
			break
	}

	const renderTop = () => {
		const sectionList = ['Section 1', 'Section 2 ', 'Section 3']

		return (
			<>
			<div className='d-flex justify-content-start'>
				{dimensions.width >= 830 ?
					<button className='d-flex pt-2 pb-2 ps-4 pe-4 mt-2 back-btn' onClick={() => _back()}>
						<FaLongArrowAltLeft style={{marginTop:'3px'}}/>
						<span className='ms-2'>Back</span>
					</button>	
					:
					<button className='pt-1 pb-1 ps-3 pe-3 mt-4' style={{ backgroundColor:'transparent', color:'black', border:'2px solid #000', borderRadius:'2rem', width:'60px', maxWidth:'100%', height:'30px' }} onClick={() => _back()}>
						<FaLongArrowAltLeft />
					</button>
				}
			</div>
			<div className={dimensions.width > 800 ? 'p-1' : ''} style={{ width:'1000px', maxWidth:'100%' }} >
				{
					linkDisplay ? null : 
					dimensions.width > 830 ? <ProgressUnresponsive  current={currentSection} list={sectionList} /> : <ProgressResponsive current={currentSection} list={sectionList} />
				}
			</div>
			</>
		)
	}
	
	// top left - top right - bottom right - top left
	return (
		<div className='pb-5 mb-3' style={{marginTop:'10rem'}}>
			{renderTop()}
			
			<div className={dimensions.width > 800 ? 'p-5 pt-3' : 'p-3 mb-2'} style={{ width:'1000px', maxWidth:'100%' }}>
				{!linkDisplay ? display : renderCreationLink() }
			</div>
		</div>
  )
})

