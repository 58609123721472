import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { Input, Row, Col, Button, InputGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FaTrash } from 'react-icons/fa'
import { FaCopy } from 'react-icons/fa'
import { FaEllipsisV } from 'react-icons/fa'

import { useStuffsContext } from '../hooks/useStuffContext'
import { useAuthContext } from '../hooks/useAuthContext'
import { useDimensionContext } from '../hooks/useDimensionContext'
import { useUserContext } from '../hooks/useUserContext'
import { useWorkspaceContext } from '../hooks/useWorkspaceContext'

import { util } from '../utils/util'

const StuffDetails = ({ stuff }) => {
	const { auth } = useAuthContext()
	const { dispatch } = useStuffsContext()
	const { dimensions } = useDimensionContext()
	const { user } = useUserContext()
  	const { workspace } = useWorkspaceContext()

	const [dropdown, setDropdown] = useState(false)
	const [ copied, setCopied ] = useState(false)
	const [ dateFormat, setDateFormat ] = useState(user.date_format)
	// const [ copyValue, setCopyValue ] = useState('')

	const deleteStuff = async () => {
		if (!auth) return

		let r = window.confirm('Are you sure you want to delete this Product & Services?\nClick on "OK" to confirm.')
		if (!r) return

		const passStuff = {
			workspace_id: user.current_workspace,
			stuffs: workspace.stuffs,
			workspace,
			stuff_id: stuff.stuff_id,
			availability_id: stuff.availability_id
		}

		const response = await fetch(`${util.expURL}/api/stuff/${stuff._id}`, {
			method: 'POST',
			body: JSON.stringify(passStuff),
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${auth.token}`
			}
		})

		const json = await response.json()
		
		if (response.ok) {
			dispatch({ type:'DELETE_STUFF', payload:json })
			alert('Stuff has been successfully deleted')
		}
	}

	const formatDate = (date) => {
		let d = new Date(date)
		let formatedDate = ''
		let day = d.getDate()
		let month = d.getMonth() + 1
		let year = d.getFullYear()

		if (dateFormat.slice(0,2) === 'DD'){
			formatedDate = day + '/' + month + '/' + year 
		}else formatedDate = month + '/' + day + '/' + year 
		
		return formatedDate
	}

	return (
		<Row className='stuff-details'>
			<Col xs={8}>
				<h4>{stuff.title}</h4>
				<p><strong>Description : </strong>{stuff.description}</p>
				{
					stuff.price && <p><strong>Price : </strong>{stuff.code !== '' ? stuff.code : 'RM'}{stuff.price}</p>
				}
				<p className='mt-1 mb-0' >Tags: {stuff.category}</p>
				<InputGroup style={{ width:'425px', maxWidth:'100%' }}>
					{copied ? <Input style={{ fontSize:'12px' }} className='mt-2 mb-2 font-weight-bold form-control-sm' disabled value={stuff.link} /> : <Input style={{ fontSize:'12px' }} className=' mt-2 mb-2 font-weight-bold form-control-sm' disabled value={stuff.link} />}
					<CopyToClipboard text={stuff.link} 
						onCopy={async () => {
							// await setCopyValue(stuff.link)
							await setCopied(true)
							await setTimeout(() => setCopied(false), 3000)
						}}>
						<Button className='ms-2 mt-2 mb-2' size='sm' style={{ backgroundColor:'#cdf5a0', color:'black', border:'2px solid black' }} ><FaCopy style={{ fontWeight:'600' }} /></Button>
					</CopyToClipboard>
				</InputGroup>
				<p >Date Created: {formatDate(stuff.createdAt)}</p>
				{/* <p className='mt-2'>{formatDistanceToNow(new Date(stuff.createdAt), { addSuffix:true })}</p> */}
			</Col>
			<Col xs={4} >
				<div >
					<div className= 'd-flex justify-content-end'>
						<Dropdown isOpen={dropdown} toggle={() => {setDropdown(prevState => !prevState)}}>
							<DropdownToggle style={{backgroundColor:'transparent', border:0, padding:0}} >
								<FaEllipsisV color='black'/>
							</DropdownToggle>
							<DropdownMenu>
								<DropdownItem onClick={() => deleteStuff()} style={{fontSize:'12px'}}>
								<FaTrash className='me-1' color='black' />Delete
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</div>
					<div className= 'd-flex justify-content-end mt-5' style={{ alignSelf: 'end' }} ><Link className='link-button' style={{ fontSize:'13px', backgroundColor:'#cdf5a0', color:'black', borderRadius:'2rem', border: '2px solid #000', fontWeight:600 }} to={`/product-services/${stuff._id}`}>View</Link></div>
				</div>
				
				{/* <div className='mt-3 ms-2'><Button className='link-button' style={{color:'#fff',  fontSize:'13px', backgroundColor:'#de0d2d',borderRadius:'2rem', border: '2px solid #de0d2d', fontWeight:600 }} onClick={() => deleteStuff()}><FaTrash className='me-2' color='white' />Delete</Button></div> */}
			</Col>
		</Row>
	)
}

export default StuffDetails