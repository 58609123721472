/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react'
import { Nav } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { withRouter } from '../utils/withRouter'
import { useDimensionContext } from '../hooks/useDimensionContext'
import { useStuffsContext } from '../hooks/useStuffContext'

import dashboard from '../assets/img/dashboard.png'
import analytics from '../assets/img/analytics.png'
import team from '../assets/img/team.png'
import productServices from '../assets/img/productServices.png'
import dashboardactive from '../assets/img/dashboard-active.png'
import analyticsactive from '../assets/img/analytics-active.png'
import teamactive from '../assets/img/team-active.png'
import productServicesactive from '../assets/img/productServices-active.png'
import plus from '../assets/img/plus.png'

function Topbar({ props, routes}) {
	const { dimensions } = useDimensionContext();
	const { stuffs, dispatch } = useStuffsContext();

	const [ productCount, setProductCount ] = useState(0)
	const [ serviceCount, setServiceCount ] = useState(0)

	useEffect(() => {

		const countStuff = () => {
			let count = 0, count1 = 0

			stuffs.map(stuff => {
				if (stuff.category === 'Product'){
					count = count + 1
				}
				if (stuff.category === 'Service'){
					count1 = count1 + 1
				}
			})

			setProductCount(count)
			setServiceCount(count1)
		}

		if (stuffs) {
			countStuff()
		}
	}, [stuffs])

	
	const createLinks = routes => {
		return routes.map((prop, key) => {

			if(!prop.redirect && prop.display && prop.navbar) {
				let name, activetab
				let r = activeRoute(prop.path)
		
				if (r === '/dashboard') activetab = dashboardactive
				if (r === '/analytics') activetab = analyticsactive
				if (r === '/team') activetab = teamactive
				if (r === '/product-services') activetab = productServicesactive

				if (prop.name === 'Dashboard') name = dashboard 
				if (prop.name === 'Analytics') name =  analytics
				if (prop.name === 'Team') name = team
				if (prop.name === 'Product & Services') name = productServices
				return (
					<li key={key}>
						<NavLink to={prop.path} className={`nav-link`}>
							{dimensions.width >= 830? 
								<p style={{ fontWeight:'500', fontSize: '15px'}} >{prop.name}</p>
								:<div >
									<div style={{ textAlign:'center' }}>
										<img src={activetab? activetab : name} alt='yo' style={{ width:'20px',  }}/>
									</div>
									{/* <p style={{ fontWeight:'500', fontSize:dimensions.width >= 415?'12px':'10px'}} >{prop.name}</p> */}
								</div>
							}
						</NavLink>
					</li>
				)
			}

			return null
		})
	}

	// verifies if routeName is the one active (in browser input)
	const activeRoute = (routeName) => {
		return props.location.pathname.indexOf(routeName) > -1 ? routeName : ''
	}
	

	let route = routes.filter(x => x.path === props.location.pathname)
	// let name = route[0].name
	let obj_route = route.find(item => item.name)
	// if(!this.props.routes[routeIndex].navbar) return null

	//flaoting button 
	const navigate = () => {
		if (productCount > 4 && serviceCount > 4) {
			alert('You have reached the limit for adding items. \n\nPlease subscribe to add more items.')
			props.navigate('/subscription')
		} else {
			props.navigate('/product-services/new')
		}
	}

	return (
		
		<div className='d-flex justify-content-center'>
			{obj_route !== undefined && obj_route.name !== 'Account Settings' && obj_route.name !== 'Subscription' ?
			<div className='px-2 navigation' >
				{dimensions.width <= 830 && obj_route.name !== 'Add Product & Services'  && 
				<div className='floating-button' onClick={navigate} >
					<img src={plus} alt='add' className='floating-button-add'/>
				</div>}
				<Nav className='pt-1 pb-0'>
					{createLinks(routes)}
				</Nav>
			</div> :null}
		</div>
		
	)

}

export default withRouter(Topbar)