import React, { useEffect } from 'react'

import { Col, Row } from 'reactstrap'

export const ProgressUnresponsive = (props) => {
  let progress, stepCircles, currentActive = 1

	useEffect(() => {
    progress = document.getElementById('progress');
    stepCircles = document.querySelectorAll('.circle');
    currentActive = 1;

    update(props.current);
	}) 

  const update = (currentActive) => {
    stepCircles.forEach((circle, i) => {
      if (i < currentActive) {
        circle.classList.add('active');
      } else {
        circle.classList.remove('active');
      }
    });
  
    const activeCircles = document.querySelectorAll('.active');
    progress.style.width =
      ((activeCircles.length - 2) / (stepCircles.length - 1)) * 100 + '%';
  }

  return (
    <Row className='mt-2' style={{ textAlign:'left' }}>
      <Col md={3}>
      </Col>
      <Col md={9}>
      <div className='container'>
        <div className='progress-container'>
          <div className='progress' id='progress'> </div>
            {props.list.map((list, index) => {
              return  <div key={index} className='circle'>{list}</div>
            })}
        </div>
      </div>
      </Col>
    </Row>
  )
}