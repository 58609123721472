/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react'

import { Col, FormGroup, Input, Row, Table, Button, Modal, ModalBody, ModalHeader, ModalFooter, Label} from 'reactstrap'
import { FaLongArrowAltLeft } from 'react-icons/fa'
import { v4 as uuidv4 } from 'uuid'
import { useAuthContext } from '../hooks/useAuthContext'
import { useUserContext } from '../hooks/useUserContext'
import { useWorkspaceContext } from '../hooks/useWorkspaceContext'
import { useDimensionContext } from '../hooks/useDimensionContext'

import { DashboardCalendar } from '../components/DashboardCalendar'
import logo from '../assets/img/LOGO.png'
import FormInput from '../components/FormInput'
import { util } from '../utils/util'
import PageHeader from '../components/PageHeader'
import PrintInvoice from '../components/PrintInvoice'

export const Dashboard = (props) => {
	const { user, dispatch } = useUserContext()
	const { workspace:workspaceContext, dispatch:dispatchWorkspace } = useWorkspaceContext()
	const { auth } = useAuthContext()
	const { dimensions } = useDimensionContext()

	const [ events, setEvents ] = useState([])

	//Add new workspace 
	const [timeZone, setTimeZone] = useState('')
	const [timeZones, setTimeZones] = useState([])
	const [dateFormat, setDateFormat] = useState('')
	const [timeFormat, setTimeFormat] = useState('')
	const [weekStart, setWeekStart] = useState(false)
	const [weekDisplay, setWeekDisplay] = useState(false)
	const [newworkspace, setNewWorkspace] = useState(false)
	const [curr_subscription, setCurr_subscription] = useState('')
	const [dataWorkspace, setDataWorkspace] = useState([]) 
	const [workspaceIDs, setWorkspaceIDs] = useState([])
	const [workspaces, setWorkspaces] = useState([])
	const [teamName, setTeamName] = useState('')
	// const [dataSubscription, setDataSubscription] = useState('')
	const [error, setError] = useState(null)

	const [ workspace, setWorkspace ] = useState('')
	const [ workspaceList, setWorkspaceList ] = useState([])
 	const [ toggleModal, setToggleModal ] = useState(false)
	const [ toggleModalOrderForm, setToggleModalOrderForm ] = useState(false)
	const [ toggleModalWorkspace, setToggleModalWorkspace ] = useState(false)
	const [ dataOrder, setDataOrder ] = useState('')
	const [ load, setLoad ] = useState('1')

	useEffect(() => {
		const fetchWorkspaceList = async() => {
			const response = await fetch(`${util.expURL}/api/workspaces/`, {
				headers: {'Authorization': `Bearer ${auth.token}`}
			})

			const json = await response.json()
			if (response.ok && user) {
				let store = [], workSPACE = []
				json.map(data => {
				
					let temp = {
						id: data.workspace_id,
						name: data.name,
						type: data.type,
					}
					setCurr_subscription(user.current_subscription)
					// setCurr_subscription(data.current_subscription)
					if ((data.workspace_id === user.current_workspace) && workspace === '') {
						setWorkspace(data.name)
					}
					 
					return store.push(temp) && workSPACE.push(data.workspace_id)
				})
				setDataWorkspace(workSPACE)
				setWorkspaceList(store)
				setLoad('1')
			}
		}

		if (auth && user) {
			fetchWorkspaceList()
		}
	}, [dispatch, auth, user]) 

	useEffect(() => {
    	const fetchBookings = async () => {
		const stuffs = {
			stuffs: workspaceContext.stuffs
		}

		const response = await fetch(`${util.expURL}/api/event-invitees/`, {
			method: 'POST',
			body: JSON.stringify(stuffs),
			mode: 'cors',
			headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${auth.token}`
			}
		})

			const json = await response.json()

		if (response.ok) {
			let store = []

			json && json.map(booking => {
				let current = {}

				const splitDate = booking.date.split('/') 

				let tempDate
				if(booking.date.includes('-')){
					tempDate = booking.date
				} else {
					tempDate = user.date_format === 'MM/DD/YYYY' ? booking.date : `${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`
				}

				let finalTime, finalEnd
				if(booking.time !== "" || booking.start_time !== ""){
					let TIME = booking.time !== "" ? booking.time : booking.start_time
					let HOURo
					let HOUR = TIME.split(':')[0]
		
					let AMPM = TIME.slice(-2)
					// // console.warn('hmmm', AMPM);
					if(AMPM === 'PM'){
						let x = HOUR
						let y = '12'

						HOURo = parseFloat(x) + parseFloat(y)
					}  else {
						HOURo = HOUR
					}
					let FINAL = HOURo
					let FINALEND = parseFloat(HOURo) + 1

					let YEAR = parseFloat(splitDate[2])
					let MONTH =  user.date_format === "DD/MM/YYYY" ? parseFloat(splitDate[1]) - 1 : parseFloat(splitDate[0]) - 1
					let DAY =  user.date_format === "DD/MM/YYYY" ? parseFloat(splitDate[0]) : parseFloat(splitDate[1])

					finalTime = new Date(YEAR, MONTH, DAY, FINAL, 0, 0 )
					finalEnd = new Date(YEAR, MONTH, DAY, FINALEND, 0, 0)

					//2022 18 10
				} else {
					// 2022-10-19T15:32:28.960Z
					if(tempDate.slice(-1) === 'Z'){
						let DATE =  tempDate.split('T')[0]
						let YEAR = DATE.split('-')[0]
						let MONTH = parseFloat(DATE.split('-')[1]) - 1
						let DAY = DATE.split('-')[2]

						finalTime = new Date(YEAR, MONTH, DAY, 0, 0, 0 )
						finalEnd = new Date(YEAR, MONTH, DAY, 0, 0, 0 )

					} else { 
						finalTime = tempDate
						finalEnd = tempDate
					}
				}

				// current.start = new Date(2022, 9, 19, 2, 0, 0 )
				current.start = new Date(finalTime)
				current.end = new Date(finalEnd)
				current.title = booking.title + ' - ' + booking.name
				current.booking_id = booking.booking_id
				current.name = booking.name
				current.price = booking.price
				current.currency = booking.currency
				current.code = booking.code
				current.start_time = booking.start_time
				current.end_time = booking.end_time
				current.date = new Date(tempDate)
				current.time = booking.time
				current.category =booking.category !== undefined ? booking.category : 'NA'
				current.email = booking.email !== undefined ? booking.email : 'NA'
				current.phone = booking.phone !== undefined ? booking.phone : 'NA'
				current.address = booking.address
				current.notes = booking.notes !== undefined ? booking.notes : ""

				if (current.start !== 'Invalid Date') {
					store.push(current)
				}
			})

			setEvents(store)	
		}
    }

    if (workspaceContext) {
      fetchBookings()
    }
  }, [workspaceContext])


  //fetch data from time-zones
  useEffect(() => {
	const fetchTimeZones = async() => {
		const response = await fetch(`${util.expURL}/api/time-zones/`, {
			headers: {'Authorization': `Bearer ${auth.token}`}
		})

		const json = await response.json()

		if (response.ok) {
			setTimeZones(json)
		}
	}

	if (auth) {
		fetchTimeZones()
	}
	}, [dispatch, auth])


	//function handle workspace data
	const handleWorkspaceData = (type, name) => {
	
	const uuid = uuidv4()
	let workspace_id = 'workspace_' + uuid.slice(uuid.length - 10)

	let members = []
	let newMember = {
		id: auth.id,
		role: 'Admin'
	}
	members.push(newMember)
	
	const newWorkspace = {
		workspace_id,
		current_subscription:'Teams',
		type,
		name,
		members,
		stuffs: [],
		logo: '',
		product_count: 0,
		service_count: 0
	}
	dataWorkspace.push(workspace_id)
	workspaceIDs.push(workspace_id)
	workspaces.push(newWorkspace)
	}


	const saveUser = async () => {

		if (newworkspace === 'Team' || newworkspace === 'Personal') {
			handleWorkspaceData(newworkspace, teamName)
		}	
		// else {
		// 	handleWorkspaceData('Personal', '')
		// }	
		
		const user = {
			current_workspace : workspaceIDs[0],
			workspaces: dataWorkspace,
		}


		const response = await fetch(`${util.expURL}/api/user/${auth.id}`, {
			method: 'POST',
			body: JSON.stringify(user),
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${auth.token}`
			}
		})

		workspaces.map(async workspace => {
			await fetch(`${util.expURL}/api/create-workspace`, {
				method: 'POST',
				body: JSON.stringify(workspace),
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${auth.token}`
				}
			})
		})

		const json = await response.json()

		if (!response.ok) {
			setError(json.error)
		}

		if (response.ok) {
			setTimeZone('')
			setDateFormat('')
			setTimeFormat('')
			setWeekStart('')
			setWeekDisplay('')
			setNewWorkspace('')
			setWorkspaceIDs([])
			setWorkspaces([])
			setTeamName('')
			setError(null)
			dispatch({ type:'CREATE_USER', payload:json })
			alert('Workspace has been successfully added')
			setToggleModalWorkspace(!toggleModalWorkspace)
		}
 	}

	const handleWorkspace = (e) => {
		const index = e.target.selectedIndex;
		const el = e.target.childNodes[index]
		const option =  el.getAttribute('id');
	
		workspaceList && workspaceList.map(async item => {
			if (item.id === option) {
				const USER = {
					...user, 
					current_workspace:option
				}

				const response = await fetch(`${util.expURL}/api/user/${auth.id}`, {
					method: 'POST',
					body: JSON.stringify(USER),
					mode: 'cors',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${auth.token}`
					}
				})

				if (response.ok) {
					dispatchWorkspace({ type:'SET_WORKSPACE', payload:item })
					setWorkspace(item.name)
					dispatch({ type:'SET_USER', payload:USER })
				}
			}
		})
	}

	const renderOptionList = () => {
		const optionList = workspaceList.map((item, index) => {
			if (item.name !== '') {
				return <option key={index} id={item.id} value={item.name}>{item.name} Workspace</option>
			}
			else {
				return <option key={index} id={item.id} value={item.name}>Personal Workspace</option>
			}
		})
		
		return optionList
	}

	const handleViewWorkspace = () => {
		setToggleModalWorkspace(!toggleModalWorkspace)
	}

	const handleViewMore = () => {
		setToggleModal(!toggleModal)
	}

	const handleOrderForm = (x) => {
		setDataOrder(x)
		// setViewOrder(x)
		setToggleModalOrderForm(!toggleModalOrderForm)
	}

	const renderViewMore = () => {
		return (
			<tr style={{ fontSize:'15px' }}>
				<td width='25%' style={{ whiteSpace:'pre-wrap', textAlign:'center' }}>
				</td>
				<td width='50%' style={{ whiteSpace:'pre-wrap', textAlign:'center' }}>
					<Button size='sm' color='link' style={{ borderRadius:'0px', fontWeight:'bold', boxShadow:'none', color:'#2abb73', textDecoration:'none' }} className='m-0' onClick={() => setLoad('2')}>View More</Button>
				</td>
				<td width='25%' style={{ whiteSpace:'pre-wrap', textAlign:'center' }}>
				</td>
			</tr>
		)
	}

	const formatDate = (date) => {
		const dateObj = date

		var month = dateObj.getUTCMonth() + 1
		var day = dateObj.getDate()
		var year = dateObj.getUTCFullYear()

		const finalDate = user.date_format === 'DD/MM/YYYY' ? `${day}/${month}/${year}` : `${month}/${day}/${year}`
		const newDate = date !== '' ? finalDate : 'N/A'

		return newDate
	}

	const renderBookingList = () => {
		return (
			events.slice(0, 3).map((event, index) => {
				// const convertDate = new Date(event.start)
				// const newDate = format(convertDate, 'PPP')
				const newDate = formatDate(event.date)
				const TITLE = event.title.split("-")[0]

				return (
					<tr key={index} style={{ fontSize:'12px' }}>
						<td width='30%' className='ps-0'>
							<div><span style={{ fontWeight:'normal' }}><strong>{TITLE}</strong></span></div>
							<div><span style={{ fontWeight:'normal' }}>{event.booking_id}</span></div>
						</td>
						<td width='30%' style={{ whiteSpace:'pre-wrap' }}>
							<div><span style={{ fontWeight:'normal' }}>{event.name !== '' ? event.name : '-'}</span></div>
							<div><span style={{ fontWeight:'normal' }}>{!newDate.includes('NaN') ? newDate : null}</span></div>
						</td>
						<td width='20%' style={{ whiteSpace:'pre-wrap', textAlign:'center' }}>
							{event.price && <div><span style={{ fontWeight:'normal' }}>{event.code !== '' && event.code !== undefined ? event.code : 'RM'} {event.price !== ''  && event.price !== null ? event.price : '-'}</span></div>}
							<Button size='sm' color='link' style={{ borderRadius:'0px', fontWeight:'bold', boxShadow:'none', color:'#2abb73', textDecoration:'none' }} onClick={() => handleOrderForm(event)} className='m-0'>Order Form</Button>
						</td>
						<td width='20%' style={{ whiteSpace:'pre-wrap', textAlign:'center' }}>

						</td>
					</tr>
				)
			})
		)
	}

	const renderBookingList1 = () => {
		return (
			events.map((event, index) => {
				// const convertDate = new Date(event.start)
				// const newDate = format(convertDate, 'PPP')
				const newDate = formatDate(event.date)
				const TITLE = event.title.split("-")[0]


				return (
					<tr key={index} style={{ fontSize:'13px' }}>
						<td width='30%' className='ps-0'>
							<div><span style={{ fontWeight:'normal' }}><strong>{TITLE}</strong></span></div>
							<div><span style={{ fontWeight:'normal' }}>{event.booking_id}</span></div>
						</td>
						<td width='30%' style={{ whiteSpace:'pre-wrap' }}>
							<div><span style={{ fontWeight:'normal' }}>{event.name !== '' ? event.name : '-'}</span></div>
							<div><span style={{ fontWeight:'normal' }}>{!newDate.includes('NaN') ? newDate : null}</span></div>
						</td>
						<td width='20%' style={{ whiteSpace:'pre-wrap', textAlign:'center' }}>
							{event.price && <div><span style={{ fontWeight:'normal' }}>{event.code !== '' && event.code !== undefined ? event.code : 'RM'} {event.price !== '' ? event.price : '-'}</span></div>}
							<Button size='sm' color='link' style={{ borderRadius:'0px', fontWeight:'bold', boxShadow:'none', color:'#2abb73', textDecoration:'none' }} onClick={() => handleOrderForm(event)} className='m-0'>Order Form</Button>
						</td>
						<td width='20%' style={{ whiteSpace:'pre-wrap', textAlign:'center' }}>

						</td>
					</tr>
				)
			})
		)
	}

	const renderTableHead = () => {
		return (
			<tr>
				<th scope='col' className='ps-0' style={{ fontSize:'14px' }}>
					Item<br/>Booking ID
				</th>
				<th scope='col' className='ps-0' style={{ fontSize:'14px' }}>Name<br/>Date</th>
				<th scope='col' className='ps-0' style={{ fontSize:'14px', textAlign:'center' }}>Amount (CUR)</th>
				<th scope='col' className='ps-0' style={{ fontSize:'14px' }}>Note</th>
			</tr>
		)
	}

	const renderOrderList = () => {
		return (
			events.length !== 0 ?
				<Table responsive >
					<thead className='thead-light'>
						{renderTableHead()}
					</thead>
					<tbody>
						{renderBookingList() }
						{renderViewMore()}
					</tbody>
				</Table>
			: <div style={{ fontSize:'15px' }}>No order list available.</div>
		)
	}

	const renderOrderList1 = () => {
		return (
			events.length !== 0 ?
				<Table responsive>
					<thead className='thead-light'>
						{renderTableHead()}
					</thead>
					<tbody>
						{renderBookingList1() }
					</tbody>
				</Table>
			: <div style={{ fontSize:'15px' }}>No order list available.</div>
		)
	}




	const renderWorkspaceList = () => {
		return (
			<Row  className='mb-2' style={{ textAlign:'left' }}>
				<Col xs={2}>
					<div className={dimensions.width < 830 ? 'px-2' : ''} style={{ fontSize:dimensions.width < 400 ? '14px':'16px', fontWeight:'500' }}>Workspace</div>
				</Col>
				<Col xs={7}>
					<FormGroup style={{ width:  '80%'  }}>
						<Input type='select' name='workspace' value={workspace} style={{ fontSize:'12px' }} onChange={e => handleWorkspace(e)}>
							{renderOptionList()}
						</Input>
					</FormGroup>
				</Col>
				<Col xs={3}>
				{curr_subscription === 'Teams' ? <Button  style={{ fontSize: dimensions.width < 400 ? '12px': '',  color:'black', fontWeight:600, borderRadius:30, borderColor:'black', backgroundColor: "rgb(205, 245, 160)", borderWidth:2 }} className='ml-auto' onClick={handleViewWorkspace} >Add Workspace</Button> : null}
				
				</Col>
			</Row>
		)
	}
    const renderModalWorkspace = () => {
		const dateFormatList = ['MM/DD/YYYY', 'DD/MM/YYYY']
		const timeFormatList = ['12h (am/pm)','24h']
		return (
			<Modal isOpen={toggleModalWorkspace} toggle={() => handleViewWorkspace()} className='modal-dialog-centered modal-dialog' style={{ width:dimensions.width >= 800 ? '70%' : '95%', maxWidth:'100%' }}>
				<ModalHeader toggle={() => handleViewWorkspace()}>New Workspace</ModalHeader>
				<ModalBody>
				{/* <div className='starter-box-content' style={{ marginTop:dimensions.width >= 800? '5rem': '2rem', marginBottom:dimensions.width >= 800? '5rem': '2rem', maxWidth: dimensions.width >= 1000? '40%':'90%' }} > */}
				{/* <div style={{ borderRadius: dimensions.width >= 800? '3%': '5%', padding:'10px', }}> */}
				<Row className={dimensions.width >= 800 ? 'p-3 pt-0' : 'p-2'}>
				<Col md={12}>
						<Label style={{ fontSize:'15px', fontWeight:'600' }}>Workspace</Label>
						<FormGroup tag='fieldset' className='d-flex'>
							<FormGroup check className='pe-4'>
								<Label check style={{ fontSize:'15px', fontWeight:'600' }}>
									<Input type='radio' name='workspace' value='Personal' onChange={e => setNewWorkspace(e.target.value)} /> 
									Personal
								</Label>
							</FormGroup>
							<FormGroup check className='pe-4'>
								<Label check style={{ fontSize:'15px', fontWeight:'600' }}>
									<Input type='radio' name='workspace' value='Team' onChange={e => setNewWorkspace(e.target.value)} /> 
									Team
								</Label>
							</FormGroup>
						</FormGroup>
					</Col>
					<Row className={dimensions.width >= 800 ? 'p-3' : 'p-2'}>
					<h6><b>Setting up calendar</b></h6>
					</Row>
					<Col md={12}>
							{newworkspace === 'Team' ?<FormInput title='team_name' label='Team name:' type='text' value={teamName} onChange={e => setTeamName(e.target.value)} /> :<FormInput title='personal_name' label='Personal name:' type='text' value={teamName} onChange={e => setTeamName(e.target.value)} />}
						</Col>
					{/* {newworkspace === 'Team' &&
						<Col md={12}>
							<FormInput title='team_name' label='Team name:' type='text' value={teamName} onChange={e => setTeamName(e.target.value)} />
						</Col>
					} */}
					<Col md={12}>
						<FormInput title='timeZone' label='Time Zone' type='select' value={timeZone} list={timeZones} defaultOption='---Select Time Zone---' onChange={e => setTimeZone(e.target.value)} />
					</Col>
					<Col md={6}>
						<FormInput title='date_format' label='Date Format' type='select' value={dateFormat} list={dateFormatList} defaultOption='---Select Date Format---' onChange={e => setDateFormat(e.target.value)} />
					</Col>
					<Col md={6}>
						<FormInput title='time_format' label='Time Format' type='select' value={timeFormat} list={timeFormatList} defaultOption='---Select Time Format---' onChange={e => setTimeFormat(e.target.value)} />
					</Col>
					<Col md={12}>
						<Label style={{ fontSize:'15px', fontWeight:'600' }}>Week Start</Label>
						<FormGroup tag='fieldset' className='d-flex'>
							<FormGroup check className='pe-4'>
								<Label check style={{ fontSize:'15px', fontWeight:'600' }}>
									<Input type='radio' name='weekStart' value='Monday' onChange={e => setWeekStart(e.target.value)} /> 
									Monday
								</Label>
							</FormGroup>
							<FormGroup check className='pe-4'>
								<Label check style={{ fontSize:'15px', fontWeight:'600' }}>
									<Input type='radio' name='weekStart' value='Sunday' onChange={e => setWeekStart(e.target.value)} /> 
									Sunday
								</Label>
							</FormGroup>
						</FormGroup>
					</Col>
					<Col md={12}>
						<Label style={{ fontSize:'15px', fontWeight:'600' }}>Week Display</Label>
						<FormGroup tag='fieldset' className='d-flex'>
							<FormGroup check className='pe-4'>
								<Label check style={{ fontSize:'15px', fontWeight:'600' }}>
									<Input type='radio' name='weekDisplay' value='5 Days' onChange={e => setWeekDisplay(e.target.value)} /> 
									5 Days
								</Label>
							</FormGroup>
							<FormGroup check className='pe-4'>
								<Label check style={{ fontSize:'15px', fontWeight:'600' }}>
									<Input type='radio' name='weekDisplay' value='7 Days' onChange={e => setWeekDisplay(e.target.value)} /> 
									7 Days
								</Label>
							</FormGroup>
						</FormGroup>
					</Col>
					
				</Row>
				
			{/* </div> */}
			{error && <div className='error'>{error}</div>}
		{/* </div> */}
				</ModalBody>
            	<ModalFooter style={{ justifyContent:'space-between', alignContent:'center'}}>
            	<Button  style={{ fontWeight: 'bold', backgroundColor:'#cdf5a0', color:'black', border:'2px solid black'}} onClick={() => handleViewWorkspace()}>Cancel</Button>
				<Button  style={{ fontWeight: 'bold', backgroundColor:'#cdf5a0', color:'black', border:'2px solid black'}} onClick={() => saveUser()}>Save</Button>
            	</ModalFooter>
			</Modal>
		)
	}

	const renderModal = () => {
		return (
		<Modal isOpen={toggleModal} toggle={() => handleViewMore()} className='modal-dialog-centered modal-dialog' style={{ width:dimensions.width >= 800 ? '70%' : '95%', maxWidth:'100%' }}>
			<ModalHeader toggle={() => handleViewMore()}>Order List</ModalHeader>
			<ModalBody className='p-4'>
			{renderOrderList1()}
			</ModalBody>
			<ModalFooter>
			<Button  style={{ fontWeight: 'bold', backgroundColor:'#cdf5a0', color:'black', border:'2px solid black'}} onClick={() => handleViewMore()}>Cancel</Button>
			</ModalFooter>
		</Modal>
		)
	}

	const renderOrderForm = () => {
		let x = dataOrder
		const newDate = formatDate(x.date)
		const TITLE = x.title.split("-")[0]

		return (
			<div>
				<Row className='mb-4' >
					<Col>
					<Row >
						<Col xs={4}><legend style={{  fontSize:'14px', fontWeight:'700' }}>Booking Ref:</legend></Col>
						<Col xs={8}><legend style={{ fontWeight:'normal', fontSize:'13px' }}>{x.booking_id}</legend></Col>
					</Row>
					<Row >
						<Col xs={4}><legend style={{  fontSize:'14px', fontWeight:'700' }}>Order Form Date & Time:</legend></Col>
						<Col xs={8}><legend style={{ fontWeight:'normal', fontSize:'13px' }}>{!newDate.includes('NaN') ? newDate : 'NA'} | {x.time !== "" ? x.time : "Time NA"}</legend></Col>
					</Row>
					<Row >
						<Col xs={4}><legend style={{  fontSize:'14px', fontWeight:'700' }}>Issued to:</legend></Col>
						<Col xs={8}><legend style={{ fontWeight:'normal', fontSize:'13px' }}>{x.name !== '' ? x.name : '-'}</legend></Col>
					</Row>
					</Col>
					<Col>
					<Row>
						<Col xs={4}><legend style={{  fontSize:'14px', fontWeight:'700' }}>Phone Number:</legend></Col>
						<Col xs={8}><legend style={{ fontWeight:'normal', fontSize:'13px' }}>{x.phone !== '' ? x.phone : '-'}</legend></Col>
					</Row>
					<Row>
						<Col xs={4}> <legend style={{  fontSize:'14px', fontWeight:'700' }}>Email:</legend> </Col>
						<Col xs={8}><legend style={{ fontWeight:'normal', fontSize:'13px' }}>{x.email}</legend></Col>
					</Row>
					</Col>
				</Row>				
				<Table responsive>
					<thead className='thead-light mt-3'>
					<tr>
						<th scope='col' className='ps-0' style={{ fontSize:'15px' }}>Details</th>
						<th scope='col' className='ps-0' style={{ fontSize:'15px', textAlign:'center' }}></th>
						<th scope='col' className='ps-0' style={{ fontSize:'15px', textAlign:'center' }}>Amount (CUR)</th>
					</tr>
					</thead>
					<tbody>
						<tr style={{ fontSize:'13px' }}>
							<td width='52%' className='ps-0'>
								<div>
								<span style={{fontWeight:'700'}}>{TITLE}</span><br/>
								<span style={{ fontWeight:'normal' }}>
									Booked Date: {!newDate.includes('NaN') ? newDate : 'NA'} <br/> 
									Booked Time: {x.time !== "" ? x.time : "-"} <br/>
									Location: {x.address !== "" ? x.address : "-"} <br/>
									{x.notes !== "" ? "Notes: " + x.notes : null}
								</span></div>
							</td>
							<td width='23%' style={{ whiteSpace:'pre-wrap', textAlign:'center' }}>
								
							</td>
							<td width='25%' style={{ whiteSpace:'pre-wrap', textAlign:'center' }}>
								<div><span style={{ fontWeight:'normal' }}>{x.code !== '' && x.code !== undefined ? x.code : 'RM'} {x.price !== '' ? x.price : ' -'}</span></div>
							</td>
						</tr>

						
						<tr s>
						
							<td  colSpan="2" style={{ whiteSpace:'pre-wrap',fontSize:'15px',  textAlign:'right' }}>
								<div style={{ fontWeight:'600' }}>Total Amount:</div>
							</td>
							<td  width='25%' style={{ whiteSpace:'pre-wrap',  textAlign:'center' }}>
								<div><span style={{ fontWeight:'normal' }}>{x.code !== '' && x.code !== undefined ? x.code : 'RM'} {x.price !== '' ? x.price : ' -'}</span></div>
							</td>
						</tr>
					</tbody>
				</Table>
			</div>
		)
	}

	const renderModalOrderForm = () => {
		return (
		  <Modal isOpen={toggleModalOrderForm} toggle={() => handleOrderForm()} className='modal-dialog-centered modal-dialog' style={{ width:dimensions.width >= 800 ? '70%' : '95%', maxWidth:'100%' }}>
			<ModalHeader toggle={() => handleOrderForm()}>Order Form</ModalHeader>
			<ModalBody className='p-4'>
			  {renderOrderForm()}
			</ModalBody>
			<ModalFooter>
				<PrintInvoice data={dataOrder} seller={user} />
			  	<Button color='lightGreen' style={{ fontSize:dimensions.width >= 800 ? '16px' : '14px', fontWeight: 'bold',  color:'black', border:'2px solid black' }} onClick={() => handleOrderForm()}>Close</Button>
			</ModalFooter>
		  </Modal>
		)
	}

	const renderMainPage = () => {
		return(
			<div className='pb-5 headerMargin'>
				<PageHeader title='Home' />
				<div style={{ textAlign:'left' }}>
					{/* {dimensions.width > 830 ? <h4 className='mt-2 mb-4'>Welcome, {user.name? user.name : 'stranger'}!</h4> : <h5 className='mt-2 mb-4 px-2'>Welcome, {user.name? user.name : 'stranger'}!</h5>} */}
					{/* <h4 className='mt-2 mb-4'>Welcome, {user.name? user.name : 'stranger'}!</h4> */}
				</div>
				<Row  style={{ textAlign:'left' }} >
					{renderWorkspaceList()}	
				</Row>
				<Row >
					<legend className={dimensions.width < 830 ? 'mb-2  px-4' : ''}  style={{ textAlign:'left', fontSize:dimensions.width < 400 ? '16px':'18px' }}><b>Order List</b></legend>
					<div className={dimensions.width < 830 ? 'px-4' : 'mb-3'}   style={{border:'1px'}}>
						{renderOrderList()}
					</div>
					
				</Row>

				<Row >
					<legend className={dimensions.width < 830 ? 'mb-3 px-4' : 'mb-3'}  style={{ textAlign:'left', fontSize:dimensions.width < 400 ? '16px':'18px', fontWeight:'700' }}>Planner</legend>
					<div className={dimensions.width < 830 ? 'px-4' : ''} >
						<DashboardCalendar events={events} user={user}  />
					</div>
				</Row>

				{!!toggleModal && renderModal()}
				{!!toggleModalOrderForm && renderModalOrderForm()}
				{!!toggleModalWorkspace && renderModalWorkspace()}
			</div>
		)
	}

	const renderOrderPage = () => {
		return(
			<div className='m-0 p-0' >
				<div className='pb-5 headerMargin'>
				<PageHeader title='Order List' />
				<div className='d-flex justify-content-start mt-3 mb-3'>
					{dimensions.width >= 830 ?
						<button className='d-flex pt-2 pb-2 ps-4 pe-4 mt-2 back-btn' onClick={() => setLoad('1')}>
							<FaLongArrowAltLeft style={{marginTop:'3px'}}/>
							<span className='ms-2'>Back</span>
						</button>	
						:
						<button className='pt-1 pb-1 ps-3 pe-3 mt-4' style={{ backgroundColor:'transparent', color:'black', border:'2px solid #000', borderRadius:'2rem', width:'60px', maxWidth:'100%', height:'30px' }} onClick={() => setLoad('1')}>
							<FaLongArrowAltLeft />
						</button>
					}
				</div>

				{renderOrderList1()}
				{!!toggleModalOrderForm && renderModalOrderForm()}
			</div>
			
			</div>
		)
	}

	let display
	switch (load) {
		case '1': display = renderMainPage()
			break;
		case '2': display = renderOrderPage()
			break;
		default: display = <div className='headerMargin loader'></div>
			break;
	}

	return (
		<div>
			{display}
		</div>
		
	)
}