import React from 'react'

import WIP_IMG from '../assets/img/work-in-progress.png'

import { useDimensionContext } from '../hooks/useDimensionContext'

export const WIP = () => {
  const { dimensions } = useDimensionContext()

  return (
    <div className='px-4 text-center'>
      {/* <h1 className='pt-3'>WORK IN PROGRESS</h1> */}
			<img className='mt-5' src={WIP_IMG} alt='logo' style={{ width: dimensions.width < 800 ? '200px' : '250px' }}/>
    </div>
  )
}
