import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const Feedback = (props) => {
    const navigate = useNavigate()
    const [ link, setLink ] = useState(false)

    useEffect(()=>{
        const openWindow = () => {
            let w = window.open("https://docs.google.com/forms/d/e/1FAIpQLScaSsifft8FAXIbjOg8CWXuod7IEga0TGM5LCccCdmlpMU_pQ/viewform")
    
            if (w) {
                setLink(true)
                navigate('/dashboard')
            }
            return
        }

        if (link === false){
            openWindow()
        }
    }, [])
    
    return 
}

