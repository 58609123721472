import { useState } from 'react'
import { useAuthContext } from './useAuthContext'

import { util } from '../utils/util'

export const useExistingEmail = () => {
	const [ error, setError ] = useState(null)
	const [ isLoading, setIsLoading ] = useState(null)
	const { dispatch } = useAuthContext()

	const existingEmail = async (email) => {
		setIsLoading(true)
		setError(null)

		const response = await fetch(`${util.expURL}/api/auth/existingEmail`, {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({email})
		})

		const json = await response.json()
		if (!response.ok) {
			setIsLoading(false)
			sessionStorage.setItem('auth', JSON.stringify(json.error))
			setError(json.error)
		}

		if (response.ok) {
			sessionStorage.setItem('auth', JSON.stringify(json))

			dispatch({ type:'EXISTING_EMAIL', payload:json })

			setIsLoading(false)
		}
	}

	return { existingEmail, isLoading, error }
}