import { StuffsContext } from '../context/StuffContext'
import { useContext } from 'react'

export const useStuffsContext = () => {
	// pass the value of StuffsContext from provider component
	const context = useContext(StuffsContext)

	if (!context) {
		throw Error('useStuffsContext must be used inside an StuffsContextProvider')
	}

	return context
}