/* eslint-disable no-unreachable */
import { useState } from 'react'
import { NavLink } from 'react-router-dom'

import { Button, Col, Row } from 'reactstrap'

import logo from '../assets/img/LOGO.png'

import FormInput from '../components/FormInput'

import { useLogin } from '../hooks/useLogin'
import { useDimensionContext } from '../hooks/useDimensionContext'

import { withRouter } from '../utils/withRouter'
import { useEffect } from 'react'

export const Login = withRouter((props) => {
	const { login, isLoading, loginError } = useLogin()
	const { dimensions } = useDimensionContext() 

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [loginBtn, setLoginBtn] = useState(true)
	const [showError, setShowError] = useState(false)
	const [error, setError] = useState('')

	const loginUser = async (e) => {
		e.preventDefault()

		if (email === '' || password === '') {
			setError('All fields must be filled!')&& redirect()
		} else {
			setLoginBtn(false)
			const login_response = await login(email,password)
			
			if(login_response.loginError === null) {
				setLoginBtn(true)
				redirect()
			}
			else {
				setError(login_response.loginError) && redirect()
				setLoginBtn(true)
			}
		}	
	}

	const redirect = () => {
		return props.navigate('/') 
	}

	const renderLoginButton = () => {
		if (loginBtn) {
			return <Button disabled={isLoading} color='darkGreen' style={{ width: '60%'}} onClick={e => loginUser(e)}>LOGIN</Button>
		}
		if (!loginBtn) {
			return (
				<Button disabled color='darkGreen' style={{ width: '60%' }}>
					<div className='d-flex align-items-center justify-content-center'>
						<div className='mr-2'> LOGGING IN &nbsp; </div>
						<div className='buttonloader'></div>
					</div>
				</Button>
			) 
		}


	}

	return (
		<div className='starter-box-content' style={{marginTop:dimensions.width >= 800? '5rem': '3rem', marginBottom:dimensions.width >= 800? '5rem': '2rem', maxWidth: dimensions.width >= 800? '80%':'90%'}}>
			<Row style={{ alignItems:'center', paddingTop:'10px', paddingBottom:'10px', paddingLeft:'20px', paddingRight:'20px' }}>
				<Col lg={6}>
					<div>
						<img src={logo} alt='sfera-plots-logo' className='center'  style={{width: dimensions.width < 700? '40%': null}}/>
					</div>

					<div className='pt-4 pb-2' style={{ color:'black' }}>
						<h4><b>Welcome back!</b></h4>
						<p>Please login to continue</p>
					</div>
				</Col>
				<Col lg={6}>
					<div className='pt-2 pb-0'>
						<FormInput title='email' label='E-mail' type='email' value={email} onChange={e => setEmail(e.target.value)} />
						<FormInput title='password' label='Password' type='password' value={password} onChange={e => setPassword(e.target.value)} />
						<NavLink className='d-flex justify-content-end' to='/reset-password' style={{ color:'#2abb73', textDecoration:'none' }} >Forgot password?</NavLink>
					</div>
					<div className='pt-4 pb-3' style={{textAlign:'center'}}>
						{renderLoginButton()}
						<div className='p-2 pb-3'>
							<p style={{ color:'black' }}> New here? <NavLink to='/register' style={{ color:'#2abb73', textDecoration:'none' }} >Create a new account</NavLink></p>
						</div>
						{error && <div className='mt-4 error'>{error}</div>}
						{/* d{showError && <div className='mt-4 error'>{showError}</div>} */}
					</div>            
				</Col>
			</Row>
		</div>
	)
})