import React from 'react'

import { useUserContext } from '../hooks/useUserContext'

import { WIP } from './WIP'
import PageHeader from '../components/PageHeader'

export const Analytics = () => {
    const { user } = useUserContext()

    return (
        <>
        {user? 
            <div className='headerMargin' style={{ textAlign:'left' }}>
                <PageHeader title='Analytics' />
                {<WIP />}
            </div>:
            <div className='headerMargin loader'></div> 
        }
        </>  
    )
}