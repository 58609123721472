import React, { useState, useEffect } from 'react'
import { Routes, Route, HashRouter, Navigate } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-day-picker/dist/style.css'
import './assets/css/sferaplots.css'

import { useAuthContext } from './hooks/useAuthContext'
import { useDimensionContext } from './hooks/useDimensionContext'

import { Auth } from './layouts/Auth'
import { Dashboard } from './layouts/Dashboard'

import dashRoutes from './routes/routes'
import PrivateRoute from './routes/PrivateRoute.js'

import { util } from './utils/util'

export const App = (props) => {
	const { auth } = useAuthContext()
	const { dispatch } = useDimensionContext()

	const [ authenticated, setAuthenticated ] = useState(false)
	const [ loading ] = useState(false)

	useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth
      const newHeight = window.innerHeight

			const dimension = {
				width: newWidth,
				height: newHeight,
			}

			dispatch({ type:'SET_DIMENSION', payload:dimension })
    }

    window.addEventListener('resize', updateWindowDimensions)
		updateWindowDimensions()

    return () => window.removeEventListener('resize', updateWindowDimensions) 
  }, [])

	useEffect(() => {
		const fetchUser = () => {
			setAuthenticated(true)
		}

		if (auth) {
			fetchUser()
		} else {
			setAuthenticated(false)
		}

	}, [auth]) 

	// display dashboard routes
	const getRoutes = routes => {
		return routes.map((prop, key) => {
			if (!prop.redirect && prop.display) {
				return <Route key={key} path={prop.path} element={<prop.component/>}  />
			}
			if (prop.redirect) {
				return <Route key={key} path={prop.path} element={<Navigate to={prop.pathTo} replace />} />
			}
			else {
				return null
			}
		})
	}

	if (loading) return <div className='text-center p-5' style={{ fontSize:'50px' }}>LOADING</div>

	return(
		<HashRouter>
			<Routes>
				<Route path='*' element={<Navigate to='/welcome' replace />}/>
				<Route path='/welcome' element={<Auth {...props}/>} />
				<Route path='/login' element={<Auth {...props}/>} />
				<Route path='/register' element={<Auth {...props}/>} />
				<Route path='/reset-password' element={<Auth {...props}/>} />
				<Route path='/onboarding/profile' element={<Auth {...props}/>} />
				<Route path='/onboarding/availability' element={<Auth {...props}/>} />
				<Route path='/onboarding/details' element={<Auth {...props}/>} />
				<Route element={<PrivateRoute authenticated={authenticated} />}>
					<Route path='/' element={<Dashboard />}>
						{getRoutes(dashRoutes)}
					</Route>
				</Route>
			</Routes>
		</HashRouter>
	)
}