import React, { useState, useRef } from 'react'
import { Col, FormGroup, Input, Row, Table, Button, Modal, ModalBody, ModalHeader, ModalFooter, CardFooter } from 'reactstrap'
import ReactToPrint from 'react-to-print';

import { util } from '../utils/util'
import { useAuthContext } from '../hooks/useAuthContext'
import { useUserContext } from '../hooks/useUserContext'
import { useDimensionContext } from '../hooks/useDimensionContext'



export const ComponentToPrint = React.forwardRef((props, ref) => {
    const x = props.data
    const user = props.seller

    const formatDate = (date) => {
		const dateObj = date

		var month = dateObj.getUTCMonth() + 1
		var day = dateObj.getDate()
		var year = dateObj.getUTCFullYear()

		const finalDate = user.date_format === 'DD/MM/YYYY' ? `${day}/${month}/${year}` : `${month}/${day}/${year}`
		const newDate = date !== '' ? finalDate : 'N/A'

		return newDate
	}
    
    const currentTime = () => {
		var d = new Date()
		var hours = d.getHours();
		var minutes = d.getMinutes();
		var ampm = hours >= 12 ? 'PM' : 'AM';
		hours = hours % 12;
		hours = hours ? hours : 12; 
		minutes = minutes < 10 ? '0'+minutes : minutes;
		var strTime = hours + ':' + minutes + ' ' + ampm;

		return strTime

	}


    const newDate = formatDate(x.date)
    const TITLE = x.title.split("-")[0]
    const invoiceDate = formatDate(new Date())


    return(
        <div ref={ref} style={{ padding:'3rem', height: '100%'}}>
            <h6 className='mb-2'>{user.name}</h6>
            {user.address !== '' && user.address !== undefined ? <h6 style={{width:'50%'}} >{user.address}</h6>: null}
            <span className='mt-2'>{user.email} | {user.phone}</span>

            <h6 className='mt-4 mb-5'>THANK YOU FOR CHOOSING US!</h6>


            <Row className='mb-4' >
                <Col>
                <Row >
                    <Col xs={4}><legend style={{  fontSize:'14px', fontWeight:'700' }}>Booking Ref:</legend></Col>
                    <Col xs={8}><legend style={{ fontWeight:'normal', fontSize:'13px' }}>{x.booking_id}</legend></Col>
                </Row>
                <Row >
                    <Col xs={4}><legend style={{  fontSize:'14px', fontWeight:'700' }}>Invoice Date & Time:</legend></Col>
                    <Col xs={8}><legend style={{ fontWeight:'normal', fontSize:'13px' }}>{invoiceDate} | {currentTime()} </legend></Col>
                </Row>
                <Row >
                    <Col xs={4}><legend style={{  fontSize:'14px', fontWeight:'700' }}>Issued to:</legend></Col>
                    <Col xs={8}><legend style={{ fontWeight:'normal', fontSize:'13px' }}>{x.name !== '' ? x.name : '-'}</legend></Col>
                </Row>
                <Row >
                    <Col xs={4}><legend style={{  fontSize:'14px', fontWeight:'700' }}>Phone Number:</legend></Col>
                    <Col xs={8}><legend style={{ fontWeight:'normal', fontSize:'13px' }}>{x.phone !== '' ? x.phone : '-'}</legend></Col>
                </Row>
                </Col>
                <Col>
                <Row>
                    <Col xs={4}> <legend style={{  fontSize:'14px', fontWeight:'700' }}>Email:</legend> </Col>
                    <Col xs={8}><legend style={{ fontWeight:'normal', fontSize:'13px' }}>{x.email}</legend></Col>
                </Row>
                <Row>
                    <Col xs={4}><legend style={{  fontSize:'14px', fontWeight:'700' }}>Booked Date:</legend></Col>
                    <Col xs={8}><legend style={{ fontWeight:'normal', fontSize:'13px' }}>{!newDate.includes('NaN') ? newDate : 'NA'}</legend></Col>
                </Row>
                <Row>
                    <Col xs={4}><legend style={{  fontSize:'14px', fontWeight:'700' }}>Booked Time:</legend></Col>
                    <Col xs={8}><legend style={{ fontWeight:'normal', fontSize:'13px' }}>{x.time !== "" ? x.time : "-"}</legend></Col>
                </Row>
                </Col>
            </Row>
            <Table  className='mb-5' responsive>
                <thead className='thead-light mt-3'>
                <tr>
                    <th scope='col' className='ps-0' style={{ fontSize:'15px' }}>Details</th>
                    <th scope='col' className='ps-0' style={{ fontSize:'15px', textAlign:'center' }}></th>
                    <th scope='col' className='ps-0' style={{ fontSize:'15px', textAlign:'center' }}>Amount (CUR)</th>
                </tr>
                </thead>
                <tbody>
                    <tr style={{ fontSize:'13px' }}>
                        <td width='52%' className='ps-0'>
                            <div className='mb-4' >
                            <span style={{fontWeight:'700'}}>{TITLE}</span><br/>
                            <span   style={{ fontWeight:'normal' }}>
                                Location: {x.address !== "" ? x.address : "-"} <br/>
                                {x.notes !== "" ? "Notes: " + x.notes : null}
                            </span></div>
                        </td>
                        <td width='23%' style={{ whiteSpace:'pre-wrap', textAlign:'center' }}>
                            
                        </td>
                        <td width='25%' style={{ whiteSpace:'pre-wrap', textAlign:'center' }}>
                            <div><span style={{ fontWeight:'normal' }}>{x.code !== '' && x.code !== undefined ? x.code : 'RM'} {x.price !== '' ? x.price : ' -'}</span></div>
                        </td>
                    </tr>

                    <tr style={{borderBottom:'1px solid #fff'}}>
                        <td  colSpan="2" style={{ whiteSpace:'pre-wrap',fontSize:'15px',  textAlign:'right' }}><div style={{ fontWeight:'600' }}>Total Amount:</div></td>
                        <td  width='25%' style={{ whiteSpace:'pre-wrap',  textAlign:'center' }}><div><span style={{ fontWeight:'normal' }}>{x.code !== '' && x.code !== undefined ? x.code : 'RM'} {x.price !== '' ? x.price : ' -'}</span></div></td>
                    </tr>
                </tbody>
            </Table>


            

            <CardFooter className='mt-5' >
                <h6 className='mt-8 mb-5' style={{textAlign:'center'}}>LET'S WORK TOGETHER AGAIN IN THE FUTURE</h6>
                <h6 style={{textAlign:'right'}} >Powered by SFERA</h6>
            </CardFooter>
            {/* <div style={{position:'absolute', bottom:'0', paddingBottom:'3rem', width:'100%'}} className="d-flex align-items-center justify-content-center">
                {this.props.t('requestsLabel.poweredBy')} <img src={SERV} height={24} className="ml-2" />
            </div> */}


        </div>
    )
})

const PrintInvoice = (props) => {
    const { dimensions } = useDimensionContext()
    const componentRef = useRef();

    return(
        <div>
            <ReactToPrint
                trigger={() => <Button color='lightGreen' style={{ fontSize:dimensions.width >= 800 ? '16px' : '14px', fontWeight: 'bold',  color:'black', border:'2px solid black' }} >Download Invoice</Button>}
                content={() => componentRef.current}
            />
            <div style={{display:'none'}}><ComponentToPrint ref={componentRef} data={props.data} seller={props.seller}/></div>
        </div>
       
    )
}

export default PrintInvoice